import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'pipeParseDate'
})
export class PipeParseDatePipe implements PipeTransform
{

  transform(value: any, args?: any): any
  {
    const {year, month, date, hour, minute, second, timeZone} = value;
    const res = moment().set({
      year: year,
      month: month,
      date: date,
      hour: hour,
      minute: minute,
      second: second
    });
    return res.format('DD/MM/YYYY hh:mma');

  }


}
