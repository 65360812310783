import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: 'input[numbersOnly]'
})
export class InputOnlyNumberDirective
{
  private regex: RegExp = new RegExp(/^\d+$/g);

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Enter', 'ArrowRight', 'ArrowLeft'];

  constructor(private el: ElementRef)
  {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent)
  {
    if (this.specialKeys.indexOf(event.key) !== -1)
    {
      return;
    }

    let current: string = this.el.nativeElement.value;

    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex))
    {
      event.preventDefault();
    }
  }
}
