import {NgModule} from '@angular/core';
import {Apollo, ApolloModule} from 'apollo-angular';
import {HttpLink, HttpLinkModule} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {onError} from 'apollo-link-error';
import {ApolloLink, from} from 'apollo-link';
import {Router} from '@angular/router';
import {HttpHeaders} from '@angular/common/http';
import {environment} from "../../../../environments/environment";

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
})
export class GraphQLModule
{
  url = environment.apiURL;
  constructor(private apollo: Apollo,
              private httpLink: HttpLink,
              private router: Router)
  {
    const URI = `${this.url}/graphql`;

    const http = httpLink.create({uri: URI});

    const authMiddleware = new ApolloLink((operation, forward) =>
    {
      const token = localStorage.getItem('token');
      operation.setContext(({headers}) => ({
        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
      }));
      return forward(operation);
    });

    const errorLink = onError(({graphQLErrors, networkError}) =>
    {
      if (graphQLErrors)
      {
      }
      if (networkError)
      {
        this.router.navigate(['auth', 'login']);
      }
    });


    apollo.create({
      link: from([authMiddleware, errorLink, http]),
      cache: new InMemoryCache(),
      queryDeduplication: false,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        }
      }
    });
  }
}
