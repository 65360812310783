import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {delay, finalize} from 'rxjs/operators';
import {LoadingScreenService} from './loading-screen.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor

{
  baseUrl = environment.apiURL;
  activeRequests: number = 0;

  constructor(private loadingScreenService: LoadingScreenService)
  {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
 
    if (this.activeRequests === 0)
    {
      this.loadingScreenService.startLoading();
    }
    if(request.url===`${this.baseUrl}/upload/file`)
    {
      this.loadingScreenService.stopLoading(); 
    }
    if(request.url===`${this.baseUrl}/download/export/users`)
    {
      this.loadingScreenService.stopLoading(); 
    }

    this.activeRequests++;
    return next.handle(request).pipe(
      delay(500),
      finalize(() =>
      {
        this.activeRequests--;
        if (this.activeRequests === 0)
        {
          this.loadingScreenService.stopLoading();
        }
      })
    );
  };

}
