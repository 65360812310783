import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormErrorMessageComponent} from './form-error-message/form-error-message.component';
import {LoadingScreenComponent} from './loading-screen/loading-screen.component';
import {InputOnlyNumberDirective} from './directives/input-only-number.directive';
import {HttpClientModule} from '@angular/common/http';
import {PipeParseDatePipe} from './pipes/pipe-parse-date.pipe';
import {ConvertDatePipe} from './pipes/convert-date.pipe';
import {IntegerOnlyDirective} from './directives/integer-only.directive';

const listComponent = [FormErrorMessageComponent, LoadingScreenComponent, InputOnlyNumberDirective, PipeParseDatePipe, ConvertDatePipe, IntegerOnlyDirective];

@NgModule({
  declarations: [...listComponent,],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: [...listComponent],
  providers: []
})
export class CoreComponentsModule
{
}
