
import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import { type } from 'os';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: any;
  BigDecimal: any;
};

export type FeedbackOptionValue = {
  __typename?: 'FeedbackOptionValue';
  displaySequence?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Scalars['Boolean']>;
  hasComment?:Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
}

export type AnswerFeedbackInfo = {
   __typename?: 'AnswerFeedbackInfo';
      questionCode?: Maybe<Scalars['String']>;
  numberValue?: Maybe<Scalars['Int']>;
  freeTextValue?: Maybe<Scalars['String']>;
  feedbackOptionValueList?:Maybe<Array<Maybe<FeedbackOptionValue>>>;
};

export type AnswerFeedbackInfoInput = {
  questionId?: Maybe<Scalars['UUID']>;
  questionType?: Maybe<QuestionType>;
  optionValue?: Maybe<Array<Maybe<OptionValueInput>>>;
};

export type Article = {
   __typename?: 'Article';
  id?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  coverPhotoUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  advPriority?: Maybe<Scalars['String']>;
  author?: Maybe<UserAccount>;
  authorId?: Maybe<Scalars['UUID']>;
  views?: Maybe<Scalars['Int']>;
  authorName?: Maybe<Scalars['String']>;
  latestUpdatedObj?: Maybe<DateTimeObject>;
  latestUpdatedBy?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  publishDateObj?: Maybe<DateTimeObject>;
  category?: Maybe<Scalars['String']>;
  postTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdDateObj?: Maybe<DateTimeObject>;
  linkToWebsite?: Maybe<Scalars['String']>;
  advStartDateObj?:Maybe<DateTimeObject>;
  advEndDateObj?: Maybe<DateTimeObject>;
  // assessments?: Maybe<Scalars['String']>;
};
export type UserAccessListResponse =
{
  id?: Maybe<Scalars['UUID']>;
  groupName?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  userProfileList?: Maybe<Array<Maybe<Users>>>;
  sportList?:Maybe<Array<Maybe<data>>>;
  modulesList?: Maybe<Array<Maybe<data>>>;
}
export type userResultsInput ={
competitionName?: Maybe<Scalars['String']>;
competitionCategory?: Maybe<Scalars['String']>;
competitionLevel?: Maybe<Scalars['String']>;
noOfParticipants?: Maybe<Scalars['String']>;
competitionType?: Maybe<Scalars['String']>;
country?: Maybe<CountryInput>;
competitionDateValue?: Maybe<Scalars['String']>;
sportId?: Maybe<Scalars['UUID']>
assessmentId?: Maybe<Scalars['UUID']>
event?: Maybe<Scalars['String']>;
positionNumber?: Maybe<Scalars['String']>;
result?: Maybe<Scalars['String']>;
status?: Maybe<Scalars['String']>;
statusDescription?: Maybe<Scalars['String']>;
userProfileId?: Maybe<Scalars['UUID']>
}
export type userResultsUpdateInput ={
  id?: Maybe<Scalars['UUID']>;
competitionName?: Maybe<Scalars['String']>;
CompetitionCategory?: Maybe<Scalars['String']>;
competitionLevel?: Maybe<Scalars['String']>;
noOfParticipants?: Maybe<Scalars['String']>;
competitionType?: Maybe<Scalars['String']>;
country?:Maybe<CountryInput>;
competitionDateValue?: Maybe<Scalars['String']>;
sportId?: Maybe<Scalars['UUID']>
assessmentId?: Maybe<Scalars['UUID']>
event?: Maybe<Scalars['String']>;
positionNumber?: Maybe<Scalars['String']>;
result?: Maybe<Scalars['String']>;
status?: Maybe<Scalars['String']>;
statusDescription?: Maybe<Scalars['String']>;
userProfileId?: Maybe<Scalars['UUID']>
}
export type StatusUpdateInput ={
  id?: Maybe<Scalars['UUID']>;
  status?:  Maybe<Scalars['String']>;
  statusDescription?:  Maybe<Scalars['String']>;
  }

export type Users=
{
  id:  Maybe<Scalars['UUID']>;
userAccountId:  Maybe<Scalars['UUID']>;
fullName:Maybe<Scalars['String']>;
}
export type data=
{
  id:  Maybe<Scalars['UUID']>;
name:  Maybe<Scalars['UUID']>;

}
export type ArticleInput = {
  id?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
  postTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?:Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  linksList?: Maybe<Scalars['String']>;
  linkToWebsite?: Maybe<Scalars['String']>;
  advStartDateObj?: Maybe<DateTimeObject>;
  advEndDateObj?: Maybe<DateTimeObject>;
  // assessment?: Maybe<Scalars['String']>;
};
export type userAccessInput = {
  id?: Maybe<Scalars['UUID']>;
  groupName?: Maybe<Scalars['String']>;
  acccess?: Maybe<Scalars['String']>;
  userProfileList?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  moduleList?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  sportsList?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export type ArticleInput1 = {
  id?: Maybe<Scalars['UUID']>;
  dateOfBirth: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  authorId?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
  postTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};
export type ArticlePagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};
export type ArticleFilter = {
  articleType?: Maybe<ArticleType>
  articleStatus?: Maybe<ArticleStatus>
  from?:Maybe<DateTimeObjectInput>;
  to?: Maybe<DateTimeObjectInput>;
};
export enum ArticleType  {
  News = 'NEWS',
  Advertisement = 'ADVERTISEMENT'
}
export enum ArticleStatus  {
  All = 'ALL',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Deleted = 'DELETED'
}
export type UserAccessPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;

}
export type ArticlePagingResponse = {
   __typename?: 'ArticlePagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Article>>>;
};
export type UserAccessPagingResponse=
{
  __typename?: 'UserAccessPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Article>>>;
}
export type ArticleResponse = {
   __typename?: 'ArticleResponse';
  status?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Article>;
};
export type UserAccessResponse = {
  __typename?: 'UserAccessResponse';
 status?: Maybe<Scalars['String']>;
 code?: Maybe<Scalars['String']>;
 message?: Maybe<Scalars['String']>;
 object?:Maybe<Scalars['String']>;
};
export type Category = {
  __typename?: 'Category';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
//   category?: Maybe<Scalars['String']>;
//   linkName?:Maybe<Scalars['UUID']>;
//   linkUrl?: Maybe<Scalars['String']>;
//   linkImage?:Maybe<Scalars['String']>;
//   imageUrl?: Maybe<Scalars['String']>;
//  message?: Maybe<Scalars['String']>;
//   object?: Maybe<Article>;
  isPublish?:Maybe<Scalars['String']>;
  displaySequence?: Maybe<Scalars['Int']>;
  isShareApp?: Maybe<Scalars['String']>;
};
export type categoryInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  displaySequence?:Maybe<Scalars['Int']>;
  isShareApp?: Maybe<Scalars['String']>;

};
export type CategoryPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;

};
export type CategoryListResponse = {
category?: Maybe<Category>;
// categoryLinks?: Maybe<Array<Maybe<CategoryLinks>>>
categoryDetails?:Maybe<Array<Maybe<CategoryDetails>>>
};
export type CategoryDetails = {
  categoryDetails?:Maybe<CategoryData>
  categoryLinks?: Maybe<Array<Maybe<CategoryLink>>>
}
export type CategoryData ={
id?: Maybe<Scalars['UUID']>;
description?: Maybe<Scalars['String']>;
imageId?: Maybe<Scalars['UUID']>;
imageUrl?: Maybe<Scalars['String']>;
}
export type CategoryLink={
id?: Maybe<Scalars['UUID']>;
linkName?: Maybe<Scalars['String']>;
linkUrl?: Maybe<Scalars['String']>;
imageUrl?: Maybe<Scalars['String']>;
imageId?: Maybe<Scalars['UUID']>;
}
export type CategoryPagingResponse = {
   __typename?: 'CategoryPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<CategoryListResponse>>>;
};
export type CategoryResponse = {
  __typename?: 'CategoryResponse';
 code?: Maybe<Scalars['String']>;
 status?: Maybe<Scalars['String']>;
 message?: Maybe<Scalars['String']>;
 object?: Maybe<Category>;
 };

 export type CategoryLinkResponse = {
  __typename?: 'CategoryLinkResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Category>;
  };

export type CategoryLinks = {
  _typename?: 'CategoryLinks';
  id?:Maybe<Scalars['UUID']>;
  linkName?:Maybe<Scalars['UUID']>;
  linkUrl?: Maybe<Scalars['String']>;
  linkImage?:Maybe<Scalars['String']>;
  // imageUrl?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['UUID']>;
      };

 export type categoryDetailsInput = {
categoryDetails?: categoryDataInput
categoryLinks?: Maybe<Array<Maybe<categoryLinksInput>>>
  // id?: Maybe<Scalars['UUID']>;
  // linkName?:Maybe<Scalars['String']>;
  // linkUrl?: Maybe<Scalars['String']>;
  // imageUrl?:Maybe<Scalars['String']>;
  // categoryId?: Maybe<Scalars['UUID']>;

 };
 export type categoryDataInput = {
  id?: Maybe<Scalars['UUID']>;
  categoryId?: Maybe<Scalars['UUID']>;
  description?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['UUID']>;
 }
export type categoryLinksInput ={
id?: Maybe<Scalars['UUID']>;
linkName?: Maybe<Scalars['String']>;
linkUrl?: Maybe<Scalars['String']>;
categoryId?:  Maybe<Scalars['UUID']>;
imageId?:  Maybe<Scalars['UUID']>;
categoryDetailsId?:  Maybe<Scalars['UUID']>;
}
 export type PublishResponse = {
  _typename?: 'PublishResponse';
  status?: Maybe<Scalars['String']>;
  message?:Maybe<Scalars['String']>;

 };

export enum AttendanceEventType {
  AddSchedule = 'ADD_SCHEDULE',
  RemoveSchedule = 'REMOVE_SCHEDULE',
  RegisterEvent = 'REGISTER_EVENT',
  MarkAttendance = 'MARK_ATTENDANCE'
}

export type AttendanceRecord = {
   __typename?: 'AttendanceRecord';
  userProfileId?: Maybe<Scalars['UUID']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  barcodeImageUrl?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  recommendedSportList?: Maybe<Array<Maybe<Scalars['String']>>>;
  medicalConditionList?: Maybe<Array<Maybe<Scalars['String']>>>;
  sponsor?: Maybe<Scalars['String']>;
  attendancePercentage?: Maybe<Scalars['Float']>;
  totalAttending?: Maybe<Scalars['Int']>;
  totalSession?: Maybe<Scalars['Int']>;
};

export enum AttendeeRoleType {
  Coach = 'COACH',
  Athlete = 'ATHLETE',
  Caregiver = 'CAREGIVER',
  Admin = 'ADMIN',
 Athlete_support = 'ATHLETE_SUPPORT',
 Registered_user = 'REGISTERED_USER',
 Classifier = 'CLASSIFIER',
 Technical_official = 'TECHNICAL_OFFICIAL',
 Volunteer = 'VOLUNTEER',
 }

export type AuthenticationResponse = {
   __typename?: 'AuthenticationResponse';
  code?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
};


export enum CheckDistanceType {
  TrainingSessionLocation = 'TRAINING_SESSION_LOCATION'
}

export enum CheckInStatus {
  Pending = 'PENDING',
  Attending = 'ATTENDING',
  Absent = 'ABSENT'
}

export type Classification = {
   __typename?: 'Classification';
  id?: Maybe<Scalars['UUID']>;
  impairment?: Maybe<Impairment>;
  impairmentId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  displaySequence?: Maybe<Scalars['Int']>;
};
export type Assessments = {
  __typename?: 'Assessment';
  id?: Maybe<Scalars['UUID']>;
  name?:Maybe<Scalars['String']>;
  code?:Maybe<Scalars['String']>;
}
export type SportList = {
  __typename?: 'SportList';
 id?: Maybe<Scalars['UUID']>;
 name?: Maybe<Scalars['String']>;
 code?: Maybe<Scalars['String']>;
 status?: Maybe<Scalars['String']>;
};
export type Module = {
  __typename?: 'Module';
 id?: Maybe<Scalars['UUID']>;
 name?: Maybe<Scalars['String']>;
};
export type Country = {
   __typename?: 'Country';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  displaySequence?: Maybe<Scalars['Int']>;
};

export type DashboardFilter = {
  fromDate?: Maybe<DateTimeObjectInput>;
  toDate?: Maybe<DateTimeObjectInput>;
  filter?: Maybe<Scalars['String']>;
  sportId?: Maybe<Scalars['UUID']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAttended?: Maybe<Scalars['Boolean']>;
};
export type EventFilter = {
  fromDate?: Maybe<DateTimeObjectInput>;
  toDate?: Maybe<DateTimeObjectInput>;
  filter?: Maybe<Scalars['String']>;
  sportId?: Maybe<Scalars['UUID']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAttended?: Maybe<Scalars['Boolean']>;
};

export type DashboardPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};

export type DashboardPagingResponse = {
   __typename?: 'DashboardPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<EventTraining>>>;
};

export type DataResponse = ScheduleInfo | NotificationInbox | UserRecommendedSport | UserInformation;

export type DateTimeObject = {
   __typename?: 'DateTimeObject';
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type DateTimeObjectInput = {
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Int']>;
  hour?: Maybe<Scalars['Int']>;
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
  millisecond?: Maybe<Scalars['Int']>;
  timeZone?: Maybe<Scalars['String']>;
};

export type Event = {
   __typename?: 'Event';
  id?: Maybe<Scalars['UUID']>;
  description?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  eventDateObj?: Maybe<DateTimeObject>;
  eventImageUrl?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  fromTimeObj?: Maybe<DateTimeObject>;
  toTimeObj?: Maybe<DateTimeObject>;
  status?: Maybe<Scalars['String']>;
  sports?: Maybe<Array<Maybe<Sport>>>;
  sportIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  venue?: Maybe<Scalars['String']>;
  organiser?: Maybe<Scalars['String']>;
  totalVacancy?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Int']>;
  registered?: Maybe<Scalars['Int']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  eventStateTypeList?: Maybe<Array<Maybe<EventStateType>>>;
  additionalInfoList?: Maybe<Array<Maybe<EventAdditionalInfo>>>;
  isFeedbackCompleted?: Maybe<Scalars['Boolean']>;
  cancelReason?: Maybe<Scalars['String']>;
  eventRoles?: Maybe<Array<Maybe<EventRole>>>;
  fromRegDate?: Maybe<DateTimeObject>;
  toRegDate?:  Maybe<DateTimeObject>;
  emailNotification?: Maybe<Scalars['Boolean']>;
  externalLink?: Maybe<Scalars['String']>;
};

export type EventRole = {
  id: Maybe<Scalars['UUID']>;
roleCode: Maybe<Scalars['String']>;
roleName: Maybe<Scalars['String']>;
}

export type EventAdditionalAnswer = {
   __typename?: 'EventAdditionalAnswer';
  questionId?: Maybe<Scalars['UUID']>;
  answer?: Maybe<Scalars['String']>;
};

export type EventAdditionalAnswerInput = {
  questionId?: Maybe<Scalars['UUID']>;
  answer?: Maybe<Scalars['String']>;
};

export type EventAdditionalInfo = {
   __typename?: 'EventAdditionalInfo';
  id?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  questionType?: Maybe<QuestionType>;
  question?: Maybe<Scalars['String']>;
  optionValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  displaySequence?: Maybe<Scalars['Int']>;
};

export type EventAdditionalInfoInput = {
  id?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  questionType?: Maybe<QuestionType>;
  question?: Maybe<Scalars['String']>;
  optionValue?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EventAttendee = {
   __typename?: 'EventAttendee';
  id?: Maybe<Scalars['UUID']>;
  userProfile?: Maybe<UserProfile>;
  userProfileId?: Maybe<Scalars['UUID']>;
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['UUID']>;
  durationObj?: Maybe<DateTimeObject>;
  status?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  urlProfileImage?: Maybe<Scalars['String']>;
  isMarkAttendee?: Maybe<Scalars['Boolean']>;
  age?: Maybe<Scalars['Int']>;
  registrationRole?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  dateOfBirthObj?: Maybe<DateTimeObject>;
  gender?: Maybe<Scalars['String']>;
  additionalInfoList?: Maybe<Array<Maybe<EventAdditionalAnswer>>>;
  postalCode?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type EventAttendeeInput = {
  id?: Maybe<Scalars['UUID']>;
  durationObj?: Maybe<DateTimeObjectInput>;
  status?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  isMarkAttendee?: Maybe<Scalars['Boolean']>;
  registrationRole?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  dateOfBirthObj?: Maybe<DateTimeObjectInput>;
  gender?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  additionalInfoList?: Maybe<Array<Maybe<EventAdditionalAnswerInput>>>;
  postalCode?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
};

export type UserCertificatesInput = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  certificateName?: Maybe<Scalars['String']>;
  certificateDateObject?:Maybe<DateTimeObjectInput>;
  issuingAuthority?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
};


export type EventAttendeePagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};

export type EventAttendeePagingResponse = {
   __typename?: 'EventAttendeePagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<EventAttendee>>>;
};

export type EventAttendeeResponse = {
   __typename?: 'EventAttendeeResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<EventAttendee>;
};

export type EventFeedback = {
   __typename?: 'EventFeedback';
  id?: Maybe<Scalars['UUID']>;
  userProfile?: Maybe<UserProfile>;
  userProfileId?: Maybe<Scalars['UUID']>;
  event?: Maybe<Event>;
  eventId?: Maybe<Scalars['UUID']>;
  answerFeedbackInfoList?: Maybe<Array<Maybe<AnswerFeedbackInfo>>>;
  feedbackRate?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['String']>;
  isFeedbackCompleted?: Maybe<Scalars['Boolean']>;
  points?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<DateTimeObject>;
  registeredRole?: Maybe<Scalars['String']>;
};
export type TrainingSessionAttendance ={
userProfile?:Maybe<UserProfile>;
roleType?: Maybe<Scalars['String']>;
attendancePercentage?:Maybe<Scalars['Int']>;
attendance?: Maybe<Array<Maybe<Attendance>>>;
}
export type Attendance ={
sessionId?:Maybe<Scalars['UUID']>;
sessionNumber?:  Maybe<Scalars['Int']>;
trainingFromDateObj?:Maybe<DateTimeObject>;
trainingToDateObj?: Maybe<DateTimeObject>;
checkInStatus?: Maybe<Scalars['String']>;
reasonType?:  Maybe<Scalars['String']>;
otherReason?: Maybe<Scalars['String']>;
symbol?:  Maybe<Scalars['String']>;
}
export type EventFeedbackInfo = {
   __typename?: 'EventFeedbackInfo';
  id?: Maybe<Scalars['UUID']>;
  question?: Maybe<Scalars['String']>;
  questionType?: Maybe<QuestionType>;
  optionValue?: Maybe<Array<Maybe<OptionValue>>>;
};

export type EventFeedbackInput = {
  id?: Maybe<Scalars['UUID']>;
  userProfileId?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  answerFeedbackInfoList?: Maybe<Array<Maybe<AnswerFeedbackInfoInput>>>;
  feedbackRate?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['String']>;
};

export type EventFeedbackResponse = {
   __typename?: 'EventFeedbackResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<EventFeedback>;
};
export type DownloadCsvResponseContent ={
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
}
export type RedemptionDownloadResponse ={
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
}
export type EventInput = {
  id?: Maybe<Scalars['UUID']>;
  description?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  eventDateObj?: Maybe<DateTimeObjectInput>;
  eventType?: Maybe<Scalars['String']>;
  fromTimeObj?: Maybe<DateTimeObjectInput>;
  toTimeObj?: Maybe<DateTimeObjectInput>;
  cancelReason?: Maybe<Scalars['String']>;
  sportIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  status?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  totalVacancy?: Maybe<Scalars['Int']>;
  organiser?: Maybe<Scalars['String']>;
  additionalInfoList?: Maybe<Array<Maybe<EventAdditionalInfoInput>>>;
  eventRoles?: Maybe<Array<Maybe<EventRoleInput>>>;
  toEventDateObj ?: Maybe<DateTimeObjectInput>;
  fromEventDateObj ?: Maybe<DateTimeObjectInput>;
  toRegDate?:Maybe<DateTimeObjectInput>;
  fromRegDate ?:Maybe<DateTimeObjectInput>;
  twoHrNotificationSent?: Maybe<Scalars['Boolean']>
  emailNotification?: Maybe<Scalars['Boolean']>
  externalLink?:  Maybe<Scalars['String']>;
};
export type EventRoleInput = {
  id?: Maybe<Scalars['UUID']>;
  roleCode?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
}

export type DateInput = {
  dateObj?: Maybe<DateTimeObjectInput>;
  advStartDateObj?: Maybe<DateTimeObjectInput>;
  advEndDateObj?: Maybe<DateTimeObjectInput>;
}
export type EventPagingRequest = {

  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};

export type EventPagingResponse = {
   __typename?: 'EventPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Event>>>;
};

export type EventResponse = {
   __typename?: 'EventResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Event>;
};
export type EventPointsInput ={
  eventPointsList?: Maybe<Array<Maybe<EventPointsListInput>>>
}
export type EventPointsListInput ={
eventType?: Maybe<Scalars['String']>;
roleType?: Maybe<Scalars['String']>;
points?: Maybe<Scalars['Int']>;
}

export type UserCertificatesResponse = {
  __typename?: 'UserCertificatesResponse';
 status?: Maybe<Scalars['String']>;
 code?: Maybe<Scalars['String']>;
 message?: Maybe<Scalars['String']>;
 object?: Maybe<UserCertificates>;
};

export enum EventStateType {
  AddSchedule = 'ADD_SCHEDULE',
  RemoveSchedule = 'REMOVE_SCHEDULE',
  RegisterEvent = 'REGISTER_EVENT',
  RegisteredEvent = 'REGISTERED_EVENT',
  FeedBack = 'FEED_BACK',
  MarkAttendance = 'MARK_ATTENDANCE'
}

export type EventTraining = {
   __typename?: 'EventTraining';
  id?: Maybe<Scalars['UUID']>;
  eventName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  eventDateObj?: Maybe<DateTimeObject>;
  fromTimeObj?: Maybe<DateTimeObject>;
  toTimeObj?: Maybe<DateTimeObject>;
  venue?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  isMarkAttendee?: Maybe<Scalars['Boolean']>;
  isSignUp?: Maybe<Scalars['Boolean']>;
  isFeedBack?: Maybe<Scalars['Boolean']>;
};

export type EventType = {
   __typename?: 'EventType';
  groupName?: Maybe<Scalars['String']>;
  eventTypeName?: Maybe<Scalars['String']>;
};

export type ExportResponse = {
   __typename?: 'ExportResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
};

export type GeolocationInput = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type UserCertificates = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  certificateName?: Maybe<Scalars['String']>;
  certificateDateObject?: Maybe<DateTimeObject>;
  issuingAuthority?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
};
export type EventFeedbackPagingResponse ={
  __typename?: 'EventFeedbackPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<EventFeedback>>>;
}
export type EventFeedbackPagingRequest ={
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export type UserRecords = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  eventName?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
 countryObject?: Maybe<Country>;
 numberOfClassification?: Maybe<Scalars['String']>;
 approved?: Maybe<Scalars['Boolean']>;
 status?: Maybe<Scalars['String']>;
 statusDescription?: Maybe<Scalars['String']>;
 plainStatus?: Maybe<Scalars['String']>;
};
export type ReportsPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
}

export type ReportsPagingResponse = {
  __typename?: 'ReportsPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<UserRecords>>>;
}

export type Impairment = {
   __typename?: 'Impairment';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  displaySequence?: Maybe<Scalars['Int']>;
};
export type Link = {
  __typename?: 'Link';
 id?: Maybe<Scalars['UUID']>;
 name?: Maybe<Scalars['String']>;
 code?: Maybe<Scalars['String']>;
 link?: Maybe<Scalars['String']>;
 imageUrl?: Maybe<Scalars['String']>;
 displaySequence?: Maybe<Scalars['Int']>;
};

export type ListUserDailyAidResponse = {
   __typename?: 'ListUserDailyAidResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Array<Maybe<UserDailyAid>>>;
};

export type ListUserKinResponse = {
   __typename?: 'ListUserKinResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Array<Maybe<UserKin>>>;
};

export type ListUserMedicalConditionResponse = {
   __typename?: 'ListUserMedicalConditionResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Array<Maybe<UserMedicalCondition>>>;
};

export enum MarkAttendeeType {
  MarkAttendanceBarcode = 'MARK_ATTENDANCE_BARCODE',
  MarkAttendanceManual = 'MARK_ATTENDANCE_MANUAL',
  CheckInLocation = 'CHECK_IN_LOCATION'
}

export type Mutation = {
   __typename?: 'Mutation';
  createArticle?: Maybe<ArticleResponse>;
  updateArticle?: Maybe<ArticleResponse>;
  publishArticle?: Maybe<ArticleResponse>;
  deleteArticle?: Maybe<ArticleResponse>;
  changeStatusArticle?: Maybe<ArticleResponse>;
  login?: Maybe<AuthenticationResponse>;
  loginAdmin?: Maybe<AuthenticationResponse>;
  register?: Maybe<UserAccountResponse>;
  changePassword?: Maybe<AuthenticationResponse>;
  requestChangePassword?: Maybe<AuthenticationResponse>;
  checkToken?: Maybe<AuthenticationResponse>;
  createEvent?: Maybe<EventResponse>;
  updateEvent?: Maybe<EventResponse>;
  deleteEvent?: Maybe<EventResponse>;
  publishEvent?: Maybe<EventResponse>;
  unPublishEvent?: Maybe<EventResponse>;
  pushEvent?: Maybe<EventResponse>;
  markAttendanceEvent?: Maybe<EventAttendeeResponse>;
  feedbackEvent?: Maybe<EventFeedbackResponse>;
  exportUsers?: Maybe<ExportResponse>;
  updateNotification?: Maybe<ResponseContent>;
  deleteNotification?: Maybe<ResponseContent>;
  createSportArticle?: Maybe<SportResponse>;
  updateSportArticle?: Maybe<SportResponse>;
  deleteSport?: Maybe<SportResponse>;
  changeStatusSport?: Maybe<SportResponse>;
  createTraining?: Maybe<TrainingResponse>;
  updateTraining?: Maybe<TrainingResponse>;
  deleteTraining?: Maybe<TrainingResponse>;
  createTrainingSession?: Maybe<TrainingSessionResponse>;
  updateTrainingSession?: Maybe<TrainingSessionResponse>;
  deleteTrainingSession?: Maybe<TrainingSessionResponse>;
  markAttendingTrainingSession?: Maybe<TrainingSessionAttendeeResponse>;
  createUserAccount?: Maybe<UserAccountResponse>;
  deleteUserAccount?: Maybe<UserAccountResponse>;
  updateUserContact?: Maybe<UserContactResponse>;
  updateCurrentUserContact?: Maybe<UserContactResponse>;
  updateListUserDailyAid?: Maybe<ListUserDailyAidResponse>;
  updateCurrentUserDailyAid?: Maybe<UserDailyAidResponse>;
  deleteUserDailyAid?: Maybe<UserDailyAidResponse>;
  deleteCurrentUserDailyAid?: Maybe<UserDailyAidResponse>;
  createUserDocument?: Maybe<UserDocumentResponse>;
  updateUserDocument?: Maybe<UserDocumentResponse>;
  deleteUserDocument?: Maybe<UserDocumentResponse>;
  deleteUserDocumentFile?: Maybe<UserDocumentResponse>;
  updateUserKin?: Maybe<UserKinResponse>;
  updateListUserKin?: Maybe<ListUserKinResponse>;
  updateCurrentUserKin?: Maybe<UserKinResponse>;
  deleteCurrentUserKin?: Maybe<UserKinResponse>;
  deleteUserKin?: Maybe<UserKinResponse>;
  updateUserMedical?: Maybe<UserMedicalResponse>;
  updateCurrentUserMedical?: Maybe<UserMedicalResponse>;
  updateListUserMedicalCondition?: Maybe<ListUserMedicalConditionResponse>;
  // updateCurrentUserMedicalCondition?: Maybe<UserMedicalConditionResponse>;
  deleteUserMedicalCondition?: Maybe<UserMedicalConditionResponse>;
  deleteCurrentUserMedicalCondition?: Maybe<UserMedicalConditionResponse>;
  updateUserOrganisation?: Maybe<UserOrganisationResponse>;
  updateCurrentUserOrganisation?: Maybe<UserOrganisationResponse>;
  updateUserOther?: Maybe<UserOtherResponse>;
  updateCurrentUserOther?: Maybe<UserOtherResponse>;
  updateUserProfile?: Maybe<UserProfileResponse>;
  updateCurrentUserProfile?: Maybe<UserProfileResponse>;
  updateUserInformation?: Maybe<ResponseContent>;
  updateUserRecommendedSport?: Maybe<ResponseContent>;
  updateUserRole?: Maybe<ResponseContent>;
  updateUserRecommendedSportsByAdmin?: Maybe<RecommendedSportsResponse>;
  updateListUserSportLicenseByAdmin?: Maybe<UserSportLicensesResponse>;
  deleteCategory?: Maybe<CategoryResponse>;
  createCategory?: Maybe<CategoryResponse>;
  deleteCategoryLinks?: Maybe<CategoryResponse>;
  updateCategory?: Maybe<CategoryResponse>;
  updateUserCourses?: Maybe<UserCoursesResponse>;
  updateUserCertificates?: Maybe<UserCertificatesResponse>
  deleteUserRecords?: Maybe<UserRecordsResponse>;
  removeEventAttendanceByAdmin?: Maybe<EventAttendeeResponse>;
  updateListUserMedicalConditionAdmin?: Maybe<ListUserMedicalConditionResponse>;
  createUserPoint?:Maybe<UserPointResponse>;
  updateUserPoint?:Maybe<UserPointResponse>;
  deleteUserPoint?:Maybe<UserPointResponse>;
  saveUserRecordsForAdmin?:Maybe<UserRecordsResponse>;
  saveUserResultsForAdmin?: Maybe<UserRecordsResponse>;
  deleteUserResultsAdmin?: Maybe<UserRecordsResponse>;
};


export type MutationCreateArticleArgs = {
  article?: Maybe<ArticleInput>;
};


export type MutationUpdateArticleArgs = {
  article?: Maybe<ArticleInput>;
};


export type MutationPublishArticleArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteArticleArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationChangeStatusArticleArgs = {
  id?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  userAccount?: Maybe<UserAccountInput>;
};


export type MutationLoginAdminArgs = {
  userAccount?: Maybe<UserAccountInput>;
};


export type MutationRegisterArgs = {
  userAccount?: Maybe<UserAccountInput>;
};


export type MutationChangePasswordArgs = {
  newPassword?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};


export type MutationRequestChangePasswordArgs = {
  emailAddress?: Maybe<Scalars['String']>;
};


export type MutationCheckTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationCreateEventArgs = {
  event?: Maybe<EventInput>;
};


export type MutationUpdateEventArgs = {
  event?: Maybe<EventInput>;
};


export type MutationDeleteEventArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationPublishEventArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationUnPublishEventArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationPushEventArgs = {
  id?: Maybe<Scalars['UUID']>;
};
// export type MutationcreateCategoryArgs = {
//   category?: Maybe<categoryInput>;
//   categoryDetails?:  Maybe<Array<Maybe<categoryDetailsInput>>>;
// };

export type MutationUpdateCategoryArgs = {
  category?: Maybe<categoryInput>;
};
export type MutationUpdatePublishStatus = {
  id?: Maybe<Scalars['UUID']>;
  isPublish?:Maybe<Scalars['Boolean']>;
}

export type MutationMarkAttendanceEventArgs = {
  eventId?: Maybe<Scalars['UUID']>;
  actionType?: Maybe<AttendanceEventType>;
  eventAttendee?: Maybe<EventAttendeeInput>;
};


export type MutationFeedbackEventArgs = {
  eventFeedback?: Maybe<EventFeedbackInput>;
};


export type MutationUpdateNotificationArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteNotificationArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationCreateSportArticleArgs = {
  classificationIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  article?: Maybe<ArticleInput>;
  assessments?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateSportArticleArgs = {
  sportId?: Maybe<Scalars['UUID']>;
  classificationIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  assessments?: Maybe<Array<Maybe<Scalars['String']>>>;
  article?: Maybe<ArticleInput>;
};


export type MutationDeleteSportArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationChangeStatusSportArgs = {
  id?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
};


export type MutationCreateTrainingArgs = {
  training?: Maybe<TrainingInput>;
};


export type MutationUpdateTrainingArgs = {
  training?: Maybe<TrainingInput>;
};


export type MutationDeleteTrainingArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationCreateTrainingSessionArgs = {
  trainingSession?: Maybe<TrainingSessionInput>;
};


export type MutationUpdateTrainingSessionArgs = {
  trainingSession?: Maybe<TrainingSessionInput>;
};


export type MutationDeleteTrainingSessionArgs = {
  id?: Maybe<Scalars['UUID']>;
};
export type MutationDeleteUserRecordsArgs = {
  id?: Maybe<Scalars['UUID']>;
};

export type MutationMarkAttendingTrainingSessionArgs = {
  trainingSessionAttendee?: Maybe<TrainingSessionAttendeeInput>;
  type?: Maybe<MarkAttendeeType>;
};


export type MutationCreateUserAccountArgs = {
  userAccount?: Maybe<UserAccountInput>;
};


export type MutationDeleteUserAccountArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateUserContactArgs = {
  userContact?: Maybe<UserContactInput>;
};


export type MutationUpdateCurrentUserContactArgs = {
  userContact?: Maybe<UserContactInput>;
};


export type MutationUpdateListUserDailyAidArgs = {
  userDailyAidList?: Maybe<Array<Maybe<UserDailyAidInput>>>;
};


export type MutationUpdateCurrentUserDailyAidArgs = {
  userDailyAid?: Maybe<UserDailyAidInput>;
};


export type MutationDeleteUserDailyAidArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteCurrentUserDailyAidArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationCreateUserDocumentArgs = {
  userDocument?: Maybe<UserDocumentInput>;
};


export type MutationUpdateUserDocumentArgs = {
  userDocument?: Maybe<UserDocumentInput>;
};


export type MutationDeleteUserDocumentArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteUserDocumentFileArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateUserKinArgs = {
  userKin?: Maybe<UserKinInput>;
};


export type MutationUpdateListUserKinArgs = {
  userKins?: Maybe<Array<Maybe<UserKinInput>>>;
};


export type MutationUpdateCurrentUserKinArgs = {
  userKin?: Maybe<UserKinInput>;
};


export type MutationDeleteCurrentUserKinArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteUserKinArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateUserMedicalArgs = {
  userMedical?: Maybe<UserMedicalInput>;
};


export type MutationUpdateCurrentUserMedicalArgs = {
  userMedical?: Maybe<UserMedicalInput>;
};


export type MutationUpdateListUserMedicalConditionArgs = {
  userMedicalConditionList?: Maybe<Array<Maybe<UserMedicalConditionInput>>>;
};


export type MutationUpdateCurrentUserMedicalConditionArgs = {
  userMedicalCondition?: Maybe<UserMedicalConditionInput>;
};


export type MutationDeleteUserMedicalConditionArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteCurrentUserMedicalConditionArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateUserOrganisationArgs = {
  userOrganisation?: Maybe<UserOrganisationInput>;
};


export type MutationUpdateCurrentUserOrganisationArgs = {
  userOrganisation?: Maybe<UserOrganisationInput>;
};


export type MutationUpdateUserOtherArgs = {
  userOther?: Maybe<UserOtherInput>;
};


export type MutationUpdateCurrentUserOtherArgs = {
  userOther?: Maybe<UserOtherInput>;
};


export type MutationUpdateUserProfileArgs = {
  userProfile?: Maybe<UserProfileInput>;
};


export type MutationUpdateCurrentUserProfileArgs = {
  userProfile?: Maybe<UserProfileInput>;
};


export type MutationUpdateUserInformationArgs = {
  userInformation?: Maybe<UserInformationInput>;
};


export type MutationUpdateUserRecommendedSportArgs = {
  recommendedSport?: Maybe<UserRecommendedSportInput>;
};


export type MutationUpdateUserRecommendedSportsByAdminArgs = {
  recommendedSports?: Maybe<UserRecommendedSportsInput>;
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateListUserSportLicenseByAdminArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
  userSportLicensesInput?: Maybe<UserSportLicensesInput>;
};

export type Nationality = {
   __typename?: 'Nationality';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  displaySequence?: Maybe<Scalars['Int']>;
};

export type NotificationFilter = {
  fromDate?: Maybe<DateTimeObjectInput>;
  toDate?: Maybe<DateTimeObjectInput>;
  status?: Maybe<NotificationStatus>;
};

export type NotificationInbox = {
   __typename?: 'NotificationInbox';
  id?: Maybe<Scalars['UUID']>;
  messageTitle?: Maybe<Scalars['String']>;
  messageDescription?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['UUID']>;
  referenceType?: Maybe<NotificationReferenceType>;
  status?: Maybe<NotificationStatus>;
  sentBy?: Maybe<Scalars['String']>;
  sentDateObj?: Maybe<DateTimeObject>;
};

export enum NotificationReferenceType {
  Event = 'EVENT'
}

export enum NotificationStatus {
  Read = 'READ',
  Unread = 'UNREAD'
}

export type OptionValue = {
   __typename?: 'OptionValue';
  displaySequence?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  isChecked?: Maybe<Scalars['Boolean']>;
};

export type OptionValueInput = {
  displaySequence?: Maybe<Scalars['Int']>;
  isChecked?: Maybe<Scalars['Boolean']>;
};
export type RewardsPagingRequest ={
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
 filter?: Maybe<Scalars['String']>;
}
export type RewardsListResponse ={
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
pageIndex?: Maybe<Scalars['Int']>;
totalPage?: Maybe<Scalars['Int']>;
total?: Maybe<Scalars['Int']>;
data?: Maybe<Array<Maybe<Rewards>>>;
}
export type RewardsRedeemedListResponse ={
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
pageIndex?: Maybe<Scalars['Int']>;
totalPage?: Maybe<Scalars['Int']>;
total?: Maybe<Scalars['Int']>;
data?: Maybe<Array<Maybe<UserRedeemRewardsDTO>>>;
}
export type RewardsRedeemResponse={
  status?:Maybe<Scalars['String']>;
  code?:Maybe<Scalars['String']>;
  message?:Maybe<Scalars['String']>;
  object?: Maybe<UserRedeemRewards>
}
export type UserRedeemRewards={
id?:Maybe<Scalars['UUID']>;
userProfileId?: Maybe<Scalars['UUID']>;
rewardsId?: Maybe<Scalars['UUID']>;
redeemedDate?:Maybe<DateTimeObject>;
redeemedPoints?: Maybe<Scalars['Int']>;
redemptionOptionMethod?:Maybe<Scalars['String']>;
mailingAddress?: Maybe<Scalars['String']>;
postalCode?: Maybe<Scalars['String']>;
remarks?:Maybe<Scalars['String']>;
rewardStatus?: Maybe<Scalars['String']>;
userFullName?:Maybe<Scalars['String']>;
emailAddress?:Maybe<Scalars['String']>;
rewardName?: Maybe<Scalars['String']>;
}
export type UsedPointDTO ={
  redeemedDate?:Maybe<DateTimeObject>;
userPointsRedeemedList?:Maybe<Array<Maybe<UserRedeemRewards>>>;
}
export type UsedPointDTOResponse={
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Array<Maybe<UsedPointDTO>>>;
}
export type UserRedeemRewardsDTO={
id?:Maybe<Scalars['UUID']>;
userProfileId?: Maybe<Scalars['UUID']>;
rewardsId?: Maybe<Scalars['UUID']>;
redeemedDate?:Maybe<DateTimeObject>;
redeemedPoints?: Maybe<Scalars['Int']>;
redemptionOptionMethod?:Maybe<Scalars['String']>;
mailingAddress?: Maybe<Scalars['String']>;
postalCode?: Maybe<Scalars['String']>;
remarks?:Maybe<Scalars['String']>;
rewardStatus?: Maybe<Scalars['String']>;
userFullName?:Maybe<Scalars['String']>;
emailAddress?:Maybe<Scalars['String']>;
}
export type Rewards ={
  id?: Maybe<Scalars['UUID']>;
name?: Maybe<Scalars['String']>;
points?:  Maybe<Scalars['Int']>;
quantity?:  Maybe<Scalars['Int']>;
imageUrl?:Maybe<Scalars['String']>;
description?: Maybe<Scalars['String']>;
startDateObj?: Maybe<DateTimeObject>;
endDateObj?: Maybe<DateTimeObject>
}

export type PagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type PagingResponse = {
   __typename?: 'PagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<DataResponse>>>;
};

export type Query = {
   __typename?: 'Query';
  getListArticle?: Maybe<ArticlePagingResponse>;
  getListUserResultsEvent?:Maybe<Array<Maybe<Scalars['String']>>>;
  generateUserResultsCsv?:Maybe<Scalars['String']>;
  getListArticleForAdmin?: Maybe<ArticlePagingResponse>;
  getArticle?: Maybe<Article>;
  getArticleForAdmin?: Maybe<Article>;
  searchArticle?: Maybe<Array<Maybe<Article>>>;
  getListArticleType?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListAidType?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListBloodType?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListClassification?: Maybe<Array<Maybe<Classification>>>;
  getListClothSize?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListCountry?: Maybe<Array<Maybe<Country>>>;
  getListEventType?: Maybe<Array<Maybe<EventType>>>;
  getListEventTrainingType?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListEventStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListEventAttendanceStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListFrequency?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListImpairment?: Maybe<Array<Maybe<Impairment>>>;
  getListMaritalStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListNationality?: Maybe<Array<Maybe<Nationality>>>;
  getListPostType?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListRace?: Maybe<Array<Maybe<Race>>>;
  getListReligion?: Maybe<Array<Maybe<Religion>>>;
  getListRelationship?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListReasonType?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListRole?: Maybe<Array<Maybe<Role>>>;
  getListShoeSize?: Maybe<Array<Maybe<Scalars['BigDecimal']>>>;
  getListTrainingStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListUserStatus?: Maybe<Array<Maybe<Scalars['String']>>>;
  getTotalAthlete?: Maybe<Scalars['Int']>;
  getTotalUsers?: Maybe<Scalars['Int']>;
  getTotalEvent?: Maybe<Scalars['Int']>;
  getTotalTraining?: Maybe<Scalars['Int']>;
  getListEventTrainingForAdmin?: Maybe<DashboardPagingResponse>;
  getListEventTraining?: Maybe<DashboardPagingResponse>;
  getListSchedule?: Maybe<PagingResponse>;
  getListEventForAdmin?: Maybe<EventPagingResponse>;
  getEventForAdmin?: Maybe<Event>;
  getListEvent?: Maybe<EventPagingResponse>;
  getEvent?: Maybe<Event>;
  getListEventAttendeeForAdmin?: Maybe<EventAttendeePagingResponse>;
  getListEventAttendee?: Maybe<EventAttendeePagingResponse>;
  getEventAttendee?: Maybe<EventAttendee>;
  getEventFeedback?: Maybe<EventFeedback>;
  getListEventAdditionalInfo?: Maybe<Array<Maybe<EventAdditionalInfo>>>;
  getListEventFeedbackInfo?: Maybe<Array<Maybe<EventFeedbackInfo>>>;
  getListNotification?: Maybe<PagingResponse>;
  getListSport?: Maybe<Array<Maybe<Sport>>>;
  getListSportForAdmin?: Maybe<Array<Maybe<Sport>>>;
  getSport?: Maybe<Sport>;
  getSportForAdmin?: Maybe<Sport>;
  getListSportPagingForAdmin?: Maybe<SportPagingResponse>;
  getListTrainingForAdmin?: Maybe<TrainingPagingResponse>;
  getTrainingForAdmin?: Maybe<Training>;
  getListTrainingSessionForAdmin?: Maybe<TrainingSessionPagingResponse>;
  getTrainingSessionForAdmin?: Maybe<TrainingSession>;
  getListTrainingSession?: Maybe<TrainingSessionPagingResponse>;
  getTrainingSession?: Maybe<TrainingSession>;
  getListTrainingSessionAttendee?: Maybe<TrainingSessionAttendancePagingResponse>;
  getCurrentTrainingSessionAttendee?: Maybe<TrainingSessionAttendee>;
  getTraining?: Maybe<Training>;
  checkDistance?: Maybe<Scalars['Boolean']>;
  getListAttendanceRecord?: Maybe<Array<Maybe<AttendanceRecord>>>;
  getAttendanceRecord?: Maybe<AttendanceRecord>;
  getListTrainingSessionAttendanceRecord?: Maybe<Array<Maybe<TrainingSession>>>;
  getTotalSessions?: Maybe<Scalars['Int']>;
  getTotalSessionsRealTime?: Maybe<Scalars['Int']>;
  getPermission?: Maybe<Array<Maybe<Scalars['String']>>>;
  getUserContact?: Maybe<UserContact>;
  getCurrentUserContact?: Maybe<UserContact>;
  getListUserDailyAid?: Maybe<Array<Maybe<UserDailyAid>>>;
  getListCurrentUserDailyAid?: Maybe<Array<Maybe<UserDailyAid>>>;
  getUserDailyAid?: Maybe<UserDailyAid>;
  getListUserDocument?: Maybe<Array<Maybe<UserDocument>>>;
  getUserDocument?: Maybe<UserDocument>;
  getListUserDocumentFile?: Maybe<Array<Maybe<UserDocumentFile>>>;
  downloadUserDocumentFile?: Maybe<Scalars['String']>;
  downloadEventFeedbackFile?: Maybe<Scalars['String']>;
  downloadUserTrainingAttendanceUrl?: Maybe<Scalars['String']>;
  downloadEventAttendanceFile?: Maybe<Scalars['String']>;
  getUserKin?: Maybe<UserKin>;
  getListUserKin?: Maybe<Array<Maybe<UserKin>>>;
  getListCurrentUserKin?: Maybe<Array<Maybe<UserKin>>>;
  getUserMedical?: Maybe<UserMedical>;
  getCurrentUserMedical?: Maybe<UserMedical>;
  getListUserMedicalCondition?: Maybe<Array<Maybe<UserMedicalCondition>>>;
  getListCurrentUserMedicalCondition?: Maybe<Array<Maybe<UserMedicalCondition>>>;
  getUserMedicalCondition?: Maybe<UserMedicalCondition>;
  getUserOrganisation?: Maybe<UserOrganisation>;
  getCurrentUserOrganisation?: Maybe<UserOrganisation>;
  getCurrentUserOther?: Maybe<UserOther>;
  getUserOther?: Maybe<UserOther>;
  getListUserProfile?: Maybe<UserPagingResponse>;
  getUserProfile?: Maybe<UserProfile>;
  getCurrentUserProfile?: Maybe<UserProfile>;
  getUserInformation?: Maybe<UserInformation>;
  getListUserRecommendedSport?: Maybe<RecommendedSportPagingResponse>;
  getListRecommendedSportByAdmin?: Maybe<RecommendedSportPagingResponse>;
  getListSportSdscCarding?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListSportGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListSportSpexCarding?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListUserSportLicenseByAdmin?: Maybe<SportLicensePagingResponse>;
  getListUserSportLicenseLevel?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListCategoryForAdmin?: Maybe<CategoryPagingResponse>;
  getCategory?: Maybe<Category>;
  getAllSystemLogs?: Maybe<LogPagingResponse>;
  getUserCourses?: Maybe<Array<Maybe<UserCourses>>>;
  getCurrentUserCourses?: Maybe<Array<Maybe<UserCourses>>>;
  getUserCertificates?: Maybe<Array<Maybe<UserCertificates>>>;
  getCurrentUserCertificates?: Maybe<Array<Maybe<UserCertificates>>>;
  getUserRecordsOfUserForAdmin?: Maybe<ReportsPagingResponse>;
  getUserRecords?: Maybe<UserRecords>;
  getListEventFeedback?: Maybe<EventFeedbackPagingResponse>;
  getEventFeedbackChartData?: Maybe<Array<Maybe<ChartData>>>;
  getUserAccessGroupDetails?: Maybe<UserAccessListResponse>;
  getListUserPointAdmin?: Maybe<UserPointListResponse>;
  getListUserProfileForTraining?: Maybe<UserPagingResponse>;
  getRewardsListForAdmin?: Maybe<RewardsListResponse>;
  getRewardsDetails?:Maybe<RewardsResponse>;
  getRewardsRedeemedList?:Maybe<RewardsRedeemedListResponse>;
};

export type QueryGetUserCoursesArgs = {
  userAccountId?:  Maybe<Scalars['UUID']>;
}

export type QueryGetUserCertificatesArgs = {
  userAccountId?:  Maybe<Scalars['UUID']>;
}

export type QueryGetListArticleArgs = {
  pagingRequest?: Maybe<ArticlePagingRequest>;
  type?: Maybe<Scalars['String']>;
};


export type QueryGetListArticleForAdminArgs = {
  articleFilter?: Maybe<ArticleFilter>;
  pagingRequest?: Maybe<ArticlePagingRequest>;
};


export type QueryGetArticleArgs = {
  id?: Maybe<Scalars['UUID']>;
};

export type QuerygetUserRecordsOfUserForAdminArgs = {
  id?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<ReportsPagingRequest>;
};
export type QuerygetUserRecordsArgs = {
  id?: Maybe<Scalars['UUID']>;
};
export type QueryGetArticleForAdminArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QuerySearchArticleArgs = {
  content?: Maybe<Scalars['String']>;
};


export type QueryGetListClassificationArgs = {
  impairmentId?: Maybe<Scalars['UUID']>;
};


export type QueryGetTotalAthleteArgs = {
  status?: Maybe<Scalars['String']>;
};
export type QueryGetTotalUsersArgs = {
  status?: Maybe<Scalars['String']>;
}

export type QueryGetTotalEventArgs = {
  filter?: Maybe<DashboardFilter>;
};


export type QueryGetTotalTrainingArgs = {
  filter?: Maybe<DashboardFilter>;
};


export type QueryGetListEventTrainingForAdminArgs = {
  filter?: Maybe<DashboardFilter>;
  pagingRequest?: Maybe<DashboardPagingRequest>;
};


export type QueryGetListEventTrainingArgs = {
  pagingRequest?: Maybe<DashboardPagingRequest>;
  eventType?: Maybe<Scalars['String']>;
};


export type QueryGetListScheduleArgs = {
  filter?: Maybe<ScheduleFilter>;
  pagingRequest?: Maybe<SchedulePagingRequest>;
};


export type QueryGetListEventForAdminArgs = {
  filter?: Maybe<DashboardFilter>;
  pagingRequest?: Maybe<EventPagingRequest>;
  sportList?:Maybe<Array<Maybe<['UUID']>>>;
  };


export type QueryGetEventForAdminArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetListEventArgs = {
  pagingRequest?: Maybe<EventPagingRequest>;
  filter?: Maybe<DashboardFilter>;
  isRecommended?: Maybe<Scalars['Boolean']>;
};


export type QueryGetEventArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetListEventAttendeeForAdminArgs = {
  eventId?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<EventAttendeePagingRequest>;
};


export type QueryGetListEventAttendeeArgs = {
  eventId?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<EventAttendeePagingRequest>;
};


export type QueryGetEventAttendeeArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetEventFeedbackArgs = {
  eventId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListEventAdditionalInfoArgs = {
  eventId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListEventFeedbackInfoArgs = {
  eventId?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<EventFeedbackPagingRequest>;
};


export type QueryGetListNotificationArgs = {
  filter?: Maybe<NotificationFilter>;
  pagingRequest?: Maybe<PagingRequest>;
};


export type QueryGetListSportArgs = {
  classificationId?: Maybe<Scalars['UUID']>;
  filter?: Maybe<SportPagingRequest>;
};


export type QueryGetListSportForAdminArgs = {
  filter?: Maybe<SportPagingRequest>;
  classificationId?: Maybe<Scalars['UUID']>;
};


export type QueryGetSportArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetSportForAdminArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetListSportPagingForAdminArgs = {
  statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
  pagingRequest?: Maybe<SportPagingRequest>;
};


export type QueryGetListTrainingForAdminArgs = {
  filter?: Maybe<DashboardFilter>;
  pagingRequest?: Maybe<TrainingPagingRequest>;
};


export type QueryGetTrainingForAdminArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetListTrainingSessionForAdminArgs = {
  trainingId?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<TrainingSessionPagingRequest>;
};


export type QueryGetTrainingSessionForAdminArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetListTrainingSessionArgs = {
  filter?: Maybe<TrainingSessionFilter>;
  trainingId?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<TrainingSessionPagingRequest>;
};


export type QueryGetTrainingSessionArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetListTrainingSessionAttendeeArgs = {
  trainingSessionId?: Maybe<Scalars['UUID']>;
  roleType?: Maybe<AttendeeRoleType>;
  pagingRequest?: Maybe<TrainingSessionAttendeePagingRequest>;
};


export type QueryGetCurrentTrainingSessionAttendeeArgs = {
  trainingSessionId?: Maybe<Scalars['UUID']>;
};


export type QueryGetTrainingArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryCheckDistanceArgs = {
  checkType?: Maybe<CheckDistanceType>;
  id?: Maybe<Scalars['UUID']>;
  deviceLocation?: Maybe<GeolocationInput>;
};


export type QueryGetListAttendanceRecordArgs = {
  filter?: Maybe<TrainingSessionFilter>;
};


export type QueryGetAttendanceRecordArgs = {
  filter?: Maybe<TrainingSessionFilter>;
  userProfileId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListTrainingSessionAttendanceRecordArgs = {
  filter?: Maybe<TrainingSessionFilter>;
  userProfileId?: Maybe<Scalars['UUID']>;
  checkInStatus?: Maybe<CheckInStatus>;
};


export type QueryGetTotalSessionsArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetTotalSessionsRealTimeArgs = {
  training?: Maybe<TrainingInput>;
};


export type QueryGetPermissionArgs = {
  module?: Maybe<Scalars['String']>;
};


export type QueryGetUserContactArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserDailyAidArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserDailyAidArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserDocumentArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserDocumentArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserDocumentFileArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryDownloadUserDocumentFileArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserKinArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserKinArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserMedicalArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserMedicalConditionArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserMedicalConditionArgs = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserOrganisationArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserOtherArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserProfileArgs = {
  roleCode?: Maybe<Scalars['String']>;
  pagingRequest?: Maybe<UserPagingRequest>;
  status?: Maybe<Scalars['String']>;
};

export type QueryGetListUserProfileForTrainingArgs = {
  roleCode?: Maybe<Scalars['String']>;
  pagingRequest?: Maybe<UserPagingRequest>;
  status?: Maybe<Scalars['String']>;
};

export type QueryGetUserProfileArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserRecommendedSportArgs = {
  pagingRequest?: Maybe<RecommendedSportPagingRequest>;
  filter?: Maybe<RecommendedSportFilter>;
};


export type QueryGetListRecommendedSportByAdminArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<RecommendedSportPagingRequest>;
};


export type QueryGetListUserSportLicenseByAdminArgs = {
  eventId?: Maybe<Scalars['UUID']>;

};
export type QueryGetEventFeedbackChartDataByAdminArgs = {
  userAccountId?: Maybe<Scalars['UUID']>;
  pageRequest?: Maybe<PagingRequest>;
};

export enum QuestionType {
  FreeText = 'FREE_TEXT',
  DropDown = 'DROP_DOWN',
  CheckBox = 'CHECK_BOX'
}
export enum ActionType{
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

export type Race = {
   __typename?: 'Race';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  displaySequence?: Maybe<Scalars['Int']>;
};

export type RecommendedSportFilter = {
  classificationIdList?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export type RecommendedSportPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};

export type RecommendedSportPagingResponse = {
   __typename?: 'RecommendedSportPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<UserRecommendedSport>>>;
};

export type RecommendedSportsResponse = {
   __typename?: 'RecommendedSportsResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Array<Maybe<UserRecommendedSport>>>;
};

export type Religion = {
   __typename?: 'Religion';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  displaySequence?: Maybe<Scalars['Int']>;
};

export type DashboardUserRecordResponse={
  __typename?: 'DashboardUserRecordResponse';
  code?: Maybe<Scalars['String']>;
  status?:Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserRecordResponse>;
}
export type DashboardUserResultResponse={
  __typename?: 'DashboardUserRecordResponse';
  code?: Maybe<Scalars['String']>;
  status?:Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserResultResponse>;
}
export type DashboardUserRewardResponse={
  __typename?: 'DashboardUserRewardResponse';
  code?: Maybe<Scalars['String']>;
  status?:Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe< UserRewardResponse>;
}

export type  UserRewardResponse={
  __typename?: 'UserRewardResponse';
    redeemedId?: Maybe<Scalars['UUID']>;
    fullName?: Maybe<Scalars['String']>;
    emailAddress?: Maybe<Scalars['String']>;
    rewardName?: Maybe<Scalars['String']>;
    remarks?:Maybe<Scalars['String']>;
    redeemedDate?: Maybe<DateTimeObject>;
    rewardStatus?:Maybe<Scalars['String']>;
    redemptionOptionMethod: Maybe<Scalars['String']>;
}

export type UserResultResponse={
  _typename?: 'UserResultResponse';
  resultId?: Maybe<Scalars['UUID']>;
  competitionName: Maybe<Scalars['String']>;
  competitionDate: Maybe<Scalars['String']>;
  sportName:Maybe<Scalars['String']>;
  eventName: Maybe<Scalars['String']>;
  competitionCategory: Maybe<Scalars['String']>;
  competitionLevel: Maybe<Scalars['String']>;
  competitionType: Maybe<Scalars['String']>;
  countryCompeting: Maybe<Scalars['String']>;
  noOfParticipants: Maybe<Scalars['String']>;
  positionNumber: Maybe<Scalars['String']>;
  assessment: Maybe<Scalars['String']>;
  result: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  statusDescription: Maybe<Scalars['String']>;
  emailAddress: Maybe<Scalars['String']>;
  fullName: Maybe<Scalars['String']>;
}
export type UserRecordResponse={
  _typename?: 'UserRecordResponse';
  id?: Maybe<Scalars['UUID']>;
  eventName?: Maybe<Scalars['String']>;
  eventDate?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  status?:Maybe<Scalars['String']>;
  statusDescription?:Maybe<Scalars['String']>;
  emailAddress?:Maybe<Scalars['String']>;
  fullName?:Maybe<Scalars['String']>;
}
export type PendingActionPagingResponse={
  __typename?: 'PendingActionPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<PendingActionDTO>>>;
}
export type PendingActionDTO = {
  __typename?: 'PendingActionDTO';
  recordsResultsId?: Maybe<Scalars['UUID']>;
  status?:Maybe<Scalars['String']>;
  description?:Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['UUID']>;
  emailAddress?: Maybe<Scalars['String']>;
  profileId?:Maybe<Scalars['UUID']>;
  fullName?:Maybe<Scalars['String']>;
  submittedDate?:Maybe<DateTimeObject>;
  redeemedId?: Maybe<Scalars['UUID']>;
  rewardId?:Maybe<Scalars['UUID']>;
}

export type ResponseContent = {
   __typename?: 'ResponseContent';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<DataResponse>;
};

export type Role = {
   __typename?: 'Role';
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type ScheduleFilter = {
  fromDate?: Maybe<DateTimeObjectInput>;
  toDate?: Maybe<DateTimeObjectInput>;
  isAttended?: Maybe<Scalars['Boolean']>;
};

export type ScheduleInfo = {
   __typename?: 'ScheduleInfo';
  referenceId?: Maybe<Scalars['UUID']>;
  referenceType?: Maybe<ScheduleType>;
  name?: Maybe<Scalars['String']>;
  isAttended?: Maybe<Scalars['Boolean']>;
  eventDate?: Maybe<DateTimeObject>;
  fromTime?: Maybe<DateTimeObject>;
  toTime?: Maybe<DateTimeObject>;
};

export type SchedulePagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export enum ScheduleType {
  TrainingSession = 'TRAINING_SESSION',
  Event = 'EVENT'
}

export type Sport = {
   __typename?: 'Sport';
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  athleteCount?: Maybe<Scalars['Int']>;
  coachCount?: Maybe<Scalars['Int']>;
  article?: Maybe<Article>;
  articleId?: Maybe<Scalars['UUID']>;
  imageUrl?: Maybe<Scalars['String']>;
  classifications?: Maybe<Array<Maybe<Classification>>>;
  impairments?: Maybe<Array<Maybe<Impairment>>>;
  displaySequence?: Maybe<Scalars['Int']>;
  assessments?: Maybe<Array<Maybe<Assessments>>>;
  // selected?:  Maybe<Scalars['Boolean']>;
};

export type SportInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type SportLicensePagingResponse = {
   __typename?: 'SportLicensePagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<UserSportLicense>>>;
};

export type SportPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};
export type ResultsPagingRequest ={
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
}

export type SportPagingResponse = {
   __typename?: 'SportPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Sport>>>;
};

export type SportResponse = {
   __typename?: 'SportResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Sport>;
};

export type UserRecordsResponse = {
  __typename?: 'UserRecordsResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserRecords>;
}
export type More = {
  __typename?: 'More';
 id?: Maybe<Scalars['UUID']>;
 about?: Maybe<Scalars['String']>;
 pathway?: Maybe<Scalars['String']>;
 help?: Maybe<Scalars['String']>;
  article?: Maybe<Article>;
 articleId?: Maybe<Scalars['UUID']>;
 imageUrl?: Maybe<Scalars['String']>;
  displaySequence?: Maybe<Scalars['Int']>;
};


export type Training = {
   __typename?: 'Training';
  id?: Maybe<Scalars['UUID']>;
  trainingName?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  toDateObj?: Maybe<DateTimeObject>;
  fromDateObj?: Maybe<DateTimeObject>;
  numberOfSession?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  sport?: Maybe<Sport>;
  sportId?: Maybe<Scalars['UUID']>;
  trainingDateTimes?: Maybe<Array<Maybe<TrainingDateTime>>>;
  athleteManagers?: Maybe<Array<Maybe<UserProfile>>>;
  athleteManagerIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  coaches?: Maybe<Array<Maybe<UserProfile>>>;
  coachIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  athletes?: Maybe<Array<Maybe<UserProfile>>>;
  athleteIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  sportImageUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type UserPointListResponse = {
status?: Maybe<Scalars['String']>;
code?: Maybe<Scalars['String']>;
message?: Maybe<Scalars['String']>;
object?: Maybe<Array<Maybe<UserPoint>>>;
}
export type UserPoint = {
id?:  Maybe<Scalars['UUID']>;
userProfileId?:Maybe<Scalars['UUID']>;
eventFeedbackId?:Maybe<Scalars['UUID']>;
surveyId?:Maybe<Scalars['UUID']>;
type?: Maybe<Scalars['String']>;
receivedDateObj?:Maybe<DateTimeObject>
points?: Maybe<Scalars['Int']>;
}
export type EventPointsMap ={
eventType?:Maybe<Scalars['String']>;
roleTypeList?:Maybe<Array<Maybe<RoleTypeList>>>
}
export type RoleTypeList ={
eventType?: Maybe<Scalars['String']>;
roleType?: Maybe<Scalars['String']>;
points?: Maybe<Scalars['Int']>;
}
export type RewardsInput ={
id?: Maybe<Scalars['UUID']>;
name?: Maybe<Scalars['String']>;
points?: Maybe<Scalars['Int']>;
description?: Maybe<Scalars['String']>;
quantity?: Maybe<Scalars['Int']>;
startDateObj?: Maybe<DateTimeObjectInput>
endDateObj?: Maybe<DateTimeObjectInput>
}
export type RewardsResponse={
status?: Maybe<Scalars['String']>;
code?: Maybe<Scalars['String']>;
message?: Maybe<Scalars['String']>;
object?: Maybe<Rewards>
}
export type TrainingDateTime = {
   __typename?: 'TrainingDateTime';
  weekday?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  sequence?: Maybe<Scalars['Int']>;
  fromTimeObj?: Maybe<DateTimeObject>;
  toTimeObj?: Maybe<DateTimeObject>;
};

export type TrainingDateTimeInput = {
  sequence?: Maybe<Scalars['Int']>;
  weekday?: Maybe<Scalars['Int']>;
  fromTimeObj?: Maybe<DateTimeObjectInput>;
  toTimeObj?: Maybe<DateTimeObjectInput>;
};

export type TrainingFilter = {
  fromDate?: Maybe<DateTimeObjectInput>;
  toDate?: Maybe<DateTimeObjectInput>;
  filter?: Maybe<Scalars['String']>;
};

export type TrainingInput = {
  id?: Maybe<Scalars['UUID']>;
  trainingName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  toDateObj?: Maybe<DateTimeObjectInput>;
  fromDateObj?: Maybe<DateTimeObjectInput>;
  numberOfSession?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  sportId?: Maybe<Scalars['UUID']>;
  trainingDateTimes?: Maybe<Array<Maybe<TrainingDateTimeInput>>>;
  athleteManagerIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  coachIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  athleteIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  postalCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type TrainingPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};

export type TrainingPagingResponse = {
   __typename?: 'TrainingPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Training>>>;
};

export type TrainingResponse = {
   __typename?: 'TrainingResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<Training>;
};

export type TrainingSession = {
   __typename?: 'TrainingSession';
  id?: Maybe<Scalars['UUID']>;
  training?: Maybe<Training>;
  trainingName?: Maybe<Scalars['String']>;
  trainingId?: Maybe<Scalars['UUID']>;
  sessionNumber?: Maybe<Scalars['Int']>;
  dateTrainingObj?: Maybe<DateTimeObject>;
  fromTimeObj?: Maybe<DateTimeObject>;
  toTimeObj?: Maybe<DateTimeObject>;
  vacancy?: Maybe<Scalars['Int']>;
  venue?: Maybe<Scalars['String']>;
  registered?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Int']>;
  coaches?: Maybe<Array<Maybe<UserProfile>>>;
  athletes?: Maybe<Array<Maybe<UserProfile>>>;
  coachIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  athleteIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  sportImageUrl?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  trainingDescription?: Maybe<Scalars['String']>;
};

export type TrainingSessionAttendee = {
   __typename?: 'TrainingSessionAttendee';
  trainingSessionId?: Maybe<Scalars['UUID']>;
  trainingSession?: Maybe<TrainingSession>;
  userProfile?: Maybe<UserProfile>;
  userProfileId?: Maybe<Scalars['UUID']>;
  fullName?: Maybe<Scalars['String']>;
  checkInStatus?: Maybe<Scalars['String']>;
  reasonType?: Maybe<Scalars['String']>;
  reasonOther?: Maybe<Scalars['String']>;
};

export type TrainingSessionAttendeeFilter = {
  fromDate?: Maybe<DateTimeObjectInput>;
  toDate?: Maybe<DateTimeObjectInput>;
  filter?: Maybe<Scalars['String']>;
};

export type TrainingSessionAttendeeInput = {
  encodedText?: Maybe<Scalars['String']>;
  userProfileId?: Maybe<Scalars['UUID']>;
  trainingSessionId?: Maybe<Scalars['UUID']>;
  otherReason?: Maybe<Scalars['String']>;
  reasonType?: Maybe<Scalars['String']>;
};

export type TrainingSessionAttendeePagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};

export type TrainingSessionAttendancePagingResponse = {
   __typename?: 'TrainingSessionAttendancePagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<TrainingSessionAttendance>>>;
};
export type UserCourses = {
  id?: Maybe<Scalars['Int']>;
  userAccountId?: Maybe<Scalars['Int']>;
  courseName?:Maybe<Scalars['String']>;
  issuingAuthority?:Maybe<Scalars['String']>;
  courseCompletedDateObject?:Maybe<DateTimeObject>;
  place?:Maybe<Scalars['String']>;
}
export type UserCoursesInput = {
  id?: Maybe<Scalars['Int']>;
  userAccountId?: Maybe<Scalars['Int']>;
  courseName?:Maybe<Scalars['String']>;
  issuingAuthority?:Maybe<Scalars['String']>;
  courseCompletedDateObject?:Maybe<DateTimeObject>;
  place?:Maybe<Scalars['String']>;
}
export type UserCoursesResponse = {
   __typename?: 'UserCoursesResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserCourses>;
};


export type TrainingSessionAttendeeResponse = {
   __typename?: 'TrainingSessionAttendeeResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<TrainingSessionAttendee>;
};

export type TrainingSessionFilter = {
  fromDate?: Maybe<DateTimeObjectInput>;
  toDate?: Maybe<DateTimeObjectInput>;
  fromTime?: Maybe<DateTimeObjectInput>;
  toTime?: Maybe<DateTimeObjectInput>;
  filter?: Maybe<Scalars['String']>;
  isAttended?: Maybe<Scalars['Boolean']>;
};
export type CsvDownloadResponse ={
status?:Maybe<Scalars['String']>;
code?: Maybe<Scalars['String']>;
message?: Maybe<Scalars['String']>;
object?: Maybe<Scalars['String']>;
}

export type TrainingSessionInput = {
  id?: Maybe<Scalars['UUID']>;
  trainingId?: Maybe<Scalars['UUID']>;
  sessionNumber?: Maybe<Scalars['Int']>;
  dateTrainingObj?: Maybe<DateTimeObjectInput>;
  fromTimeObj?: Maybe<DateTimeObjectInput>;
  toTimeObj?: Maybe<DateTimeObjectInput>;
  vacancy?: Maybe<Scalars['Int']>;
  registered?: Maybe<Scalars['Int']>;
  available?: Maybe<Scalars['Int']>;
  venue?: Maybe<Scalars['String']>;
  coachIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  athleteIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  postalCode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type UserRecordsInput = {
id: Maybe<Scalars['UUID']>;
userAccountId: Maybe<Scalars['UUID']>;
eventName: Maybe<Scalars['String']>;
eventDate: Maybe<Scalars['String']>;
countryObject: Maybe<CountryInput>;
numberOfClassification: Maybe<Scalars['String']>;
approved: Maybe<Scalars['Boolean']>;
}
export type TrainingSessionAttendeeInputAdmin ={
encodedText?: Maybe<Scalars['String']>;
userProfileId?: Maybe<Scalars['UUID']>;
trainingSessionId?: Maybe<Scalars['UUID']>;
otherReason?: Maybe<Scalars['String']>;
reasonType?: Maybe<Scalars['String']>;
checkInStatus?: Maybe<Scalars['String']>;
}
export type CountryInput = {
id: Maybe<Scalars['UUID']>;
name: Maybe<Scalars['String']>;
code: Maybe<Scalars['String']>;
displaySequence: Maybe<Scalars['Int']>;
}
export type TrainingSessionPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};
export type LogPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>
  systemLogFilters?: Maybe<Array<Maybe<SystemLogFiltersInput>>>;
}

export type SystemLogFiltersInput= {
  usernames?:Maybe<Array<Maybe<Scalars['String']>>>;
  emails?:Maybe<Array<Maybe<Scalars['String']>>>;
  from?:Maybe<DateTimeObject>;
  to?:Maybe<DateTimeObject>;
}
export type SystemLogs = {
  dateTimeObj?: Maybe<DateTimeObject>;
  nameOfUser?:Maybe<Scalars['String']>;
  email?:Maybe<Scalars['String']>;
  userType?:Maybe<Scalars['String']>;
  component?:Maybe<Scalars['String']>;
  action?:Maybe<Scalars['String']>;
  description?:Maybe<Scalars['String']>;
  origin?:Maybe<Scalars['String']>;
  ipAddress?:Maybe<Scalars['String']>;
}

export type LogPagingResponse = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<SystemLogs>>>;

}


export type TrainingSessionPagingResponse = {
   __typename?: 'TrainingSessionPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<TrainingSession>>>;
};

export type TrainingSessionResponse = {
   __typename?: 'TrainingSessionResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<TrainingSession>;
};

export type UserAccount = {
   __typename?: 'UserAccount';
  id?: Maybe<Scalars['UUID']>;
  username?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

export type UserAccountInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  roleCode?: Maybe<Scalars['String']>;
  userTypeCode?: Maybe<Scalars['String']>;
  impairmentId?: Maybe<Scalars['UUID']>;
  recommendedSportIdList?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  classificationIdList?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export type UserAccountResponse = {
   __typename?: 'UserAccountResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserAccount>;
};

export type UserContact = {
   __typename?: 'UserContact';
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  homeNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  athleteProfileIdList?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  athleteProfileList?: Maybe<Array<Maybe<UserProfile>>>;
};

export type ImportResponse =  {
  __typename?: 'ImportResponse';
  status?:Maybe<Scalars['String']>;
  code?:Maybe<Scalars['String']>;
  message?:Maybe<Scalars['String']>;
  object?:Maybe<Scalars['String']>;
}
export type UserContactInput = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  homeNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  athleteProfileIdList?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export type UserContactResponse = {
   __typename?: 'UserContactResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserContact>;
};
export type ChartData = {
  __typename?: 'ChartData';
  questionCode?: Maybe<Scalars['String']>;
  questionText?: Maybe<Scalars['String']>;
  totalNumberOfResponse?: Maybe<Scalars['Int']>;
  feedBackStatistics?: Maybe<Array<Maybe<FeedBackStatistics>>>
};
export type Assessment ={
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
  };

export type FeedBackStatistics = {
  isNumber?:Maybe<Scalars['Boolean']>;
  numberValue?: Maybe<Scalars['Int']>;
  textValue?: Maybe<Scalars['String']>
  count?: Maybe<Scalars['Int']>;
};

export type UserDailyAid = {
   __typename?: 'UserDailyAid';
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  aidType?: Maybe<Scalars['String']>;
  otherContent?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  diameter?: Maybe<Scalars['Float']>;
  batteryType?: Maybe<Scalars['String']>;
};

export type UserDailyAidInput = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  aidType?: Maybe<Scalars['String']>;
  otherContent?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  diameter?: Maybe<Scalars['Float']>;
  batteryType?: Maybe<Scalars['String']>;
};

export type UserDailyAidResponse = {
   __typename?: 'UserDailyAidResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserDailyAid>;
};

export type UserDocument = {
   __typename?: 'UserDocument';
  id?: Maybe<Scalars['UUID']>;
  documentName?: Maybe<Scalars['String']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  userDocumentFiles?: Maybe<Array<Maybe<UserDocumentFile>>>;
};


export type UserDocumentFile = {
   __typename?: 'UserDocumentFile';
  id?: Maybe<Scalars['UUID']>;
  fileName?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  uploadedDateObj?: Maybe<DateTimeObject>;
};

export type UserDocumentInput = {
  id?: Maybe<Scalars['UUID']>;
  documentName?: Maybe<Scalars['String']>;
  userAccountId?: Maybe<Scalars['UUID']>;
};

export type UserDocumentResponse = {
   __typename?: 'UserDocumentResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserDocument>;
};

export type UserInformation = {
   __typename?: 'UserInformation';
  tierName?: Maybe<Scalars['String']>;
  tierScoreRate?: Maybe<Scalars['Float']>;
  numberToNextTier?: Maybe<Scalars['Int']>;
  barcodeImageUrl?: Maybe<Scalars['String']>;
  photoProfileUrl?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
  recommendedSportList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserInformationInput = {
  fullName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactNumber?: Maybe<Scalars['String']>;
};
export type UserPointResponse = {
  status: Maybe<Scalars['String']>;
code: Maybe<Scalars['String']>;
message: Maybe<Scalars['String']>;
object: Maybe<UserPoint>;
}
export type UserKin = {
   __typename?: 'UserKin';
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  relationship?: Maybe<Scalars['String']>;
  relationshipOther?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isSameAddressContact?: Maybe<Scalars['Boolean']>;
  isGuardian?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  homeNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  bloodType?: Maybe<Scalars['String']>;
  nric?: Maybe<Scalars['String']>;
};

export type UserKinInput = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  relationship?: Maybe<Scalars['String']>;
  relationshipOther?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isSameAddressContact?: Maybe<Scalars['Boolean']>;
  isGuardian?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  homeNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  bloodType?: Maybe<Scalars['String']>;
  nric?: Maybe<Scalars['String']>;
};

export type UserKinResponse = {
   __typename?: 'UserKinResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserKin>;
};
export type UserResultPagingResponse = {
  __typename?: 'UserResultPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
   data?:Maybe<Array<Maybe<UserResults>>>;
}
export type UserResults ={
  __typename?: 'UserResults';
id?: Maybe<Scalars['UUID']>;
competitionName?: Maybe<Scalars['String']>;
CompetitionCategory?: Maybe<Scalars['String']>;
competitionLevel?: Maybe<Scalars['String']>;
noOfParticipants?: Maybe<Scalars['String']>;
competitionType?: Maybe<Scalars['String']>;
countryCompeting?: Maybe<Scalars['String']>;
assessmentId?: Maybe<Scalars['UUID']>;
competitionDateValue?: Maybe<Scalars['String']>;
sport?: Maybe<Sport>;
userProfileId?: Maybe<Scalars['UUID']>;
event?: Maybe<Scalars['String']>;
positionNumber?: Maybe<Scalars['String']>;
result?: Maybe<Scalars['String']>;
status?: Maybe<Scalars['String']>;
statusDescription?: Maybe<Scalars['String']>;
plainStatus?: Maybe<Scalars['String']>
}
export type UserMedical = {
   __typename?: 'UserMedical';
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  bloodType?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  dietaryPreference?: Maybe<Scalars['String']>;
  foodAllergy?: Maybe<Scalars['String']>;
  medicalMemo?: Maybe<Scalars['String']>;
  medicalCondition?: Maybe<Scalars['String']>;
};

export type UserMedicalCondition = {
   __typename?: 'UserMedicalCondition';
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  classificationId?: Maybe<Scalars['UUID']>;
  /** @deprecated Field no longer supported */
  medicalCondition?: Maybe<Scalars['String']>;
  medicalMemo?: Maybe<Scalars['String']>;
};

export type UserMedicalConditionInput = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  classificationId?: Maybe<Scalars['UUID']>;
  medicalCondition?: Maybe<Scalars['String']>;
  medicalMemo?: Maybe<Scalars['String']>;
};

export type UserMedicalConditionResponse = {
   __typename?: 'UserMedicalConditionResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserMedicalCondition>;
};

export type UserMedicalInput = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  bloodType?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  dietaryPreference?: Maybe<Scalars['String']>;
  foodAllergy?: Maybe<Scalars['String']>;
  medicalMemo?: Maybe<Scalars['String']>;
  medicalCondition?: Maybe<Scalars['String']>;
};
export type UserPointInput = {
id?:  Maybe<Scalars['UUID']>;
userProfileId?:Maybe<Scalars['UUID']>;
eventFeedbackId?:Maybe<Scalars['UUID']>;
surveyId?:Maybe<Scalars['UUID']>;
type?: Maybe<Scalars['String']>;
receivedDateObj?:Maybe<DateTimeObjectInput>
points?: Maybe<Scalars['Int']>;
}
export type UserMedicalResponse = {
   __typename?: 'UserMedicalResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserMedical>;
};

export type UserOrganisation = {
   __typename?: 'UserOrganisation';
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  occupation?: Maybe<Scalars['String']>;
  organisationName?: Maybe<Scalars['String']>;
  organisationAddress?: Maybe<Scalars['String']>;
  personInCharge?: Maybe<Scalars['String']>;
  designationPersonInCharge?: Maybe<Scalars['String']>;
};

export type UserOrganisationInput = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  occupation?: Maybe<Scalars['String']>;
  organisationName?: Maybe<Scalars['String']>;
  organisationAddress?: Maybe<Scalars['String']>;
  personInCharge?: Maybe<Scalars['String']>;
  designationPersonInCharge?: Maybe<Scalars['String']>;
};

export type UserOrganisationResponse = {
   __typename?: 'UserOrganisationResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserOrganisation>;
};
export type UserCourse = {
  __typename?: 'UserCourse';
  name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
};

export type UserOther = {
   __typename?: 'UserOther';
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  topSize?: Maybe<Scalars['String']>;
  bottomSize?: Maybe<Scalars['String']>;
  shoeSize?: Maybe<Scalars['BigDecimal']>;
  //tshirtSize?: Maybe<Scalars['String']>;
};

export type UserOtherInput = {
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  topSize?: Maybe<Scalars['String']>;
  bottomSize?: Maybe<Scalars['String']>;
  shoeSize?: Maybe<Scalars['BigDecimal']>;
  //tshirtSize?: Maybe<Scalars['String']>;
};

export type UserOtherResponse = {
   __typename?: 'UserOtherResponse';
  code?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserOther>;
};

export type UserPagingRequest = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  sortColumn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortDir?: Maybe<Array<Maybe<Scalars['Int']>>>;
  filter?: Maybe<Scalars['String']>;
};

export type UserPagingResponse = {
   __typename?: 'UserPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<UserProfile>>>;
};

export type UserDTOPagingResponse={
  _typename?: 'UserDTOPagingResponse';
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  pageIndex?: Maybe<Scalars['Int']>;
  totalPage?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<UserGroupDto>>>;
}

export type UserGroupDto={
  _typename?: 'UserGroupDto';
  name?: Maybe<Scalars['String']>;
  email?:  Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['UUID']>;
  groupID?: Maybe<Scalars['UUID']>;
  checked?: Maybe<Scalars['Boolean']>;
}

export type UserProfile = {
   __typename?: 'UserProfile';
  id?: Maybe<Scalars['UUID']>;
  userAccountId?: Maybe<Scalars['UUID']>;
  fullName?: Maybe<Scalars['String']>;
  chineseName?: Maybe<Scalars['String']>;
  legalGuardian?: Maybe<Scalars['String']>;
  isMale?: Maybe<Scalars['Boolean']>;
  nric?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sports?: Maybe<Array<Maybe<Sport>>>;
  sportIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  dateOfBirthObj?: Maybe<DateTimeObject>;
  placeOfBirth?: Maybe<Country>;
  placeOfBirthId?: Maybe<Scalars['UUID']>;
  passport?: Maybe<Scalars['String']>;
  dateOfIssueObj?: Maybe<DateTimeObject>;
  dateOfExpiryObj?: Maybe<DateTimeObject>;
  nationalityId?: Maybe<Scalars['UUID']>;
  nationality?: Maybe<Nationality>;
  raceId?: Maybe<Scalars['UUID']>;
  race?: Maybe<Race>;
  religionId?: Maybe<Scalars['UUID']>;
  religion?: Maybe<Religion>;
  maritalStatus?: Maybe<Scalars['String']>;
  isNationalService?: Maybe<Scalars['Boolean']>;
  dateNsEnlistmentObj?: Maybe<DateTimeObject>;
  dateNsCompletionObj?: Maybe<DateTimeObject>;
  isNrocMembership?: Maybe<Scalars['Boolean']>;
  photoProfileUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  lastModifiedDateObj?: Maybe<DateTimeObject>;
  lastModifiedBy?: Maybe<Scalars['String']>;
  roleCode?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  userTypeCode?: Maybe<Scalars['String']>;
  userType?: Maybe<Role>;
  checkInStatus?: Maybe<Scalars['String']>;
  barcodeImageUrl?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  classifier?:Maybe<Scalars['String']>;
  accountCreatedDate?: Maybe<DateTimeObject>;
};
export type UserMedicalConditionInputAdmin = {
  _typename?: 'UserMedicalConditionInputAdmin';
id?: Maybe<Scalars['UUID']>;
userAccountId?: Maybe<Scalars['UUID']>;
classificationId?: Maybe<Scalars['UUID']>;
medicalCondition?: Maybe<Scalars['String']>;
medicalMemo?: Maybe<Scalars['String']>;
action?: Maybe<ActionType>;
}

export type UserAccessDetails = {
  __typename?: 'UserAccessDetails';
  id?: Maybe<Scalars['UUID']>;
  fullName?: Maybe<Scalars['String']>;
 accessDetails?: Maybe<Array<Maybe<AccessDetailslist>>>;
};
export type AccessDetailslist = {
  __typename?: 'UserAccessDetails';
  id?: Maybe<Scalars['UUID']>;
  groupName?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  modulesList?: Maybe<Array<Maybe<data>>>;
  SportList?: Maybe<Array<Maybe<data>>>;
};

export type UserProfileInput = {
  userAccountId?: Maybe<Scalars['UUID']>;
  fullName?: Maybe<Scalars['String']>;
  chineseName?: Maybe<Scalars['String']>;
  isMale?: Maybe<Scalars['Boolean']>;
  nric?: Maybe<Scalars['String']>;
  sportIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  dateOfBirthObj?: Maybe<DateTimeObjectInput>;
  placeOfBirthId?: Maybe<Scalars['UUID']>;
  passport?: Maybe<Scalars['String']>;
  dateOfIssueObj?: Maybe<DateTimeObjectInput>;
  dateOfExpiryObj?: Maybe<DateTimeObjectInput>;
  nationalityId?: Maybe<Scalars['UUID']>;
  raceId?: Maybe<Scalars['UUID']>;
  religionId?: Maybe<Scalars['UUID']>;
  maritalStatus?: Maybe<Scalars['String']>;
  isNationalService?: Maybe<Scalars['Boolean']>;
  dateNsEnlistmentObj?: Maybe<DateTimeObjectInput>;
  dateNsCompletionObj?: Maybe<DateTimeObjectInput>;
  isNrocMembership?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  roleCode?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  classifier?:Maybe<Scalars['String']>;
};

export type UserProfileResponse = {
   __typename?: 'UserProfileResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  object?: Maybe<UserProfile>;
};

export type UserRecommendedSport = {
   __typename?: 'UserRecommendedSport';
  imageUrl?: Maybe<Scalars['String']>;
  sportId?: Maybe<Scalars['UUID']>;
  sportName?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  isRecommended?: Maybe<Scalars['Boolean']>;
  isInterested?: Maybe<Scalars['Boolean']>;
  isCompetitive?: Maybe<Scalars['Boolean']>;
  sportSdscCarding?: Maybe<Scalars['String']>;
  sportGroup?: Maybe<Scalars['String']>;
  sportSpexCarding?: Maybe<Scalars['String']>;
  sportYear?: Maybe<Scalars['String']>;
  sportClassification?: Maybe<Scalars['String']>;
};

export type UserRecommendedSportInput = {
  sportId?: Maybe<Scalars['UUID']>;
  isRecommended?: Maybe<Scalars['Boolean']>;
  isInterested?: Maybe<Scalars['Boolean']>;
  isCompetitive?: Maybe<Scalars['Boolean']>;
  sportSdscCarding?: Maybe<Scalars['String']>;
  sportGroup?: Maybe<Scalars['String']>;
  sportSpexCarding?: Maybe<Scalars['String']>;
  sportYear?: Maybe<Scalars['String']>;
  sportClassification?: Maybe<Scalars['String']>;
};

export type UserRecommendedSportsInput = {
  userRecommendedSports?: Maybe<Array<Maybe<UserRecommendedSportInput>>>;
};

export type UserSportLicense = {
   __typename?: 'UserSportLicense';
  id?: Maybe<Scalars['UUID']>;
  imageUrl?: Maybe<Scalars['String']>;
  sportId?: Maybe<Scalars['UUID']>;
  sport?: Maybe<Sport>;
  sportName?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  expiryDateObj?: Maybe<DateTimeObject>;
};

export type UserSportLicenseInput = {
  id?: Maybe<Scalars['UUID']>;
  sportId?: Maybe<Scalars['UUID']>;
  level?: Maybe<Scalars['String']>;
  expiryDateObj?: Maybe<DateTimeObjectInput>;
};

export type UserSportLicensesInput = {
  userSportLicenses?: Maybe<Array<Maybe<UserSportLicenseInput>>>;
};

export type UserSportLicensesResponse = {
   __typename?: 'UserSportLicensesResponse';
  status?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};


export type MutationChangePasswordMutationVariables = {
  newPassword?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};


export type MutationChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword?: Maybe<(
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'code' | 'status' | 'message'>
  )> }
);

export type MutationCheckTokenMutationVariables = {
  token?: Maybe<Scalars['String']>;
};


export type MutationCheckTokenMutation = (
  { __typename?: 'Mutation' }
  & { checkToken?: Maybe<(
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'code' | 'status' | 'message'>
  )> }
);
export type QueryGetCurrentUserProfileQueryVariables = {};

export type QueryGetAccessDetailsByLoggedInUserQueryVariables = {};
export type QueryGetCurrentUserProfileQuery = (
  { __typename?: 'Query' }
  & { getCurrentUserProfile?: Maybe<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'userAccountId' | 'fullName'>
  )> }
);
export type QueryGetAccessDetailsByLoggedInUserQuery = (
  { __typename?: 'Query' }
  & { getAccessDetailsByLoggedInUser?: Maybe<(
    { __typename?: 'UserAccessDetails' }
    & Pick<UserAccessDetails, 'id' | 'accessDetails' | 'fullName'>
  )> }
);

export type MutationLoginAdminMutationVariables = {
  userAccount?: Maybe<UserAccountInput>;
};


export type MutationLoginAdminMutation = (
  { __typename?: 'Mutation' }
  & { loginAdmin?: Maybe<(
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'token' | 'code' | 'status' | 'message'>
  )> }
);

export type MutationRequestChangPasswordMutationVariables = {
  emailAddress?: Maybe<Scalars['String']>;
};


export type MutationRequestChangPasswordMutation = (
  { __typename?: 'Mutation' }
  & { requestChangePassword?: Maybe<(
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'code' | 'status' | 'message'>
  )> }
);

export type QueryGetListAidTypeQueryVariables = {};


export type QueryGetListAidTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListAidType'>
);

export type QueryGetListBloodTypeQueryVariables = {};


export type QueryGetListBloodTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListBloodType'>
);

export type QueryGetListClassificationQueryVariables = {};
export type QueryGetSportsListForAdminQueryVariables = {
  query?: Maybe<Scalars['String']>;
};
export type QueryGetModulesListForAdminQueryVariables = {

};
export type QueryGetListClassificationQuery = (
  { __typename?: 'Query' }
  & { getListClassification?: Maybe<Array<Maybe<(
    { __typename?: 'Classification' }
    & Pick<Classification, 'id' | 'impairmentId' | 'name' | 'code'>
    & { impairment?: Maybe<(
      { __typename?: 'Impairment' }
      & Pick<Impairment, 'name'>
    )> }
  )>>> }
);


export type QueryGetSportsListForAdminQuery = (
  { __typename?: 'Query' }
  & { getSportsListForAdmin?: Maybe<Array<Maybe<(
    { __typename?: 'SportList' }
    & Pick<SportList, 'id' | 'status' | 'name' | 'code'>

  )>>> }
);

export type QueryGetModulesListForAdminQuery = (
  { __typename?: 'Query' }
  & { getModulesListForAdmin?: Maybe<Array<Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'id' |'name' >

  )>>> }
);
export type QueryGetListClothSizeQueryVariables = {};


export type QueryGetListClothSizeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListClothSize'>
);

export type QueryDownloadEventFeedbackFileVariables = {
  eventId?: Maybe<Scalars['UUID']>;
  timeOffset?: Maybe<Scalars['String']>;
};

export type QueryDownloadEventAttendanceFileVariables ={
  eventId?: Maybe<Scalars['UUID']>;
}
export type QueryDownloadEventFeedbackFileQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadEventFeedbackFile'>
);
export type QueryDownloadUserTrainingAttendanceUrlQueryVariables = {
  trainingSessionId?: Maybe<Scalars['UUID']>;
  userProfileId?: Maybe<Scalars['UUID']>;
};

export type QueryDownloadUserTrainingAttendanceUrlQuery = (
  { __typename?: 'Query' }
  & { downloadUserTrainingAttendanceUrl?: Maybe<(
    { __typename?: 'CsvDownloadResponse' }
  & Pick<CsvDownloadResponse , 'code'| 'status' | 'message' |'object'>
  )>
  }
);
export type QueryDownloadTrainingAttendanceOfAthleteUrlQuery = (
  { __typename?: 'Query' }
  & { downloadTrainingAttendanceOfAthlete?: Maybe<(
    { __typename?: 'CsvDownloadResponse' }
  & Pick<CsvDownloadResponse , 'code'| 'status' | 'message' |'object'>
  )>
  }
);
export type QueryDownloadTrainingAttendanceOfAthleteUrlQueryVariables={
  trainingId?: Maybe<Scalars['UUID']>;
  trainingName?: Maybe<Scalars['String']>;
  trainingSessionId?: Maybe<Scalars['UUID']>;
  userProfileId?: Maybe<Scalars['UUID']>;
}

export type QueryDownloadSystemLogExcelReportQuery =(

  { __typename?: 'Query' }
  & {downloadSystemLogExcelReport ? :Maybe<(
    { __typename?: 'DownloadCsvResponseContent' }
    & Pick<DownloadCsvResponseContent , 'code'| 'status' | 'message' |'object'>
  )>}
);

export type QueryDownloadSystemLogExcelReportQueryVariables={
  pagingRequest?: Maybe<LogPagingRequest>;
  SystemLogFiltersInput?:Maybe<SystemLogFiltersInput>;
}

export type QueryDownloadEventAttendanceFileQuery = (
 { __typename?: 'Query' }
  & Pick<Query, 'downloadEventAttendanceFile'>
);
export type QueryDownloadAllSessionAttendanceUrlVariables ={
  filter?:Maybe<TrainingSessionFilter>
  trainingId?: Maybe<Scalars['UUID']>;
  trainingName?: Maybe<Scalars['String']>;

}
export type QueryDownloadAllSessionAttendanceUrleQuery =(
  { __typename?: 'Query' }
  & { downloadAllSessionAttendanceUrl?: Maybe<(
    { __typename?: 'CsvDownloadResponse' }
    & Pick<CsvDownloadResponse, 'status' | 'code' | 'message' | 'object'>
)>
  }
);

export type QueryDownloadAllTrainingAttendanceOfAthleteUrlQuery=(
  { __typename?: 'Query' }
  & {downloadAllTrainingAttendanceOfAthlete?: Maybe<(
    { __typename?: 'CsvDownloadResponse' }
    & Pick<CsvDownloadResponse, 'status' | 'code' | 'message' | 'object'>
  )>}
);

export type QueryDownloadAllTrainingAttendanceOfAthleteUrlQueryVariables={
  filter?:Maybe<TrainingSessionFilter>
  fullName?:Maybe<Scalars['String']>;
  userProfileId?: Maybe<Scalars['UUID']>;
}
export type QueryDownloadUserRecordCsvQuery = (
  { __typename?: 'Query' }
  & { downloadUserRecordCsv?: Maybe<(
    {_typename?: 'DownloadCsvResponseContent'}
    & Pick<DownloadCsvResponseContent,'code' | 'status' |'message' | 'object'>
  )>}
);
export type QueryDownloadUserResultsCsvVariables ={
  userProfileId?: Maybe<Scalars['UUID']>;
}
export type QueryDownloadUserResultsCsvQuery =(
  { __typename?: 'Query' }
  & { downloadUserResultsCsv?: Maybe<(
    {_typename?: 'DownloadCsvResponseContent'}
    & Pick<DownloadCsvResponseContent,'code' | 'status' |'message' | 'object'>
  )>}
)
export type QueryDownloadUserRecordCsvVariables={
  userId?: Maybe<Scalars['UUID']>;
}
export type QueryDownloadRedemptionListVariables={
rewardName?:Maybe<Scalars['String']>;
rewardsId?: Maybe<Scalars['UUID']>;
}
export type QueryDownloadRedemptionListQuery=(
  { __typename?: 'Query' }
  & { downloadRedemptionList?: Maybe<(
    {_typename?: 'RedemptionDownloadResponse'}
    & Pick<RedemptionDownloadResponse,'code' | 'status' |'message' | 'object'>
  )>}
);
export type QueryGetListCountryQueryVariables = {};


export type QueryGetListCountryQuery = (
  { __typename?: 'Query' }
  & { getListCountry?: Maybe<Array<Maybe<(
    { __typename?: 'Country' }
    & Pick<Country, 'id' | 'name'>
  )>>> }
);

export type QueryGetListImpairmentQueryVariables = {};


export type QueryGetListImpairmentQuery = (
  { __typename?: 'Query' }
  & { getListImpairment?: Maybe<Array<Maybe<(
    { __typename?: 'Impairment' }
    & Pick<Impairment, 'id' | 'name' | 'code'>
  )>>> }
);

export type QueryGetListMaritalStatusQueryVariables = {};


export type QueryGetListMaritalStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListMaritalStatus'>
);

export type QueryGetListNationalityQueryVariables = {};


export type QueryGetListNationalityQuery = (
  { __typename?: 'Query' }
  & { getListNationality?: Maybe<Array<Maybe<(
    { __typename?: 'Nationality' }
    & Pick<Nationality, 'id' | 'name'>
  )>>> }
);

export type QueryGetListPostTypeQueryVariables = {};


export type QueryGetListPostTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListPostType'>
);

export type QueryGetListRaceQueryVariables = {};


export type QueryGetListRaceQuery = (
  { __typename?: 'Query' }
  & { getListRace?: Maybe<Array<Maybe<(
    { __typename?: 'Race' }
    & Pick<Race, 'id' | 'name'>
  )>>> }
);

export type QueryGetListRelationshipQueryVariables = {};


export type QueryGetListRelationshipQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListRelationship'>
);

export type QueryGetListReasonTypeQueryVariables = {};


export type QueryGetGetListReasonTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListReasonType'>
);

export type QueryGetListReligionQueryVariables = {};


export type QueryGetListReligionQuery = (
  { __typename?: 'Query' }
  & { getListReligion?: Maybe<Array<Maybe<(
    { __typename?: 'Religion' }
    & Pick<Religion, 'id' | 'name'>
  )>>> }
);

export type QueryGetListShoeSizeQueryVariables = {};


export type QueryGetListShoeSizeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListShoeSize'>
);
export type QueryGetPendingListGQLAdminQuery = (
{ __typename?: 'Query' }
&  { getPendingActionList?: Maybe<(
  { __typename?: 'PendingActionPagingResponse' }
  & Pick <PendingActionPagingResponse, 'offset' | 'limit' | 'pageIndex' | 'totalPage' | 'total'>
  & { data?: Maybe<Array<Maybe<(
    { __typename?: 'PendingActionDTO'}
    & Pick<PendingActionDTO, 'recordsResultsId'| 'status' | 'description'| 'accountId' | 'emailAddress'
    | 'profileId' | 'fullName' | 'redeemedId' | 'rewardId'>
    )>>>,submittedDate?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>
    }
)>
  });

export type QueryGetListUserStatusQueryVariables = {};

export type QueryGetPendingListGQLAdminQueryVariables = {
  pagingRequest?: Maybe<DashboardPagingRequest>;
  sportList?: Maybe<Scalars['UUID']>;
}
export type QueryGetUserRecordDetailsQuery=(
  { __typename?: 'Query' }
  & { getUserRecordDetails ? :Maybe<(
    { __typename?: 'DashboardUserRecordResponse' }
    &Pick <DashboardUserRecordResponse, 'code'|'status'|'message'>
    &{object?:Maybe<({
      _typename?:'UserRecordResponse'
    }&Pick<UserRecordResponse,'id'|'eventName'|'eventDate'|'country'| 'status'|'statusDescription'|'emailAddress'
    | 'fullName'>)>}
  )>});
export type QueryGetUserRecordDetailsQueryVariables = {
  recordId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserRewardDetailsQueryVariables = {
  redeemedId?:Maybe<Scalars['UUID']>;
}


export type QueryGetUserRewardDetailsQuery=(
  { __typename?: 'Query' }
  & { getUserRewardRedeemedDetails ? :Maybe<(
    { __typename?: 'DashboardUserRewardResponse' }
    &Pick <DashboardUserRewardResponse, 'code'|'status'|'message'>
    &{object?:Maybe<({
      _typename?:'UserRewardResponse'
    }&Pick<UserRewardResponse,'redeemedId'|'fullName'|'emailAddress'|'rewardName'|'rewardStatus'|'remarks'|'redeemedDate'|'redemptionOptionMethod'>)>}
  )>});

export type QueryGetUserResultDetailsQuery =(
  { __typename?: 'Query' }
  &{getUserResultDetails ? :Maybe<(
    { __typename?: 'DashboardUserResultResponse'}
    &Pick <DashboardUserResultResponse, 'code'|'status'|'message'>
    &{object ?:Maybe<({
      _typename?: ' UserResultResponse'
    } &Pick < UserResultResponse, 'resultId'| 'competitionName'|'competitionDate' | 'sportName'
    | 'eventName'|'competitionCategory'|'competitionLevel'|'competitionType'|'countryCompeting'
    |'noOfParticipants'| 'positionNumber'|'assessment'|'result'|'status'|
    'statusDescription'|'emailAddress'|'fullName'>)>}
  )>}
)

export type QueryGetUserResultDetailsQueryVariables = {
  resultId?: Maybe<Scalars['UUID']>;
};

export type QueryGetListUserStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListUserStatus'>
);

export type QueryGetListEventTrainingForAdminQueryVariables = {
  pagingRequest?: Maybe<DashboardPagingRequest>;
  filter?: Maybe<DashboardFilter>;
};


export type QueryGetListEventTrainingForAdminQuery = (
  { __typename?: 'Query' }
  & { getListEventTrainingForAdmin?: Maybe<(
    { __typename?: 'DashboardPagingResponse' }
    & Pick<DashboardPagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'EventTraining' }
      & Pick<EventTraining, 'id' | 'eventName' | 'type' | 'venue' | 'imageUrl'>
      & { eventDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>, fromTimeObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>, toTimeObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )> }
    )>>> }
  )> }
);
export type QueryGetListUserPointVariables = {
  userProfileId?: Maybe<Scalars['UUID']>;
}

export type QueryGetListUserPointQuery = (
  { __typename?: 'Query' }
  & { getListUserPointAdmin?: Maybe<(
    { __typename?: 'UserPointListResponse' }
    & Pick<UserPointListResponse, 'status' | 'code' | 'message'>
    & { object?: Maybe<Array<Maybe<(
      { __typename?: 'UserPoint' }
      & Pick<UserPoint, 'id' | 'userProfileId' | 'eventFeedbackId' | 'surveyId' | 'type' | 'points'>
    )>>>, receivedDateObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
    )> }
  )>}
);
export type QueryGetListEventPointsVariables={}

export type QueryGetListEventPointsQuery =(
  { __typename?: 'Query' }
  & { getListEventPoints?: Maybe<Array<Maybe<(
    & Pick<EventPointsMap,'eventType'|'roleTypeList'>
  )>>>,roleTypeList?: Maybe<Array<Maybe<(
    { __typename?: 'RoleTypeList' }
    & Pick<RoleTypeList,'eventType'| 'roleType'|'points'>
  )>>>}
);
export type QueryGetTotalAthleteQueryVariables = {
  status?: Maybe<Scalars['String']>;
};


export type QueryGetTotalUsersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTotalUsers'>
);
export type QueryGetTotalUsersQueryVariables = {
  status?: Maybe<Scalars['String']>;
};
export type QueryGetTotalAthleteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTotalAthlete'>
);
export type QueryGetTotalEventQueryVariables = {
  filter?: Maybe<DashboardFilter>;
};


export type QueryGetTotalEventQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTotalEvent'>
);

export type QueryGetTotalTrainingQueryVariables = {
  filter?: Maybe<DashboardFilter>;
};


export type QueryGetTotalTrainingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTotalTraining'>
);

export type QueryGetEventForAdminQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetEventForAdminQuery = (
  { __typename?: 'Query' }
  & { getEventForAdmin?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'eventName' | 'eventImageUrl' | 'eventType' | 'description' | 'status' | 'venue' | 'totalVacancy' | 'available' | 'registered' | 'organiser' | 'isPublished' | 'cancelReason' | 'emailNotification'|'externalLink'>
    & { eventDateObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'millisecond'| 'timeZone'>
    )>, fromTimeObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'millisecond'| 'timeZone'>
    )>, toTimeObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
       & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'millisecond' |'timeZone'>
    )>, fromRegDate?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'timeZone'>
    )>,toRegDate?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'timeZone'>
    )>,additionalInfoList?: Maybe<Array<Maybe<(
      { __typename?: 'EventAdditionalInfo' }
      & Pick<EventAdditionalInfo, 'id' | 'question' | 'questionType' | 'optionValue'>
    )>>>,eventRoles?: Maybe<Array<Maybe<(
      { __typename?: 'EventRole'}
      & Pick<EventRole, 'id' | 'roleCode' | 'roleName'>
    )>>>}
  )> }
);

export type QueryGetListEventAttendeeForAdminQueryVariables = {
  eventId?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<EventAttendeePagingRequest>;
};


export type QueryGetListEventAttendeeForAdminQuery = (
  { __typename?: 'Query' }
  & { getListEventAttendeeForAdmin?: Maybe<(
    { __typename?: 'EventAttendeePagingResponse' }
    & Pick<EventAttendeePagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'EventAttendee' }
      & Pick<EventAttendee, 'id' | 'urlProfileImage' | 'registrationRole'|'phoneNumber'|'fullName'|'email' |'emergencyContactName'|'emergencyContactNumber'|'userProfileId'>
      & { userProfile?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'fullName' | 'nric' | 'userAccountId'>
      )>,event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'isFeedbackCompleted'>
        & { additionalInfoList?: Maybe<Array<Maybe<(
          { __typename?: 'EventAdditionalInfo' }
          & Pick<EventAdditionalInfo, 'question'|'id'>
          & { additionalInfoList?: Maybe<Array<Maybe<(
            { __typename?: 'EventAdditionalAnswer' }
            & Pick<EventAdditionalAnswer, 'questionId'|'answer'>

      )>
     >>}
    )>>> }
  )>}
)>>>}
  )>}
);

export type QueryGetListEventForAdminQueryVariables = {
  pagingRequest?: Maybe<EventPagingRequest>;
  filter?: Maybe<EventFilter>;
  sportList?:Maybe<Array<Maybe<['UUID']>>>;
};


export type QueryGetListEventForAdminQuery = (
  { __typename?: 'Query' }
  & { getListEventForAdmin?: Maybe<(
    { __typename?: 'EventPagingResponse' }
    & Pick<EventPagingResponse, 'total' | 'offset' | 'limit'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'eventName' | 'eventImageUrl' | 'eventType' | 'description' | 'status' | 'venue' | 'totalVacancy' | 'available' | 'registered' | 'emailNotification'|'externalLink'>
      & { eventDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'timeZone'>
      )>, fromTimeObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'millisecond' | 'timeZone'>
      )>, toTimeObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'millisecond' |'timeZone'>
      )>,fromRegDate?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'millisecond' |'timeZone'>
      )>,toRegDate?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'millisecond' |'timeZone'>
      )>
     }
    )>>> }
  )> }
);

export type QueryGetListEventStatusQueryVariables = {};


export type QueryGetListEventStatusQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListEventStatus'>
);

export type QueryGetListEventTypeQueryVariables = {};


export type QueryGetListEventTypeQuery = (
  { __typename?: 'Query' }
  & { getListEventType?: Maybe<Array<Maybe<(
    { __typename?: 'EventType' }
    & Pick<EventType, 'groupName' | 'eventTypeName'>
  )>>> }
);

export type MutationCreateEventMutationVariables = {
  event?: Maybe<EventInput>;
};


export type MutationCreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent?: Maybe<(
    { __typename?: 'EventResponse' }
    & Pick<EventResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )>,
    // additionalInfoList?: Maybe<Array<Maybe<(
    //   { _typename?: EventAdditionalInfo }
    //   & Pick<EventAdditionalInfo, 'id'>
    // )>>>,
   }
  )> }
);

export type MutationDeleteEventMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteEvent?: Maybe<(
    { __typename?: 'EventResponse' }
    & Pick<EventResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  )> }
);

export type MutationPublishEventMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationPublishEventMutation = (
  { __typename?: 'Mutation' }
  & { publishEvent?: Maybe<(
    { __typename?: 'EventResponse' }
    & Pick<EventResponse, 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  )> }
);

export type MutationPushEventMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationPushEventMutation = (
  { __typename?: 'Mutation' }
  & { pushEvent?: Maybe<(
    { __typename?: 'EventResponse' }
    & Pick<EventResponse, 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'status'>
    )> }
  )> }
);

export type MutationUnPublishEventMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationUnPublishEventMutation = (
  { __typename?: 'Mutation' }
  & { unPublishEvent?: Maybe<(
    { __typename?: 'EventResponse' }
    & Pick<EventResponse, 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  )> }
);

export type MutationUpdateEventMutationVariables = {
  event?: Maybe<EventInput>;
};


export type MutationUpdateEventMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent?: Maybe<(
    { __typename?: 'EventResponse' }
    & Pick<EventResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
      )>,
      // additionalInfoList?: Maybe<Array<Maybe<(
      //   { _typename?: EventAdditionalInfo }
      //   & Pick<EventAdditionalInfo, 'id'>
      // )>>>,
  }
  )> }
);

export type MutationUpdateUserCertificatesMutationVariables = {
  userCertificates?: Maybe<Array<Maybe<UserCertificatesInput>>>;

}


export type MutationUpdateUserCertificates = (
  { __typename?: 'Mutation' }
  & { updateUserCertificates?: Maybe<(
    { __typename?: 'UserCertificatesResponse' }
    & Pick<UserCertificatesResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserCertificates' }
      & Pick<UserCertificates, 'id' | 'userAccountId' | 'certificateName' | 'issuingAuthority' | 'place' >
      )>,certificateDateObject?: Maybe<(
        { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date'>
       )> }
      )> }
    );
    export type MutationUpdateUserCoursesMutationVariables = {
      userCourses?: Maybe<Array<Maybe<UserCoursesInput>>>;
    };

 export type MutationUpdateUserCoursesMutation = (
  { __typename?: 'Mutation' }
  & { updateUserCourses?: Maybe<(
    { __typename?: 'UserCoursesResponse' }
    & Pick<UserCoursesResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserCourses' }
      & Pick<UserCourses, 'id' | 'userAccountId' | 'courseName'  | 'place'>
      )>,courseCompletedDateObject?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date'>
      )>}
      )> }
    );


export type MutationChangeStatusArticleMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
};


export type MutationChangeStatusArticleMutation = (
  { __typename?: 'Mutation' }
  & { changeStatusArticle?: Maybe<(
    { __typename?: 'ArticleResponse' }
    & Pick<ArticleResponse, 'status' | 'code' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id'>
    )> }
  )> }
);

export type MutationCreateArticleMutationVariables = {
  article?: Maybe<ArticleInput>;
};
export type MutationUpdateUserAccessMutationVariables = {
  userAccessData?: Maybe<userAccessInput>;
};

export type MutationCreateUserAccessMutationVariables = {
  userAccessData?: Maybe<userAccessInput>;
};
export type MutationCreateArticleMutation = (
  { __typename?: 'Mutation' }
  & { createArticle?: Maybe<(
    { __typename?: 'ArticleResponse' }
    & Pick<ArticleResponse, 'status' | 'code' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id'>
    )> }
  )> }
);
export type MutationUpdateUserAccessMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAccess?: Maybe<(
    { __typename?: 'UserAccessResponse' }
    & Pick<UserAccessResponse, 'status' | 'code' | 'message'|'object'>

  )> }
);

export type MutationCreateUserAccessMutation = (
  { __typename?: 'Mutation' }
  & { createUserAccess?: Maybe<(
    { __typename?: 'UserAccessResponse' }
    & Pick<UserAccessResponse, 'status' | 'code' | 'message'|'object'>

  )> }
);
export type MutationDeleteArticleMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};
export type MutationDeleteUserAccessMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};

export type MutationDeleteArticleMutation = (
  { __typename?: 'Mutation' }
  & { deleteArticle?: Maybe<(
    { __typename?: 'ArticleResponse' }
    & Pick<ArticleResponse, 'status' | 'code' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id'>
    )> }
  )> }
);

export type MutationDeleteUserAccessMutation = (
  { __typename?: 'Mutation' }
  & { deleteArticle?: Maybe<(
    { __typename?: 'UserAccessResponse' }
    & Pick<UserAccessResponse, 'status' | 'code' | 'message'|'object'>

  )> }
);
export type QueryGetArticleQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetArticleQuery = (
  { __typename?: 'Query' }
  & { getArticle?: Maybe<(
    { __typename?: 'Article' }
    & Pick<Article, 'id' | 'title'>
  )> }
);
export type QueryGetUserCertificatesQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};

export type QueryGetUserCertificatesQuery = (
  { __typename?: 'Query' }
  & { getUserCertificates?: Maybe<Array<Maybe<(
    { __typename?: 'UserCertificates' }
    & Pick<UserCertificates, 'id' | 'userAccountId' | 'certificateName' | 'issuingAuthority' | 'place' >
  )>>>,
  certificateDateObject?: Maybe<(
    { __typename?: 'DateTimeObject' }
  & Pick<DateTimeObject, 'year' | 'month' | 'date'>
  )>}
);

export type QueryGetUserCoursesQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};

export type QueryGetUserCoursesQuery = (
  { __typename?: 'Query' }
  & { getUserCourses?: Maybe<Array<Maybe<(
    { __typename?: 'UserCourses' }
    & Pick<UserCourses, 'id' | 'userAccountId' | 'courseName'  | 'place' | 'issuingAuthority'>
  )>>>
  courseCompletedDateObject?: Maybe<(
    { __typename?: 'DateTimeObject' }
    & Pick<DateTimeObject, 'year' | 'month' | 'date'>
  )>
}
);
export type QueryGetUserRecordsOfUserForAdminQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
  pagingRequest?: ReportsPagingRequest;
};

export type QueryGetUserRecordsOfUserForAdminQuery = (
  { __typename?: 'Query' }
  & { getUserRecordsOfUserForAdmin?: Maybe<(
    { __typename?: 'ReportsPagingResponse' }
    & Pick<ReportsPagingResponse, 'total' | 'offset' | 'limit'>
    & { data?: Maybe<Array<Maybe<(
      & Pick<UserRecords,  'id' | 'userAccountId' | 'eventName' | 'eventDate' | 'numberOfClassification' | 'approved' | 'status'| 'statusDescription' | 'plainStatus'>
      )>>>,countryObject?: Maybe<(
        { __typename?: 'Country' }
       & Pick<Country, 'id' | 'name'| 'code'| 'displaySequence'>
       )>
     }
      )>}
   );

   export type MutationUpdateUserRecordsForAdminMutationVariables = {
   userRecords?: Maybe<UserRecordsInput>;
   }
   export type MutationUpdateUserRecordsForAdminMutation = (
    { __typename?: 'Mutation' }
    & { updateUserRecordsForAdmin?: Maybe<(
      { __typename?: 'UserRecordsResponse' }
      & Pick<UserRecordsResponse, 'code' | 'status' | 'message'>
      & { object?: Maybe<(
        & Pick<UserRecords,  'id' | 'userAccountId' | 'eventName' | 'eventDate' | 'numberOfClassification' | 'approved'>
      )>,countryObject?: Maybe<(
        { __typename?: 'Country' }
       & Pick<Country, 'id' | 'name'| 'code'| 'displaySequence'>
       )>
      }
    )>}
   );

export type QueryGetUserRecordsQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};

export type QueryGetUserRecordsQuery = (
  { __typename?: 'Query' }
  & { getUserRecords?: Maybe<(
    { __typename?: 'UserRecords' }
    & Pick<UserRecords, 'id' | 'userAccountId' | 'eventName' | 'eventDate'  | 'numberOfClassification' | 'approved' | 'status' | 'statusDescription'>
  )>,countryObject?: Maybe<(
    { __typename?: 'Country' }
   & Pick<Country, 'id' | 'name'| 'code'| 'displaySequence'>
   )>
}
)

// export type QueryGetEventFeedbackQuery = (
//   { __typename?: 'Query' }
//   & { getEventFeedback?: Maybe<(
//     { __typename?: 'EventFeedback' }
//     & Pick<EventFeedback,



// )

export type QueryGetArticleForAdminQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetArticleForAdminQuery = (
  { __typename?: 'Query' }
  & { getArticleForAdmin?: Maybe<(
    { __typename?: 'Article' }
    & Pick<Article, 'id' | 'title' | 'coverPhotoUrl' | 'description' | 'shortDescription' | 'type' | 'views' | 'status' | 'postTypes' | 'advPriority'>
    & { author?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'fullName'>
    )>, latestUpdatedObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
    )> }
  )> }
);

export type QueryGetRewardsListForAdminQueryVariables = {
  pagingRequest?: Maybe<RewardsPagingRequest>;
};

export type QueryGetRewardsListForAdminQuery =(
  { __typename?: 'Query' }
  & { getRewardsListForAdmin?: Maybe<(
    { __typename?: 'RewardsListResponse' }
    & Pick<RewardsListResponse, 'offset'|'limit'|'pageIndex'|'totalPage'|'total'>
    & { data?: Maybe<Array<Maybe<(
      & Pick<Rewards, 'id'|'name'|'points'|'imageUrl' | 'quantity'|'startDateObj'|'endDateObj'>
     )>>>
    }
  )>}
);
export type QueryGetRewardsRedeemedListQueryVariables ={
  pagingRequest?: Maybe<RewardsPagingRequest>;
  rewardsId?: Maybe<Scalars['UUID']>;
}
export type QueryGetGetRewardsRedeemedListQuery=(
  { __typename?: 'Query' }
  & { getRewardsRedeemedList?: Maybe<(
    { __typename?: 'RewardsRedeemedListResponse' }
    & Pick<RewardsRedeemedListResponse, 'offset'|'limit'|'pageIndex'|'totalPage'|'total'>
    & { data?: Maybe<Array<Maybe<(
      & Pick<UserRedeemRewardsDTO, 'id'|'userProfileId'|'rewardsId'|'redeemedDate'|'redeemedPoints'|'redemptionOptionMethod'|'mailingAddress'|'postalCode'|'remarks'|'rewardStatus'|'userFullName'|'emailAddress'>
     )>>>, redeemedDate?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>

  )>}
)>}
);
export type QueryGetUsedUserPointListQueryVariables={
  userProfileId?: Maybe<Scalars['UUID']>;
}
export type QueryGetUsedUserPointListQuery=(
  { __typename?: 'Query' }
  & { getUsedUserPointList?: Maybe<(
    { __typename?: 'UsedPointDTOResponse' }
    & Pick<UsedPointDTOResponse, 'status'|'code'|'message'>
    & {object?: Maybe<Array<Maybe<(
      & Pick<UsedPointDTO, 'redeemedDate'|'userPointsRedeemedList'>
    )>>>,redeemedDate?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
  )>, userPointsRedeemedList?: Maybe<Array<Maybe<(
    { __typename?: 'UserRedeemRewards' }
    & Pick<UserRedeemRewards,'id'|'userProfileId'|'rewardsId'|'redeemedDate'|'redeemedPoints'|'redemptionOptionMethod'|'mailingAddress'|'postalCode'|'remarks'|'rewardStatus'|'userFullName'|'emailAddress' | 'rewardName'>
  )>>>}
  )>}
);
export type QueryGetRewardsListQueryVariables = {
  pagingRequest?: Maybe<RewardsPagingRequest>;
};
export type QueryGetRewardsListQuery =(
  { __typename?: 'Query' }
  & { getRewardsList?: Maybe<(
    { __typename?: 'RewardsListResponse' }
    & Pick<RewardsListResponse, 'offset'|'limit'|'pageIndex'|'totalPage'|'total'>
    & { data?: Maybe<Array<Maybe<(
      & Pick<Rewards, 'id'|'name'|'points'|'imageUrl'>
     )>>>
    }
  )>}
);
export type  QueryGetRewardsDetailsQueryVariables ={
  rewardsId?: Maybe<Scalars['UUID']>;
}
export type QueryGetRewardsDetailsQuery =(
  { __typename?: 'Query' }
  & { getRewardsDetails?: Maybe<(
    { __typename?: 'RewardsResponse' }
    & Pick<RewardsResponse, 'status'|'code'|'message'>
    & { object?: Maybe<(
      { __typename?: 'Rewards' }
      & Pick<Rewards, 'id'|'name'|'points'|'imageUrl'|'quantity' | 'endDateObj'| 'startDateObj' | 'description'>
    )>}
  )>}
);
export type QueryGetListArticleForAdminQueryVariables = {
  pagingRequest?: Maybe<ArticlePagingRequest>;
  articleFilter?: Maybe<ArticleFilter>;
};

export type QueryGetListUserAccessForAdminQueryVariables = {
  pagingRequest?: Maybe<UserAccessPagingRequest>;
};
export type QueryGetUserProfileListForAdminQueryVariables = {
  pagingRequest?: Maybe<UserPagingRequest>;
};
export type QueryGetUserProfileListForGroupAdminQueryVariables = {
  pagingRequest?: Maybe< UserPagingRequest>;
  groupId?: Maybe<Scalars['UUID']>;
};
export type QueryGetListUserAccessForAdminQuery = (
  { __typename?: 'Query' }
  & { getListUserAccessForAdmin?: Maybe<(
    { __typename?: 'UserAccessPagingResponse' }
    & Pick<UserAccessPagingResponse, 'offset' | 'limit' | 'total'|'pageIndex'|'totalPage'|'data'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserAccessListResponse' }
      & Pick<UserAccessListResponse, 'id' | 'groupName' | 'access' | 'userProfileList' | 'sportList' | 'modulesList'>
    )>>> }
  )> }
);

export type QueryGetUserProfileListForAdminQuery = (
  { __typename?: 'Query' }
  & { getUserProfileListForAdmin?: Maybe<(
    { __typename?: 'UserPagingResponse' }
    & Pick<UserPagingResponse, 'offset' | 'limit' | 'total'|'pageIndex'|'totalPage'|'data' >
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'fullName' | 'email' | 'chineseName' | 'legalGuardian' | 'isMale' | 'nric' | 'roleCode' | 'sportIds' | 'placeOfBirthId' | 'passport' | 'nationalityId' | 'raceId' | 'religionId' | 'maritalStatus' | 'isNationalService' | 'isNrocMembership' | 'photoProfileUrl' | 'status' | 'lastModifiedBy'>
    )>>> }
  )> }
);

export type QueryGetUserProfileListForGroupAdminQuery = (
  { __typename?: 'Query' }
  & { getUserProfileListForGroupAdminV2?: Maybe<(
    { __typename?: 'UserDTOPagingResponse' }
    & Pick<UserDTOPagingResponse, 'offset' | 'limit' | 'total'|'pageIndex'|'totalPage'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserGroupDto' }
      & Pick<UserGroupDto, 'name' | 'email' | 'userID' | 'groupID' | 'checked'>
    )>>> }
  )> }
);

export type QueryGetListArticleForAdminQuery = (
  { __typename?: 'Query' }
  & { getListArticleForAdmin?: Maybe<(
    { __typename?: 'ArticlePagingResponse' }
    & Pick<ArticlePagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id' | 'title' | 'coverPhotoUrl' | 'description' | 'views' | 'type' | 'status' | 'postTypes'>
      & { author?: Maybe<(
        { __typename?: 'UserAccount' }
        & Pick<UserAccount, 'username' | 'fullName'>
      )>, latestUpdatedObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>,createdDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>, publishDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )> }
    )>>> }
  )> }
);

export type QueryGetListEventFeedbackQueryVariables = {
eventId?:Maybe<Scalars['UUID']>;
pagingRequest?: Maybe<EventFeedbackPagingRequest>
}


export type QueryGetListEventFeedbackQuery = (
  { __typename?: 'Query' }
  & { getListEventFeedback?: Maybe<(
    { __typename?: 'EventFeedbackPagingResponse' }
    & Pick<EventFeedbackPagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'EventFeedback' }
      & Pick<EventFeedback, 'id' | 'userProfileId' | 'eventId' | 'isFeedbackCompleted' | 'points' | 'registeredRole' >
      & { updatedDate?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second' | 'timeZone'>
      )>,  userProfile?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'role' | 'fullName'>
      )>, role?: Maybe<(
        { __typename?: 'Role' }
        & Pick<Role, 'name'| 'code'>
       )>,event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'eventName'>
      )>,answerFeedbackInfoList?: Maybe<Array<Maybe<(
        { __typename?: 'AnswerFeedbackInfo' }
       & Pick<AnswerFeedbackInfo, 'freeTextValue' | 'numberValue' |'questionCode'>
      )>>>,
       feedbackOptionValueList?: Maybe<Array<Maybe<(
        { __typename?: 'FeedbackOptionValue' }
       & Pick<FeedbackOptionValue, 'value' | 'isChecked' |'hasComment'|'comment'>
      )>
      >>}
    )>>>}
  )>}
   )


  export type QueryGetAssessmentBySportVariables ={
    sportId: Maybe<Scalars['UUID']>;
  }
   export type QueryGetAssessmentBySportQuery = (
    { __typename?: 'Query' }
    & { getAssessmentBySport?: Maybe<Array<Maybe<(
      { __typename?: 'Assessment' }
      & Pick<Assessment, 'id'| 'name'>
    )>>>
    }
   );

   export type QueryGetEventFeedbackChartDataQueryVariables = {
    eventId?:Maybe<Scalars['UUID']>;
   }

   export type QueryGetEventFeedbackChartDataQuery = (
    { __typename?: 'Query' }
    & { getEventFeedbackChartData?: Maybe<Array<Maybe<(
      { __typename?: 'ChartData' }
      & Pick<ChartData, 'questionCode' | 'questionText' | 'totalNumberOfResponse' >
      & { feedBackStatistics?: Maybe<Array<Maybe<(
        { __typename?: 'FeedBackStatistics' }
        & Pick<FeedBackStatistics, 'isNumber' | 'numberValue' | 'textValue' | 'count' >
      )>>>}
    )>>>}
   )

//       { __typename?: 'UserAccessListResponse' }
//       & Pick<UserAccessListResponse, 'id' | 'groupName' | 'access' | 'userProfileList' | 'sportList' | 'modulesList'>

//     )>>> }
//   )> }
// );
// export type QueryGetUserProfileListForAdminQuery = (
//   { __typename?: 'Query' }
//   & { getUserProfileListForAdmin?: Maybe<(
//     { __typename?: 'UserPagingResponse' }
//     & Pick<UserPagingResponse, 'offset' | 'limit' | 'total'|'pageIndex'|'totalPage'|'data'|'total'>
//     & { data?: Maybe<Array<Maybe<(
//       { __typename?: 'UserProfile' }
//       & Pick<UserProfile, 'id' | 'fullName' | 'chineseName' | 'legalGuardian' | 'isMale' | 'nric' | 'roleCode' | 'sportIds' | 'placeOfBirthId' | 'passport' | 'nationalityId' | 'raceId' | 'religionId' | 'maritalStatus' | 'isNationalService' | 'isNrocMembership' | 'photoProfileUrl' | 'status' | 'lastModifiedBy'>

//     )>>> }
//   )> }
// );
//       { __typename?: 'UserAccessListResponse' }
//       & Pick<UserAccessListResponse, 'id' | 'groupName' | 'access' | 'userProfileList' | 'sportList' | 'modulesList'>

//     )>>> }
//   )> }
// );
// export type QueryGetUserProfileListForAdminQuery = (
//   { __typename?: 'Query' }
//   & { getUserProfileListForAdmin?: Maybe<(
//     { __typename?: 'UserPagingResponse' }
//     & Pick<UserPagingResponse, 'offset' | 'limit' | 'total'|'pageIndex'|'totalPage'|'data'|'total'>
//     & { data?: Maybe<Array<Maybe<(
//       { __typename?: 'UserProfile' }
//       & Pick<UserProfile, 'id' | 'fullName' | 'chineseName' | 'legalGuardian' | 'isMale' | 'nric' | 'roleCode' | 'sportId' | 'placeOfBirthId' | 'passport' | 'nationalityId' | 'raceId' | 'religionId' | 'maritalStatus' | 'isNationalService' | 'isNrocMembership' | 'photoProfileUrl' | 'status' | 'lastModifiedBy'>

//     )>>> }
//   )> }
// );
export type MutationPublishArticleMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationPublishArticleMutation = (
  { __typename?: 'Mutation' }
  & { publishArticle?: Maybe<(
    { __typename?: 'ArticleResponse' }
    & Pick<ArticleResponse, 'status' | 'code' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id'>
    )> }
  )> }
);

export type MutationUpdateArticleMutationVariables = {
  article?: Maybe<ArticleInput>;
};


export type MutationUpdateArticleMutation = (
  { __typename?: 'Mutation' }
  & { updateArticle?: Maybe<(
    { __typename?: 'ArticleResponse' }
    & Pick<ArticleResponse, 'status' | 'code' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id'>
    )> }
  )> }
);
export type MutationUpdateUserPointVariables ={
  userPoint?: Maybe<UserPointInput>
}
export type MutationUpdateUserPointMutation =(
  { __typename?: 'Mutation' }
  & { updateUserPoint?: Maybe<(
    { __typename?: 'UserPointResponse' }
    & Pick<UserPointResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserPoint' }
      & Pick<UserPoint,'id' | 'userProfileId' | 'eventFeedbackId' | 'surveyId' | 'type' | 'points'>
      )>, receivedDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )> }
    )>}
  )


export type MutationChangeStatusSportMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
  status?: Maybe<Scalars['String']>;
};


export type MutationChangeStatusSportMutation = (
  { __typename?: 'Mutation' }
  & { changeStatusSport?: Maybe<(
    { __typename?: 'SportResponse' }
    & Pick<SportResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Sport' }
      & Pick<Sport, 'id'>
    )> }
  )> }
);

export type MutationCreateSportArticleMutationVariables = {
  article?: Maybe<ArticleInput>;
  classificationIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
 assessments?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateSportArticleMutation = (
  { __typename?: 'Mutation' }
  & { createSportArticle?: Maybe<(
    { __typename?: 'SportResponse' }
    & Pick<SportResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Sport' }
      & Pick<Sport, 'id' | 'articleId'>
    )> }
  )> }
);

export type MutationDeleteSportMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteSportMutation = (
  { __typename?: 'Mutation' }
  & { deleteSport?: Maybe<(
    { __typename?: 'SportResponse' }
    & Pick<SportResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Sport' }
      & Pick<Sport, 'id'>
    )> }
  )> }
);
export type MutationDeleteUserPointVariables = {
  userPointId?: Maybe<Scalars['UUID']>;
}
export type MutationDeleteUserPointMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserPoint?: Maybe<(
    { __typename?: 'UserPointResponse' }
    & Pick<UserPointResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserPoint' }
      & Pick<UserPoint,'id' | 'userProfileId' | 'eventFeedbackId' | 'surveyId' | 'type' | 'points'>
      )>, receivedDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )> }
    )>}
  );

  export type MutationSaveUserRecordsForAdminVariables = {
    userRecords?: Maybe<UserRecordsInput>
  }
  export type MutationSaveUserRecordsForAdminMutation = (
    { __typename?: 'Mutation' }
    & { saveUserRecordsForAdmin?: Maybe<(
      { __typename?: 'UserRecordsResponse' }
      & Pick<UserRecordsResponse, 'code' | 'status' | 'message'>
      & { object?: Maybe<(
        { __typename?: 'UserRecords' }
        & Pick<UserRecords, 'id' | 'approved' | 'numberOfClassification' | 'userAccountId' | 'eventName' | 'eventDate' | 'statusDescription' | 'status'>
      )>, countryObject?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'| 'code'| 'displaySequence'>
      )>}
    )>}
  );
  export type MutationUpdateTrainingAttendanceVariables ={
    trainingSessionAttendee?: Maybe<TrainingSessionAttendeeInputAdmin>
  }
  export type MutationUpdateTrainingAttendanceMutation =(
    { __typename?: 'Mutation' }
    & { updateTrainingAttendance?: Maybe<(
      { __typename?: 'TrainingSessionAttendeeResponse' }
      & Pick<TrainingSessionAttendeeResponse, 'code' | 'status' | 'message'>
      & { object?: Maybe<(
        { __typename?: 'TrainingSessionAttendee' }
        & Pick<TrainingSessionAttendee, 'trainingSessionId'| 'userProfileId'|'fullName'|'checkInStatus'|'reasonType'|'reasonOther'>
      )>,userProfile?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id'| 'fullName'>
      )>, trainingSession?: Maybe<(
        { __typename?: 'TrainingSession' }
        & Pick<TrainingSession, 'id'|'trainingId'|'sessionNumber'|'trainingName'|'dateTrainingObj'>
      )>}
      )>
     }
  );
  export type MutationUpdateUserRoleVariables = {
    roleCode?: Maybe<Scalars['String']>;
    userAccountIdList?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  }
  export type MutationUpdateUserRoleMutation = (
    { __typename?: 'Mutation' }
    & { updateUserRole?: Maybe<(
      { __typename?: 'ResponseContent' }
      & Pick<ResponseContent, 'code' | 'status' | 'message'>
      )>}
  );
  export type MutationSaveUserResultsForAdminVariables = {
    userResults?: Maybe<userResultsInput>
  }
  export type MutationSaveUserResultsForAdminMutation = (
    { __typename?: 'Mutation' }
    & { saveUserResultsForAdmin?: Maybe<(
      { __typename?: 'UserRecordsResponse' }
      & Pick<UserRecordsResponse, 'code' | 'status' | 'message'>
  )>
    }
  );
  export type MutationUpdateStatusUserResultsAdminVariables ={
    resultStatus?: Maybe<StatusUpdateInput>
  }
  export type MutationUpdateStatusUserResultsAdminMutation =(
    { __typename?: 'Mutation' }
    & { updateStatusUserResultsAdmin?: Maybe<(
      { __typename?: 'UserRecordsResponse' }
      & Pick<UserRecordsResponse, 'code' | 'status' | 'message'>
  )>
    }
  );
  export type MutationUpdateUserResultsAdminVariables ={
    userResults?: Maybe<userResultsUpdateInput>
    }
  export type MutationGenerateUserResultsCsvVariables = {
    statisticsInput?: Maybe<statisticsInput>;
    imageFile?:Maybe<Scalars['String']>;
  };
 export type MutationUpdateUserResultsAdminMutation =(
    { __typename?: 'Mutation' }
    & { updateUserResultsAdmin?: Maybe<(
      { __typename?: 'UserRecordsResponse' }
      & Pick<UserRecordsResponse, 'code' | 'status' | 'message'>
  )>
    }
  );
 export type MutationGenerateUserResultsCsvMutation = (
  { __typename?: 'Query' }
  & Pick<Query,'generateUserResultsCsv'>
);
  export type MutationDeleteUserResultsAdminVariables={
    id?: Maybe<Scalars['UUID']>;
  }
  export type MutationDeleteUserResultsAdminMutation =(
    { __typename?: 'Mutation' }
    & { deleteUserResultsAdmin?: Maybe<(
      { __typename?: 'UserRecordsResponse' }
      & Pick<UserRecordsResponse, 'code' | 'status' | 'message'>
  )>
    }
  );

export type MutationUpdateCategorySequenceVariables={
  category?: Maybe<categoryInput>
  direction?: Maybe<Scalars['String']>;
}
export type MutationUpdateCategorySequenceMutation = (
  { __typename?: 'Mutation' }
  & { updateCategorySequence?: Maybe<(
    { __typename?: 'CategoryResponse' }
    & Pick<CategoryResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id'|'name'|'description'|'isPublish'|'displaySequence'>
)>}
  )>}
);
export type MutationUpdateRewardsVariables ={
  rewards?: Maybe<RewardsInput>
}

export type MutationUpdateRewardsMutation = (
  { __typename?: 'Mutation' }
  & { updateRewards?: Maybe<(
    { __typename?: 'RewardsResponse' }
    & Pick<RewardsResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Rewards' }
      & Pick<Rewards, 'id'|'name'|'points'|'imageUrl'|'description' | 'quantity'|'startDateObj' |'endDateObj'>
    )>}
  )>}
);
export type MutationDeleteUserRecordsMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
}
export type MutationDeleteUserRecordsMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserRecords?: Maybe<(
    { __typename?: 'UserRecordsResponse' }
    & Pick<UserRecordsResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserRecords' }
      & Pick<UserRecords, 'id' | 'userAccountId' | 'eventName' | 'eventDate' | 'numberOfClassification' | 'approved'>
      )>,countryObject?: Maybe<(
        { __typename?: 'Country' }
       & Pick<Country, 'id' | 'name'| 'code'| 'displaySequence'>
       )>
    }
      )> }
    );

    export type MutationApproveUserRecordsMutationVariables = {
      id?: Maybe<Scalars['UUID']>;
      status?: Maybe<Scalars['String']>;
      statusDescription?: Maybe<Scalars['String']>;
    }

    export type MutationApproveUserRecordsMutation = (
      { __typename?: 'Mutation' }
      & { approveUserRecords?: Maybe<(
        { __typename?: 'UserRecordsResponse' }
        & Pick<UserRecordsResponse, 'code' | 'status' | 'message'>
        & { object?: Maybe<(
          { __typename?: 'UserRecords' }
          & Pick<UserRecords, 'id' | 'userAccountId' | 'eventName' | 'eventDate'  | 'numberOfClassification' | 'approved' | 'status' | 'statusDescription'>
          )>,countryObject?: Maybe<(
            { __typename?: 'Country' }
           & Pick<Country, 'id' | 'name'| 'code'| 'displaySequence'>
           )>
        }
          )> }
    );

export type QueryGetListSportQueryVariables = {};


export type QueryGetListSportQuery = (
  { __typename?: 'Query' }
  & { getListSport?: Maybe<Array<Maybe<(
    { __typename?: 'Sport' }
    & Pick<Sport, 'id' | 'name' | 'status' | 'athleteCount' | 'coachCount' | 'imageUrl'>
    & { article?: Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id'>

    )> }
  )>>> }
);

export type QueryGetListUserResultsEventQuery = (
  { __typename?: 'Query' }
  & Pick<Query,'getListUserResultsEvent'>
);

export type QueryGetListUserResultsEventQueryVariables ={
  statisticsInput?: Maybe<statisticsInput>
}

export type  statisticsInput ={
  assessmentId?:Maybe<Scalars['UUID']>;
  sportId?: Maybe<Scalars['UUID']>;
  eventName?: Maybe<Scalars['String']>;
  fromDate?:  Maybe<DateTimeObjectInput>;
  toDate?:  Maybe<DateTimeObjectInput>;
  athletes?: Maybe<Scalars['UUID']>;
}

export type QueryGetListUserResultsStatisticsQuery=(
  { __typename?: 'Query' }
  & {getListUserResultsStatistics?: Maybe<Array<Maybe<(
    {__typename?: 'UserResultsStatisticsMap'}
    & Pick<UserResultsStatisticsMap, 'userProfile'|'userResultsStatistics'>
  )>>>,userProfile?:Maybe<Array<Maybe<(
    {__typename?: 'userProfile'}
    &Pick<userProfile, 'id'|'fullName'>
  )>>>,userResultsStatistics?:Maybe<Array<Maybe<(
    {__typename?: 'userResultsStatistics'}
    &Pick<userResultsStatistics,'result'|'competitionDate'|'event'>
  )>>>}
  );

export type UserResultsStatisticsMap={
  userProfile?:UserProfile;
  userResultsStatistics?:Maybe<Array<Maybe<userResultsStatistics>>>;
}

  export type userProfile={
  id? :Maybe<Scalars['UUID']>;
  fullName?:Maybe<Scalars['String']>;
  }
  export type QueryGetListUserResultsStatisticsQueryVariables={
    statisticsInput?: Maybe<statisticsInput>
  }
  export type userResultsStatistics={
    result?:Maybe<Scalars['String']>;
    competitionDate?: Maybe<DateTimeObject>;
    event?:Maybe<Scalars['String']>;
  }
export type QueryGetListUserResultsAthletesAdminQuery=(
  { __typename?: 'Query' }
  & { getListUserResultsAthletesAdmin?: Maybe<Array<Maybe<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id'| 'fullName'>
  )>>>
  }
)
export type QueryGetListUserResultsAthletesAdminQueryVariables={
  statisticsInput?: Maybe<statisticsInput>
}
export type QueryGetListSportForAdminQueryVariables = {
  classificationId?: Maybe<Scalars['UUID']>;
  filter?: Maybe<SportPagingRequest>;
};


export type QueryGetListSportForAdminQuery = (
  { __typename?: 'Query' }
  & { getListSportForAdmin?: Maybe<Array<Maybe<(
    { __typename?: 'Sport' }
    & Pick<Sport, 'id' | 'name' | 'status' | 'athleteCount' | 'coachCount' | 'imageUrl'>
    & { article?: Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id' | 'title'>
    )>, classifications?: Maybe<Array<Maybe<(
      { __typename?: 'Classification' }
      & Pick<Classification, 'id'>
    )>>>, impairments?: Maybe<Array<Maybe<(
      { __typename?: 'Impairment' }
      & Pick<Impairment, 'id'>
    )>>>,
    assessments?: Maybe<Array<Maybe<(
      { __typename?: 'Assessments' }
      & Pick<Assessments, 'id' | 'code' | 'name'>
    )>>> }
  )>>> }
);
export type QueryGetListCategoryForAdminArgs = {

  pagingRequest?: Maybe<CategoryPagingRequest>;
};

export type QueryGetListCategoryForAdminQueryVariables = {
  category?: Maybe<Category>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  linksList?: Maybe<Scalars['String']>;
  pagingRequest?: Maybe<CategoryPagingRequest>;
  };

export type QueryGetListCategoryForAdminQuery = (
  { __typename?: 'Query' }
  & { getListCategoryForAdmin?: Maybe<(
    { __typename?: 'CategoryPagingResponse' }
    & Pick<CategoryPagingResponse, 'total' | 'offset' | 'limit' | 'pageIndex' | 'totalPage'>
    & { data?: Maybe<Array<Maybe<(
       & Pick<CategoryListResponse, 'category' | 'categoryDetails'>
    & { category?: Maybe<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'description' | 'isPublish' | 'displaySequence' |'isShareApp'>
    )>}
    & { categoryDetails?: Maybe<Array<Maybe<(
      & Pick <CategoryDetails,'categoryDetails' | 'categoryLinks'>
      & { categoryDetails?: Maybe<(
        & Pick <CategoryData, 'id'|'description'|'imageId'|'imageUrl'>
      )>}
      & {categoryLinks?: Maybe<Array<Maybe<(
        & Pick <CategoryLink, 'id' | 'linkName' | 'linkUrl' | 'imageUrl' | 'imageId' >
      )>>>}
    )>>>}
    )>>> }
  )>}
);
  export type QueryGetCategoryQueryVariables = {
    category?: Maybe<Category>;
    id?: Maybe<Scalars['UUID']>;
    name?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    linksList?: Maybe<Scalars['String']>;
    };
    export type QueryGetCategoryQuery = (
      { __typename?: 'Query' }
      & { getCategory?: Maybe<(
        { __typename?: 'CategoryListResponse' }
        & Pick<Category, 'description' | 'id' | 'name'|'isPublish'| 'displaySequence'>
        &{ categoryLinks?: Maybe<Array<Maybe<(
          { __typename?: 'CategoryLinks' }
          & Pick<CategoryLinks, 'id' | 'linkImage' |'linkName'|'linkUrl'>
        )>>> }
       )>}
    );

  export type MutationCreateCategoryMutationVariables = {
    category?: Maybe<categoryInput>;
    categoryDetails?:  Maybe<Array<Maybe<categoryDetailsInput>>>;
      }

  export type MutationCreateCategoryMutation = (
    { __typename?: 'Mutation' }
    & { createCategory?: Maybe<(
      { __typename?: 'CategoryResponse' }
      & Pick<CategoryResponse, 'code' | 'status' | 'message'>
      & { object?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'description' | 'name'|'id' | 'isPublish' | 'displaySequence' | 'isShareApp'>

              )> }
    )> }
  );
  export type MutationDeleteCategoryMutationVariables = {
    id?: Maybe<Scalars['UUID']>;
  };

   export type MutationDeleteCategoryMutation = (
    { __typename?: 'Mutation' }
    & { deleteCategory?: Maybe<(
      { __typename?: 'CategoryResponse' }
      & Pick<CategoryResponse, 'code' | 'status' | 'message'>
      & { object?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
      )> }
    )> }
  );

  export type MutationDeleteCategoryLinksMutationVariables = {
    id?: Maybe<Scalars['UUID']>;
  };

   export type MutationDeleteCategoryLinksMutation = (
    { __typename?: 'Mutation' }
    & { deleteCategory?: Maybe<(
      { __typename?: 'CategoryResponse' }
      & Pick<CategoryResponse, 'code' | 'status' | 'message'>
      & { object?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'>
      )> }
    )> }
  );

  export type MutationUpdateCategoryMutationVariables = {
    category?: Maybe<categoryInput>;
    categoryDetails?: Maybe<Array<Maybe<categoryDetailsInput>>>;
  };

  export type MutationUpdateCategoryMutation = (
    { __typename?: 'Mutation' }
    & { updateCategory?: Maybe<(
      { __typename?: 'CategoryResponse' }
      & Pick<CategoryResponse, 'code' | 'status' | 'message'>
      & { object?: Maybe<(
        { __typename?: 'Category' }
        & Pick<Category, 'id'|'name'|'description'|'isPublish'|'displaySequence'|'isShareApp'>
        // & { categoryLinks?: Maybe<Array<Maybe<(
        //   { __typename?: 'Category' }
        //   & Pick<categoryInput, 'id' | 'name' |'description'>
        //   &{ categoryLinks?: Maybe<Array<Maybe<(
        //     { __typename?: 'CategoryLinks' }
        //     & Pick<CategoryLinks, 'id' | 'linkImage' |'linkName'|'linkUrl'>
        )> }
        )> }
      );



    //   )>>> }
    // )>>> }
    //   )>}
    //      )>}
    // );



  export type MutationUpdatePublishStatusMutationVariables={
    id?: Maybe<Scalars['UUID']>;
    isPublish?: Maybe<Scalars['Boolean']>;
  }

  export type MutationUpdatePublishStatusMutation =(
    { __typename?: 'Mutation' }
    & { updatePublishStatus?: Maybe<(
      { __typename?: 'PublishResponse' }
      & Pick<PublishResponse, 'status'|'message'>

      )> }
      );

export type QueryGetListSportGroupQueryVariables = {};


export type QueryGetListSportGroupQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListSportGroup'>
);

export type QueryGetListSportSdscCardingQueryVariables = {};


export type QueryGetListSportSdscCardingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListSportSdscCarding'>
);

export type QueryGetListSportSpexCardingQueryVariables = {};


export type QueryGetListSportSpexCardingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListSportSpexCarding'>
);

export type QueryGetListUserSportLicenseLevelQueryVariables = {};


export type QueryGetListUserSportLicenseLevelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getListUserSportLicenseLevel'>
);

export type QueryGetSportQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetSportQuery = (
  { __typename?: 'Query' }
  & { getSport?: Maybe<(
    { __typename?: 'Sport' }
    & Pick<Sport, 'id' | 'name' | 'status' | 'athleteCount' | 'coachCount' | 'imageUrl'>
    & { article?: Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id' | 'title' | 'coverPhotoUrl' | 'description' | 'linkToWebsite'>
    )> }
  )> }
);

export type QueryGetSportForAdminQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetSportForAdminQuery = (
  { __typename?: 'Query' }
  & { getSportForAdmin?: Maybe<(
    { __typename?: 'Sport' }
    & Pick<Sport, 'id' | 'name' | 'status' | 'athleteCount' | 'coachCount' | 'imageUrl'>
    & { article?: Maybe<(
      { __typename?: 'Article' }
      & Pick<Article, 'id' | 'title' | 'description' | 'coverPhotoUrl' | 'linkToWebsite'>
    )>, classifications?: Maybe<Array<Maybe<(
      { __typename?: 'Classification' }
      & Pick<Classification, 'id'>
    )>>>, impairments?: Maybe<Array<Maybe<(
      { __typename?: 'Impairment' }
      & Pick<Impairment, 'id'>
    )>>>, assessments?: Maybe<Array<Maybe<(
      { __typename?: 'Assessments' }
      & Pick<Assessments, 'id' | 'code' | 'name'>
    )>>>
  }

  )> }
);

export type MutationUpdateSportArticleMutationVariables = {
  article?: Maybe<ArticleInput>;
  sportId?: Maybe<Scalars['UUID']>;
  assessments?: Maybe<Array<Maybe<Scalars['String']>>>;
  classificationIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


export type MutationUpdateSportArticleMutation = (
  { __typename?: 'Mutation' }
  & { updateSportArticle?: Maybe<(
    { __typename?: 'SportResponse' }
    & Pick<SportResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Sport' }
      & Pick<Sport, 'id' | 'articleId'>
    )> }
  )> }
);

export type MutationCreateTrainingMutationVariables = {
  training?: Maybe<TrainingInput>;
};


export type MutationCreateTrainingMutation = (
  { __typename?: 'Mutation' }
  & { createTraining?: Maybe<(
    { __typename?: 'TrainingResponse' }
    & Pick<TrainingResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Training' }
      & Pick<Training, 'id'>
    )> }
  )> }
);

export type MutationCreateTrainingSessionMutationVariables = {
  trainingSession?: Maybe<TrainingSessionInput>;
};


export type MutationCreateTrainingSessionMutation = (
  { __typename?: 'Mutation' }
  & { createTrainingSession?: Maybe<(
    { __typename?: 'TrainingSessionResponse' }
    & Pick<TrainingSessionResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'TrainingSession' }
      & Pick<TrainingSession, 'id'>
    )> }
  )> }
);

export type MutationDeleteTrainingMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteTrainingMutation = (
  { __typename?: 'Mutation' }
  & { deleteTraining?: Maybe<(
    { __typename?: 'TrainingResponse' }
    & Pick<TrainingResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Training' }
      & Pick<Training, 'id'>
    )> }
  )> }
);

export type MutationDeleteTrainingSessionMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteTrainingSessionMutation = (
  { __typename?: 'Mutation' }
  & { deleteTrainingSession?: Maybe<(
    { __typename?: 'TrainingSessionResponse' }
    & Pick<TrainingSessionResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'TrainingSession' }
      & Pick<TrainingSession, 'id'>
    )> }
  )> }
);

export type MutationUpdateTrainingMutationVariables = {
  training?: Maybe<TrainingInput>;
};


export type MutationUpdateTrainingMutation = (
  { __typename?: 'Mutation' }
  & { updateTraining?: Maybe<(
    { __typename?: 'TrainingResponse' }
    & Pick<TrainingResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Training' }
      & Pick<Training, 'id'>
    )> }
  )> }
);

export type MutationUpdateTrainingSessionMutationVariables = {
  trainingSession?: Maybe<TrainingSessionInput>;
};


export type MutationUpdateTrainingSessionMutation = (
  { __typename?: 'Mutation' }
  & { updateTrainingSession?: Maybe<(
    { __typename?: 'TrainingSessionResponse' }
    & Pick<TrainingSessionResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'TrainingSession' }
      & Pick<TrainingSession, 'id'>
    )> }
  )> }
);
export type QueryGetAllSystemLogsQueryVariables = {
  pagingRequest?: Maybe<LogPagingRequest>;
}

export type QueryGetFilterDataForSystemLogsQueryVariables={
  pagingRequest?: Maybe<LogPagingRequest>
}

  export type QueryGetAllSystemLogsQuery = (
    { __typename?: 'Query' }
    & { getAllSystemLogs?: Maybe<(
      { __typename?:'LogPagingResponse' }
      & Pick<LogPagingResponse, 'total' | 'offset' | 'limit'>
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'SystemLogs' }
        & Pick<SystemLogs, 'nameOfUser' | 'email' | 'userType'|'component' | 'action' | 'description'|'origin'|'ipAddress'>
        & {dateTimeObj?: Maybe<(
            { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'|'millisecond'|'timeZone'>
        )> }
    )>>> }
  )> }
);

export type QueryGetFilterDataForSystemLogsQuery=(
  {__typename?: 'Query'}
  & { getFilterDataForSystemLogs?: Maybe<(
    {__typename?:'LogFilterPagingResponse'}
    & Pick<LogFilterPagingResponse,'total' | 'offset' | 'limit' >
    & {data?:Maybe<Array<Maybe<(
      {__typename?: 'SystemLogFilters'}
      & Pick<SystemLogFilters,'usernames' | 'emails'>
    )>>>}
  )>}
)

export type LogFilterPagingResponse={
    __typename?: 'LogFilterPagingResponse';
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    total?: Maybe<Scalars['Int']>;
    data?: Maybe<Array<Maybe<SystemLogFilters>>>;
}

export type SystemLogFilters={
   __typename?: 'SystemLogFilters';
  usernames?:Maybe<Array<Maybe<Scalars['String']>>>;
  emails?:Maybe<Array<Maybe<Scalars['String']>>>;
}
export type QueryGetListTrainingForAdminQueryVariables = {
  pagingRequest?: Maybe<TrainingPagingRequest>;
  filter?: Maybe<DashboardFilter>;
  sportList?:Maybe<Array<Maybe<['UUID']>>>;
};


export type QueryGetListTrainingForAdminQuery = (
  { __typename?: 'Query' }
  & { getListTrainingForAdmin?: Maybe<(
    { __typename?: 'TrainingPagingResponse' }
    & Pick<TrainingPagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Training' }
      & Pick<Training, 'id' | 'trainingName' | 'venue' | 'numberOfSession' | 'frequency' | 'sportImageUrl' | 'status'>
      & { toDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>, fromDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )> }
    )>>> }
  )> }
);
export type QueryGetListAthleteAttendanceForAdminQuery=(
    { __typename?: 'Query' }
    & { getListAthleteAthendaceForAdmin?: Maybe<(
      { __typename?: 'AthleteAttendancePagingResponse' }
      & Pick<AthleteAttendancePagingResponse, 'offset' | 'limit' | 'total'>
      & { data?: Maybe<Array<Maybe<(
        { __typename?: 'AthleteAttendance'}
        & Pick<AthleteAttendance, 'trainingName'| 'attendancePercentage'|'trainingId'>
      )>>>,attendance?: Maybe<Array<Maybe<(
        { __typename?: 'Attendance' }
        & Pick<Attendance, 'sessionId'|'sessionNumber' | 'checkInStatus' | 'reasonType' | 'otherReason' | 'symbol'>
      )>>>,trainingFromDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>,trainingToDateObj?:  Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
    )>}
  )>}
  )
  export type AthleteAttendancePagingResponse={
    __typename?: 'AthleteAttendancePagingResponse';
    offset?: Maybe<Scalars['Int']>;
    limit?: Maybe<Scalars['Int']>;
    total?: Maybe<Scalars['Int']>;
    data?: Maybe<Array<Maybe<AthleteAttendance>>>;
  }

  export type AthleteAttendance={
    __typename?: 'AthleteAttendance';
  trainingName?: Maybe<Scalars['String']>
  trainingId?:  Maybe<Scalars['UUID']>;
  attendancePercentage?:  Maybe<Scalars['Int']>;
   attendance?: Maybe<Array<Maybe<Attendance>>>;
  }


  export type QueryGetListAthleteAttendanceForAdminQueryVariables = {
  filter?: Maybe<TrainingSessionFilter>;
  pagingRequest?: Maybe<TrainingPagingRequest>;
  userProfileId?: Maybe<Scalars['UUID']>;
  }

export type QueryGetListTrainingSessionForAdminQueryVariables = {
  trainingSessionPagingRequest?: Maybe<TrainingSessionPagingRequest>;
  trainingId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListTrainingSessionForAdminQuery = (
  { __typename?: 'Query' }
  & { getListTrainingSessionForAdmin?: Maybe<(
    { __typename?: 'TrainingSessionPagingResponse' }
    & Pick<TrainingSessionPagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'TrainingSession' }
      & Pick<TrainingSession, 'id' | 'trainingId' | 'sessionNumber' | 'vacancy' | 'registered' | 'available' | 'coachIds'>
      & { training?: Maybe<(
        { __typename?: 'Training' }
        & Pick<Training, 'id'>
      )>, dateTrainingObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>, coaches?: Maybe<Array<Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id' | 'fullName'>
      )>>> }
    )>>> }
  )> }
);

export type QueryGetTotalSessionsQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetTotalSessionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTotalSessions'>
);

export type QueryGetTotalSessionsRealTimeQueryVariables = {
  training?: Maybe<TrainingInput>;
};


export type QueryGetTotalSessionsRealTimeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getTotalSessionsRealTime'>
);

export type QueryGetTrainingForAdminQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetTrainingForAdminQuery = (
  { __typename?: 'Query' }
  & { getTrainingForAdmin?: Maybe<(
    { __typename?: 'Training' }
    & Pick<Training, 'id' | 'trainingName' | 'venue' | 'numberOfSession' | 'frequency' | 'status' | 'sportId' | 'athleteManagerIds' | 'coachIds' | 'athleteIds' | 'longitude' | 'latitude' | 'sportImageUrl' | 'description'>
    & { toDateObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
    )>, fromDateObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
    )>, sport?: Maybe<(
      { __typename?: 'Sport' }
      & Pick<Sport, 'id'>
    )>, trainingDateTimes?: Maybe<Array<Maybe<(
      { __typename?: 'TrainingDateTime' }
      & Pick<TrainingDateTime, 'weekday'>
      & { fromTimeObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>, toTimeObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )> }
    )>>>, athleteManagers?: Maybe<Array<Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id'>
    )>>>, coaches?: Maybe<Array<Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id'>
    )>>>, athletes?: Maybe<Array<Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id'>
    )>>> }
  )> }
);

export type QueryGetUserResultAdminQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<ResultsPagingRequest>
}

export type QueryGetUserResultAdminQuery = (
  { __typename?: 'Query' }
  & { getUserResultAdmin?: Maybe<(
    { __typename?: 'UserResultPagingResponse' }
    & Pick<UserResultPagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserResults' }
      & Pick<UserResults, 'id'|'competitionName'| 'CompetitionCategory'|'competitionLevel'| 'noOfParticipants'|'competitionType'|'countryCompeting'| 'competitionDateValue'|'userProfileId'|'event'|'positionNumber'|'result'|'status'|'statusDescription'| 'plainStatus'>
       )>>>,sport?: Maybe<(
        { __typename?: 'Sport' }
        & Pick<Sport, 'id' |'name'>
       )>,country?: Maybe<(
         {_typename?: 'Country'}
         & Pick<Country,'code'|'name'|'displaySequence'|'id'>
       )>,assessment?: Maybe<(
         {_typename?: Assessment}
         & Pick<Assessment,'id'|'name'>
       )>,competitionDate?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour'>
       )>
    }
  )>
    }
);

export type QueryGetTrainingSessionForAdminQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetTrainingSessionForAdminQuery = (
  { __typename?: 'Query' }
  & { getTrainingSessionForAdmin?: Maybe<(
    { __typename?: 'TrainingSession' }
    & Pick<TrainingSession, 'id' | 'trainingId' | 'sessionNumber' | 'vacancy' | 'registered' | 'available' | 'coachIds' | 'athleteIds' | 'venue' | 'latitude' | 'longitude'>
    & { training?: Maybe<(
      { __typename?: 'Training' }
      & Pick<Training, 'id'>
    )>, dateTrainingObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'date' | 'hour' | 'month' | 'second' | 'year' | 'minute'>
    )>, fromTimeObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
    )>, toTimeObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
    )>, coaches?: Maybe<Array<Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'fullName'>
    )>>> }
  )> }
);


export type QueryGetListTrainingSessionAttendeeForAdminQueryVariables ={
filter?: Maybe<TrainingSessionFilter>;
pagingRequest?: Maybe<TrainingPagingRequest>;
trainingId?: Maybe<Scalars['UUID']>;
}
export type QueryGetListTrainingSessionAttendeeForAdminQuery = (
  { __typename?: 'Query' }
  & { getListTrainingSessionAttendeeForAdmin?: Maybe<(
    { __typename?: 'TrainingSessionAttendancePagingResponse' }
    & Pick<TrainingSessionAttendancePagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'TrainingSessionAttendance'}
      & Pick<TrainingSessionAttendance, 'roleType'| 'attendancePercentage'>
    )>>>,userProfile?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id'|'fullName'>
    )>,attendance?: Maybe<Array<Maybe<(
      { __typename?: 'Attendance' }
      & Pick<Attendance, 'sessionId'|'sessionNumber' | 'checkInStatus' | 'reasonType' | 'otherReason' | 'symbol'>
    )>>>,trainingFromDateObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>

  )>}
)>}
);


export type MutationCreateUserAccountMutationVariables = {
  userAccount?: Maybe<UserAccountInput>;
};


export type MutationCreateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { createUserAccount?: Maybe<(
    { __typename?: 'UserAccountResponse' }
    & Pick<UserAccountResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id'>
    )> }
  )> }
);

export type MutationDeleteUserAccountMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserAccount?: Maybe<(
    { __typename?: 'UserAccountResponse' }
    & Pick<UserAccountResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id'>
    )> }
  )> }
);

export type QueryGetPermissionQueryVariables = {};


export type QueryGetPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPermission'>
);

export type MutationCreateUserDocumentMutationVariables = {
  userDocument?: Maybe<UserDocumentInput>;
};


export type MutationCreateUserDocumentMutation = (
  { __typename?: 'Mutation' }
  & { createUserDocument?: Maybe<(
    { __typename?: 'UserDocumentResponse' }
    & Pick<UserDocumentResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserDocument' }
      & Pick<UserDocument, 'id'>
    )> }
  )> }
);

export type MutationDeleteUserDailyAidMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteUserDailyAidMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserDailyAid?: Maybe<(
    { __typename?: 'UserDailyAidResponse' }
    & Pick<UserDailyAidResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserDailyAid' }
      & Pick<UserDailyAid, 'id'>
    )> }
  )> }
);

export type MutationDeleteUserDocumentFileMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteUserDocumentFileMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserDocumentFile?: Maybe<(
    { __typename?: 'UserDocumentResponse' }
    & Pick<UserDocumentResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserDocument' }
      & Pick<UserDocument, 'id'>
    )> }
  )> }
);

export type MutationDeleteUserKinMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteUserKinMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserKin?: Maybe<(
    { __typename?: 'UserKinResponse' }
    & Pick<UserKinResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserKin' }
      & Pick<UserKin, 'id'>
    )> }
  )> }
);

export type MutationDeleteUserMedicalConditionMutationVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type MutationDeleteUserMedicalConditionMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserMedicalCondition?: Maybe<(
    { __typename?: 'UserMedicalConditionResponse' }
    & Pick<UserMedicalConditionResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserMedicalCondition' }
      & Pick<UserMedicalCondition, 'id'>
    )> }
  )> }
);

export type MutationExportUsersMutationVariables = {};


export type MutationExportUsersMutation = (
  { __typename?: 'Mutation' }
  & { exportUsers?: Maybe<(
    { __typename?: 'ExportResponse' }
    & Pick<ExportResponse, 'status' | 'code' | 'message' | 'object'>
  )> }
);

export type MutationUpdateListUserDailyAidMutationVariables = {
  userDailyAidList?: Maybe<Array<Maybe<UserDailyAidInput>>>;
};


export type MutationUpdateListUserDailyAidMutation = (
  { __typename?: 'Mutation' }
  & { updateListUserDailyAid?: Maybe<(
    { __typename?: 'ListUserDailyAidResponse' }
    & Pick<ListUserDailyAidResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<Array<Maybe<(
      { __typename?: 'UserDailyAid' }
      & Pick<UserDailyAid, 'id'>
    )>>> }
  )> }
);

export type MutationUpdateListUserKinMutationVariables = {
  userKins?: Maybe<Array<Maybe<UserKinInput>>>;
};


export type MutationUpdateListUserKinMutation = (
  { __typename?: 'Mutation' }
  & { updateListUserKin?: Maybe<(
    { __typename?: 'ListUserKinResponse' }
    & Pick<ListUserKinResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<Array<Maybe<(
      { __typename?: 'UserKin' }
      & Pick<UserKin, 'id'>
    )>>> }
  )> }
);

export type MutationUpdateListUserMedicalConditionMutationVariables = {
  userMedicalConditionList?: Maybe<Array<Maybe<UserMedicalConditionInput>>>;
};


export type MutationUpdateListUserMedicalConditionMutation = (
  { __typename?: 'Mutation' }
  & { updateListUserMedicalCondition?: Maybe<(
    { __typename?: 'ListUserMedicalConditionResponse' }
    & Pick<ListUserMedicalConditionResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<Array<Maybe<(
      { __typename?: 'UserMedicalCondition' }
      & Pick<UserMedicalCondition, 'id'>
    )>>> }
  )> }
);

export type MutationUpdateListUserSportLicenseByAdminMutationVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
  userSportLicensesInput?: Maybe<UserSportLicensesInput>;
};


export type MutationUpdateListUserSportLicenseByAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateListUserSportLicenseByAdmin?: Maybe<(
    { __typename?: 'UserSportLicensesResponse' }
    & Pick<UserSportLicensesResponse, 'code' | 'message' | 'status'>
  )> }
);

export type MutationUpdateUserContactMutationVariables = {
  userContact?: Maybe<UserContactInput>;
};


export type MutationUpdateUserContactMutation = (
  { __typename?: 'Mutation' }
  & { updateUserContact?: Maybe<(
    { __typename?: 'UserContactResponse' }
    & Pick<UserContactResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserContact' }
      & Pick<UserContact, 'id'>
    )> }
  )> }
);

export type MutationUpdateUserDocumentMutationVariables = {
  userDocument?: Maybe<UserDocumentInput>;
};



export type MutationUpdateUserDocumentMutation = (
  { __typename?: 'Mutation' }
  & { updateUserDocument?: Maybe<(
    { __typename?: 'UserDocumentResponse' }
    & Pick<UserDocumentResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserDocument' }
      & Pick<UserDocument, 'id'>
    )> }
  )> }
);

export type MutationUpdateUserMedicalMutationVariables = {
  userMedical?: Maybe<UserMedicalInput>;
};


export type MutationUpdateUserMedicalMutation = (
  { __typename?: 'Mutation' }
  & { updateUserMedical?: Maybe<(
    { __typename?: 'UserMedicalResponse' }
    & Pick<UserMedicalResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserMedical' }
      & Pick<UserMedical, 'id'>
    )> }
  )> }
);

export type MutationUpdateUserOrganisationMutationVariables = {
  userOrganisation?: Maybe<UserOrganisationInput>;
};


export type MutationUpdateUserOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserOrganisation?: Maybe<(
    { __typename?: 'UserOrganisationResponse' }
    & Pick<UserOrganisationResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserOrganisation' }
      & Pick<UserOrganisation, 'id'>
    )> }
  )> }
);
export type MutationCreateUserPointMutationVariables = {
userPoint?: Maybe<UserPointInput>
}

 export type MutationUpdateDashboardStatusMutation = (
   {__typename?: 'Mutation'}
   & {updateDashboardStatus?: Maybe<(
     { __typename?: 'ResponseContent'}
     &Pick<ResponseContent, 'code'|'message'|'status'>
   )>}
 )

 export type MutationUpdateDashboardStatusVariables={
  accountId?: Maybe<Scalars['UUID']>;
  dashBoardId?:Maybe<Scalars['UUID']>;
  description?:Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
  status?:  Maybe<Scalars['String']>;
 }
export type MutationCreateUserPointMutation = (
  { __typename?: 'Mutation' }
  & { createUserPoint?: Maybe<(
    { __typename?: 'UserPointResponse' }
    & Pick<UserPointResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserPoint' }
      & Pick<UserPoint,'id' | 'userProfileId' | 'eventFeedbackId' | 'surveyId' | 'type' | 'points'>
      )>, receivedDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )> }
    )>}
  )
  export type MutationCreateRewardsMutationVariables={

    rewards?: Maybe<RewardsInput>
  }

  export type MutationCreateRewardsMutation = (
    { __typename?: 'Mutation' }
    & { createRewards?: Maybe<(
      { __typename?: 'RewardsResponse' }
      & Pick<RewardsResponse, 'code' | 'status' | 'message'>
      & { object?: Maybe<(
        { __typename?: 'Rewards' }
      & Pick<Rewards,'id'|'name'|'points'|'imageUrl'|'description' |'quantity' |'startDateObj'| 'endDateObj'>
      )>
    }
    )>}
  );
export type MutationCreateEventPointsMutationVariables ={
  eventPoints?:Maybe<EventPointsInput>
}
  export type MutationCreateEventPointsMutation = (
    { __typename?: 'Mutation' }
    & { createEventPoints?: Maybe<(
      { __typename?: 'EventResponse' }
      & Pick<EventResponse, 'code' | 'status' | 'message'>
      & { object?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
      )>,
      }
    )>
    }
  );
export type MutationDeleteRewardForAdminMutationVariables={
  rewardId?: Maybe<Scalars['UUID']>;
}
export type MutationDeleteRewardForAdminMutation=(
  { __typename?: 'Mutation' }
  & { deleteRewardForAdmin?: Maybe<(
    { __typename?: 'RewardsResponse' }
    & Pick<RewardsResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'Rewards' }
      & Pick<Rewards, 'id'|'name'|'description'|'imageUrl'|'points'>
    )> }
  )> }
);
export type MutationUpdateUserOtherMutationVariables = {
  userOther?: Maybe<UserOtherInput>;
};

export type MutationUpdateRewardRedeemedStatusMutation=(
  { __typename?: 'Mutation' }
  & { getRewardsRedeemedList?: Maybe<(
    { __typename?: 'RewardsRedeemResponse' }
    & Pick<RewardsRedeemResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserRedeemRewards' }
      & Pick<UserRedeemRewards, 'id'|'userProfileId'|'rewardsId'|'redeemedDate'|'redeemedPoints'|'redemptionOptionMethod'|'mailingAddress'|'postalCode'|'remarks'|'rewardStatus'|'userFullName'|'emailAddress'>
    )>, redeemedDate?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
    )>
   }
  )> }
);
export type MutationUpdateRewardRedeemedStatusMutationVariables={
redeemId?:Maybe<Scalars['UUID']>;
remarks?: Maybe<Scalars['String']>;
status?: Maybe<Scalars['String']>;
}

export type MutationUpdateUserOtherMutation = (
  { __typename?: 'Mutation' }
  & { updateUserOther?: Maybe<(
    { __typename?: 'UserOtherResponse' }
    & Pick<UserOtherResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserOther' }
      & Pick<UserOther, 'id'>
    )> }
  )> }
);

export type MutationUpdateUserProfileMutationVariables = {
  userProfile?: Maybe<UserProfileInput>;
};


export type MutationUpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile?: Maybe<(
    { __typename?: 'UserProfileResponse' }
    & Pick<UserProfileResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id'>
    )> }
  )> }
);

export type MutationUpdateUserRecommendedSportsByAdminMutationVariables = {
  recommendedSports?: Maybe<UserRecommendedSportsInput>;
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type MutationUpdateUserRecommendedSportsByAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateUserRecommendedSportsByAdmin?: Maybe<(
    { __typename?: 'RecommendedSportsResponse' }
    & Pick<RecommendedSportsResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<Array<Maybe<(
      { __typename?: 'UserRecommendedSport' }
      & Pick<UserRecommendedSport, 'imageUrl' | 'sportId' | 'sportName' | 'isRecommended' | 'isInterested' | 'isCompetitive' | 'sportSdscCarding' | 'sportGroup' | 'sportSpexCarding' | 'sportYear' | 'sportClassification'>
    )>>> }
  )> }
);

export type QueryDownloadUserDocumentFileQueryVariables = {};


export type QueryDownloadUserDocumentFileQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadUserDocumentFile'>
);

export type QueryGetListRecommendedSportByAdminQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
  pagingRequest?: Maybe<RecommendedSportPagingRequest>;
};


export type QueryGetListRecommendedSportByAdminQuery = (
  { __typename?: 'Query' }
  & { getListRecommendedSportByAdmin?: Maybe<(
    { __typename?: 'RecommendedSportPagingResponse' }
    & Pick<RecommendedSportPagingResponse, 'offset' | 'limit' | 'pageIndex' | 'totalPage' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserRecommendedSport' }
      & Pick<UserRecommendedSport, 'imageUrl' | 'sportId' | 'sportName' | 'isInterested' | 'isCompetitive' | 'sportSdscCarding' | 'sportGroup' | 'sportSpexCarding' | 'sportYear' | 'sportClassification'>
    )>>> }
  )> }
);

export type QueryGetListRoleQueryVariables = {};


export type QueryGetListRoleQuery = (
  { __typename?: 'Query' }
  & { getListRole?: Maybe<Array<Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'name' | 'code'>
  )>>> }
);

export type QueryGetListUserDailyAidQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserDailyAidQuery = (
  { __typename?: 'Query' }
  & { getListUserDailyAid?: Maybe<Array<Maybe<(
    { __typename?: 'UserDailyAid' }
    & Pick<UserDailyAid, 'id' | 'userAccountId' | 'aidType' | 'otherContent' | 'length' | 'width' | 'height' | 'weight' | 'diameter' | 'batteryType'>
  )>>> }
);

export type QueryGetListUserDocumentQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserDocumentQuery = (
  { __typename?: 'Query' }
  & { getListUserDocument?: Maybe<Array<Maybe<(
    { __typename?: 'UserDocument' }
    & Pick<UserDocument, 'id' | 'documentName' | 'userAccountId'>
    & { userDocumentFiles?: Maybe<Array<Maybe<(
      { __typename?: 'UserDocumentFile' }
      & Pick<UserDocumentFile, 'id' | 'fileName' | 'fileUrl'>
      & { uploadedDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )> }
    )>>> }
  )>>> }
);

export type QueryGetListUserKinQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserKinQuery = (
  { __typename?: 'Query' }
  & { getListUserKin?: Maybe<Array<Maybe<(
    { __typename?: 'UserKin' }
    & Pick<UserKin, 'id' | 'userAccountId' | 'relationship' | 'relationshipOther' | 'fullName' | 'isSameAddressContact' | 'address' | 'postalCode' | 'homeNumber' | 'mobileNumber' | 'emailAddress'>
  )>>> }
);

export type QueryGetListUserMedicalConditionQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetListUserMedicalConditionQuery = (
  { __typename?: 'Query' }
  & { getListUserMedicalCondition?: Maybe<Array<Maybe<(
    { __typename?: 'UserMedicalCondition' }
    & Pick<UserMedicalCondition, 'id' | 'userAccountId' | 'classificationId' | 'medicalCondition' | 'medicalMemo'>
  )>>> }
);

export type QueryGetListUserProfileQueryVariables = {
  roleCode?: Maybe<Scalars['String']>;
  users?: Maybe<Scalars['String']>;
  registeredUser?: Maybe<Scalars['String']>;
  volunteer?: Maybe<Scalars['String']>;
  pagingRequest?: Maybe<UserPagingRequest>;
  status?: Maybe<Scalars['String']>;
  sportList?:Maybe<Array<Maybe<['UUID']>>>;
 };


export type QueryGetListUserProfileQuery = (
  { __typename?: 'Query' }
  & { getListUserProfile?: Maybe<(
    { __typename?: 'UserPagingResponse' }
    & Pick<UserPagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'userAccountId' | 'fullName' | 'photoProfileUrl' | 'nric' | 'isNrocMembership' | 'legalGuardian' | 'status'>
      & { sports?: Maybe<Array<Maybe<(
        { __typename?: 'Sport' }
        & Pick<Sport, 'name'>
      )>>>, accountCreatedDate?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )>}
    )>>> }
  )> }
);
export type QueryGetListUserProfileForTrainingQueryVariables = {
  roleCode?: Maybe<Scalars['String']>;
  pagingRequest?: Maybe<UserPagingRequest>;
  status?: Maybe<Scalars['String']>;
  sportList?:Maybe<Array<Maybe<['UUID']>>>;
}

export type QueryGetListUserProfileForTrainingQuery = (
  { __typename?: 'Query' }
  & { getListUserProfileForTraining?: Maybe<(
    { __typename?: 'UserPagingResponse' }
    & Pick<UserPagingResponse, 'offset' | 'limit' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'userAccountId' | 'fullName' | 'photoProfileUrl' | 'nric' | 'isNrocMembership' | 'legalGuardian' | 'status'>
       & { sports?: Maybe<Array<Maybe<(
        { __typename?: 'Sport' }
        & Pick<Sport, 'name'>
      )>>> }
        )>>> }
      )> }
    );


export type QueryGetListUserSportLicenseByAdminQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
  pageRequest?: Maybe<PagingRequest>;
};


export type QueryGetListUserSportLicenseByAdminQuery = (
  { __typename?: 'Query' }
  & { getListUserSportLicenseByAdmin?: Maybe<(
    { __typename?: 'SportLicensePagingResponse' }
    & Pick<SportLicensePagingResponse, 'offset' | 'limit' | 'pageIndex' | 'totalPage' | 'total'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'UserSportLicense' }
      & Pick<UserSportLicense, 'id' | 'imageUrl' | 'sportId' | 'sportName' | 'level'>
      & { sport?: Maybe<(
        { __typename?: 'Sport' }
        & Pick<Sport, 'name' | 'imageUrl'>
      )>, expiryDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date'>
      )> }
    )>>> }
  )> }
);

export type QueryGetUserContactQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserContactQuery = (
  { __typename?: 'Query' }
  & { getUserContact?: Maybe<(
    { __typename?: 'UserContact' }
    & Pick<UserContact, 'id' | 'userAccountId' | 'address' | 'postalCode' | 'homeNumber' | 'mobileNumber' | 'emailAddress' | 'emergencyContactName' | 'emergencyContactNumber' | 'athleteProfileIdList'>
  )> }
);

export type QueryGetImportUserVariables = {};
export type QueryGetImportUserQuery = (
  { __typename?: 'Query' }
  & { getImportUserTemplate?: Maybe<(
    { __typename?: 'ImportResponse' }
    & Pick<ImportResponse, 'status' | 'code' | 'message' | 'object' >
  )> }
);

export type QueryGetUserDocumentQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserDocumentQuery = (
  { __typename?: 'Query' }
  & { getUserDocument?: Maybe<(
    { __typename?: 'UserDocument' }
    & Pick<UserDocument, 'id' | 'documentName' | 'userAccountId'>
    & { userDocumentFiles?: Maybe<Array<Maybe<(
      { __typename?: 'UserDocumentFile' }
      & Pick<UserDocumentFile, 'id' | 'fileName' | 'fileUrl'>
      & { uploadedDateObj?: Maybe<(
        { __typename?: 'DateTimeObject' }
        & Pick<DateTimeObject, 'year' | 'month' | 'date' | 'hour' | 'minute' | 'second'>
      )> }
    )>>> }
  )> }
);

export type QueryGetUserMedicalQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserMedicalQuery = (
  { __typename?: 'Query' }
  & { getUserMedical?: Maybe<(
    { __typename?: 'UserMedical' }
    & Pick<UserMedical, 'id' | 'userAccountId' | 'bloodType' | 'height' | 'weight' | 'dietaryPreference' | 'foodAllergy' | 'medicalMemo' | 'medicalCondition'>
  )> }
);

export type QueryGetUserOrganisationQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserOrganisationQuery = (
  { __typename?: 'Query' }
  & { getUserOrganisation?: Maybe<(
    { __typename?: 'UserOrganisation' }
    & Pick<UserOrganisation, 'id' | 'userAccountId' | 'occupation' | 'organisationName' | 'organisationAddress' | 'personInCharge' | 'designationPersonInCharge'>
  )> }
);

export type QueryGetUserOtherQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserOtherQuery = (
  { __typename?: 'Query' }
  & { getUserOther?: Maybe<(
    { __typename?: 'UserOther' }
    & Pick<UserOther, 'id' | 'userAccountId' | 'topSize' | 'bottomSize' | 'shoeSize'>
  )> }
);
export type QueryGetUserAccessGroupDetailsQueryVariables = {
  id?: Maybe<Scalars['UUID']>;
}
export type QueryGetUserAccessGroupDetailsQuery = (
  { __typename?: 'Query' }
  & { getUserAccessGroupDetails?: Maybe<(
    { __typename?: 'UserAccessListResponse' }
    & Pick<UserAccessListResponse, 'id'|'groupName'|'access'>
    & { userProfileList?: Maybe<Array<Maybe<(
      { __typename?: 'Users' }
      & Pick<Users, 'id'|'userAccountId'|'fullName'>
    )>>>,sportList?:Maybe<Array<Maybe<(
      { __typename?: 'data' }
      & Pick<data, 'id'|'name'>
    )>>>,modulesList?: Maybe<Array<Maybe<(
      { __typename?: 'data' }
      & Pick<data, 'id'|'name'>
    )>>>}
  )>}
)



export type QueryGetUserProfileQueryVariables = {
  userAccountId?: Maybe<Scalars['UUID']>;
};


export type QueryGetUserProfileQuery = (
  { __typename?: 'Query' }
  & { getUserProfile?: Maybe<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'fullName' | 'chineseName' | 'legalGuardian' | 'isMale' | 'nric' | 'roleCode' | 'sportIds' | 'placeOfBirthId' | 'passport' | 'nationalityId' | 'raceId' | 'classifier' | 'level' | 'religionId' | 'maritalStatus' | 'isNationalService' | 'isNrocMembership' | 'photoProfileUrl' | 'status' | 'lastModifiedBy'>
    & { sport?: Maybe<Array<Maybe<(
      { __typename?: 'Sport' }
      & Pick<Sport, 'id'>
    )>>>, dateOfBirthObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date'>
    )>, placeOfBirth?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id'>
    )>, dateOfIssueObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date'>
    )>, dateOfExpiryObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date'>
    )>, nationality?: Maybe<(
      { __typename?: 'Nationality' }
      & Pick<Nationality, 'id' | 'name'>
    )>, race?: Maybe<(
      { __typename?: 'Race' }
      & Pick<Race, 'id'>
    )>, religion?: Maybe<(
      { __typename?: 'Religion' }
      & Pick<Religion, 'id'>
    )>, dateNsEnlistmentObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date'>
    )>, dateNsCompletionObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'month' | 'date'>
    )>, lastModifiedDateObj?: Maybe<(
      { __typename?: 'DateTimeObject' }
      & Pick<DateTimeObject, 'year' | 'date' | 'month' | 'hour' | 'minute' | 'second' | 'timeZone'>
    )> }
  )> }
);

export type MutationRemoveEventAttendanceByAdminMutationVariables ={
  actionType?: Maybe<AttendanceEventType>;
  eventAttendeeId?:Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  }
  export type MutationRemoveEventAttendanceByAdminMutation =(
    { __typename?: 'Mutation' }
     & { removeEventAttendanceByAdmin?: Maybe<(
    { __typename?: 'EventAttendeeResponse' }
     & Pick<EventAttendeeResponse, 'status'|'message'|'code'>
     & { object?: Maybe<(
      { __typename?: 'EventAttendee' }
      & Pick<EventAttendee, 'id'|'userProfileId'|'eventId'>
     )>}
     )>}
  );

 export type MutationUpdateListUserMedicalConditionAdminMutationVariables = {
  userMedicalConditionList?: Maybe<Array<Maybe<UserMedicalConditionInputAdmin>>>;
 }

 export type MutationUpdateListUserMedicalConditionAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateListUserMedicalConditionAdmin?: Maybe<(
    { __typename?: 'ListUserMedicalConditionResponse' }
    & Pick<ListUserMedicalConditionResponse, 'status'|'message'|'code'>
    & { object?: Maybe<Array<Maybe<(
      { __typename?: 'UserMedicalCondition' }
      & Pick<UserMedicalCondition, 'id' | 'userAccountId' | 'classificationId' |'medicalMemo'>
    )>>>}
  )>}
 );

export type MutationRegisterMutationVariables = {
  userAccount?: Maybe<UserAccountInput>;
};


export type MutationRegisterMutation = (
  { __typename?: 'Mutation' }
  & { register?: Maybe<(
    { __typename?: 'UserAccountResponse' }
    & Pick<UserAccountResponse, 'code' | 'status' | 'message'>
    & { object?: Maybe<(
      { __typename?: 'UserAccount' }
      & Pick<UserAccount, 'id'>
    )> }
  )> }
);

export const MutationChangePasswordDocument = gql`
    mutation mutationChangePassword($newPassword: String, $token: String) {
  changePassword(newPassword: $newPassword, token: $token) {
    code
    status
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationChangePasswordGQL extends Apollo.Mutation<MutationChangePasswordMutation, MutationChangePasswordMutationVariables> {
    document = MutationChangePasswordDocument;

  }
export const MutationCheckTokenDocument = gql`
    mutation mutationCheckToken($token: String) {
  checkToken(token: $token) {
    code
    status
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationCheckTokenGQL extends Apollo.Mutation<MutationCheckTokenMutation, MutationCheckTokenMutationVariables> {
    document = MutationCheckTokenDocument;

  }
export const QueryGetCurrentUserProfileDocument = gql`
    query queryGetCurrentUserProfile {
  getCurrentUserProfile {
    id
    userAccountId
    fullName
  }
}
    `;


  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetCurrentUserProfileGQL extends Apollo.Query<QueryGetCurrentUserProfileQuery, QueryGetCurrentUserProfileQueryVariables> {
    document = QueryGetCurrentUserProfileDocument;

  }
  export const QueryGetAccessDetailsByLoggedInUserDocument = gql`
  query queryGetAccessDetailsByLoggedInUser {
    getAccessDetailsByLoggedInUser {
      accessDetails {
        SportList {
          id
          name
        }
        access
        groupName
        id
        modulesList {
          id
          name
        }
      }
      fullName
      id
    }
}
  `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetAccessDetailsByLoggedInUserGQL extends Apollo.Query<QueryGetAccessDetailsByLoggedInUserQuery, QueryGetAccessDetailsByLoggedInUserQueryVariables> {
    document = QueryGetAccessDetailsByLoggedInUserDocument;

  }
export const MutationLoginAdminDocument = gql`
    mutation mutationLoginAdmin($userAccount: userAccountInput) {
  loginAdmin(userAccount: $userAccount) {
    role
    token
    code
    status
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationLoginAdminGQL extends Apollo.Mutation<MutationLoginAdminMutation, MutationLoginAdminMutationVariables> {
    document = MutationLoginAdminDocument;

  }
export const MutationRequestChangPasswordDocument = gql`
    mutation mutationRequestChangPassword($emailAddress: String) {
  requestChangePassword(emailAddress: $emailAddress) {
    code
    status
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationRequestChangPasswordGQL extends Apollo.Mutation<MutationRequestChangPasswordMutation, MutationRequestChangPasswordMutationVariables> {
    document = MutationRequestChangPasswordDocument;

  }
export const QueryGetListAidTypeDocument = gql`
    query queryGetListAidType {
  getListAidType
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListAidTypeGQL extends Apollo.Query<QueryGetListAidTypeQuery, QueryGetListAidTypeQueryVariables> {
    document = QueryGetListAidTypeDocument;

  }
export const QueryGetListBloodTypeDocument = gql`
    query queryGetListBloodType {
  getListBloodType
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListBloodTypeGQL extends Apollo.Query<QueryGetListBloodTypeQuery, QueryGetListBloodTypeQueryVariables> {
    document = QueryGetListBloodTypeDocument;

  }
  export const QueryDownloadUserTrainingAttendanceUrlDocument = gql`
  query queryDownloadUserTrainingAttendanceUrl($trainingSessionId: [UUID],$userProfileId: UUID) {
    downloadUserTrainingAttendanceUrl(trainingSessionId: $trainingSessionId,userProfileId: $userProfileId){
      code
      message
      object
      status
    }

}

  `;

 @Injectable({
    providedIn: 'root'
  })
  export class QueryDownloadUserTrainingAttendanceUrlGQL extends Apollo.Query<QueryDownloadUserTrainingAttendanceUrlQuery, QueryDownloadUserTrainingAttendanceUrlQueryVariables> {
    document = QueryDownloadUserTrainingAttendanceUrlDocument;

  }

export const QueryDownloadTrainingAttendanceOfAthleteUrlDocument = gql`
query queryDownloadTrainingAttendanceOfAthleteUrl($trainingId:UUID,$trainingName:String,$trainingSessionId: [UUID],$userProfileId: UUID) {
  downloadTrainingAttendanceOfAthlete(trainingId: $trainingId,trainingName: $trainingName,trainingSessionId: $trainingSessionId,userProfileId: $userProfileId){
    code
    message
    object
    status
  }

}

`;

@Injectable({
  providedIn: 'root'
})
export class QueryDownloadTrainingAttendanceOfAthleteGQL extends Apollo.Query<QueryDownloadTrainingAttendanceOfAthleteUrlQuery,QueryDownloadTrainingAttendanceOfAthleteUrlQueryVariables> {
  document = QueryDownloadTrainingAttendanceOfAthleteUrlDocument;

}

export const QueryGetdownloadSystemLogExcelReportDocument = gql`
query queryDownloadSystemLogExcelReport($pagingRequest: LogPagingRequest) {
  downloadSystemLogExcelReport(pagingRequest:$pagingRequest){
    code
    message
    object
    status
  }

}

`;

@Injectable({
  providedIn: 'root'
})
export class QueryGetdownloadSystemLogExcelReportGQL extends Apollo.Query<QueryDownloadSystemLogExcelReportQuery,QueryDownloadSystemLogExcelReportQueryVariables> {
  document = QueryGetdownloadSystemLogExcelReportDocument;

}



export const QueryDownloadAllTrainingAttendanceOfAthleteUrlDocument = gql`
query queryDownloadAllTrainingAttendanceOfAthleteUrl($filter: TrainingSessionFilter,$fullName:String,$userProfileId:UUID) {
  downloadAllTrainingAttendanceOfAthlete(filter:$filter,fullName:$fullName,userProfileId: $userProfileId){
    code
    message
    object
    status
  }

}

`;

@Injectable({
  providedIn: 'root'
})
export class QueryDownloadAllTrainingAttendanceOfAthleteUrlGQL extends Apollo.Query<QueryDownloadAllTrainingAttendanceOfAthleteUrlQuery,QueryDownloadAllTrainingAttendanceOfAthleteUrlQueryVariables> {
  document = QueryDownloadAllTrainingAttendanceOfAthleteUrlDocument;

}

export const QueryGetListClassificationDocument = gql`
    query queryGetListClassification {
  getListClassification {
    id
    impairment {
      name
    }
    impairmentId
    name
    code
  }
}
    `;


  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListClassificationGQL extends Apollo.Query<QueryGetListClassificationQuery, QueryGetListClassificationQueryVariables> {
    document = QueryGetListClassificationDocument;

  }
  export const QueryGetSportsListForAdminDocument = gql`
    query queryGetSportsListForAdmin($query: String) {
      getSportsListForAdmin(query: $query)  {
    id
    status
    name
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetSportsListForAdminGQL extends Apollo.Query<QueryGetSportsListForAdminQuery, QueryGetSportsListForAdminQueryVariables> {
    document = QueryGetSportsListForAdminDocument;

  }
  export const QueryGetModulesListForAdminDocument = gql`
  query queryGetModulesListForAdmin{
    getModulesListForAdmin{
  id

  name

}
}
  `;
  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetModulesListForAdminGQL extends Apollo.Query<QueryGetModulesListForAdminQuery, QueryGetModulesListForAdminQueryVariables> {
    document = QueryGetModulesListForAdminDocument;

  }
export const QueryGetListClothSizeDocument = gql`
    query queryGetListClothSize {
  getListClothSize
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListClothSizeGQL extends Apollo.Query<QueryGetListClothSizeQuery, QueryGetListClothSizeQueryVariables> {
    document = QueryGetListClothSizeDocument;

  }
export const QueryGetListCountryDocument = gql`
    query queryGetListCountry {
  getListCountry {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListCountryGQL extends Apollo.Query<QueryGetListCountryQuery, QueryGetListCountryQueryVariables> {
    document = QueryGetListCountryDocument;

  }
export const QueryGetListImpairmentDocument = gql`
    query queryGetListImpairment {
  getListImpairment {
    id
    name
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListImpairmentGQL extends Apollo.Query<QueryGetListImpairmentQuery, QueryGetListImpairmentQueryVariables> {
    document = QueryGetListImpairmentDocument;

  }
export const QueryGetListMaritalStatusDocument = gql`
    query queryGetListMaritalStatus {
  getListMaritalStatus
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListMaritalStatusGQL extends Apollo.Query<QueryGetListMaritalStatusQuery, QueryGetListMaritalStatusQueryVariables> {
    document = QueryGetListMaritalStatusDocument;

  }
export const QueryGetListNationalityDocument = gql`
    query queryGetListNationality {
  getListNationality {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListNationalityGQL extends Apollo.Query<QueryGetListNationalityQuery, QueryGetListNationalityQueryVariables> {
    document = QueryGetListNationalityDocument;

  }
export const QueryGetListPostTypeDocument = gql`
    query queryGetListPostType {
  getListPostType
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListPostTypeGQL extends Apollo.Query<QueryGetListPostTypeQuery, QueryGetListPostTypeQueryVariables> {
    document = QueryGetListPostTypeDocument;

  }
export const QueryGetListRaceDocument = gql`
    query queryGetListRace {
  getListRace {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListRaceGQL extends Apollo.Query<QueryGetListRaceQuery, QueryGetListRaceQueryVariables> {
    document = QueryGetListRaceDocument;

  }
export const QueryGetListRelationshipDocument = gql`
    query queryGetListRelationship {
  getListRelationship
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListRelationshipGQL extends Apollo.Query<QueryGetListRelationshipQuery, QueryGetListRelationshipQueryVariables> {
    document = QueryGetListRelationshipDocument;

  }
  export const QueryGetListReasonTypeDocument = gql`
  query queryGetListReasonType {
    getListReasonType
}
  `;

@Injectable({
  providedIn: 'root'
})
export class QueryGetListReasonTypeGQL extends Apollo.Query<QueryGetGetListReasonTypeQuery, QueryGetListReasonTypeQueryVariables> {
  document = QueryGetListReasonTypeDocument;

}

export const QueryGetListReligionDocument = gql`
    query queryGetListReligion {
  getListReligion {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListReligionGQL extends Apollo.Query<QueryGetListReligionQuery, QueryGetListReligionQueryVariables> {
    document = QueryGetListReligionDocument;

  }
  export const QueryGetAllSystemLogsDocument = gql`
  query queryGetAllSystemLogs ($pagingRequest: LogPagingRequest){
    getAllSystemLogs(pagingRequest: $pagingRequest){
      data {
        dateTimeObj {
          date
          hour
          millisecond
          minute
          month
          second
          timeZone
          year
        }
        component
        action
        description
        email
        ipAddress
        nameOfUser
        origin
        userType
      }
      total
      offset
      limit
    }
  }


    `;

@Injectable({
    providedIn: 'root'
  })
  export class QueryGetAllSystemLogsGQL extends Apollo.Query<QueryGetAllSystemLogsQuery, QueryGetAllSystemLogsQueryVariables> {
    document = QueryGetAllSystemLogsDocument;

  }

  export const QueryGetFilterDataForSystemLogsGQLDocument = gql`
  query queryGetFilterDataForSystemLogs ($pagingRequest: LogPagingRequest){
    getFilterDataForSystemLogs(pagingRequest: $pagingRequest){
      limit
      offset
      total
      data {
        usernames
        emails
      }
  }
}
    `;

@Injectable({
    providedIn: 'root'
  })
  export class QueryGetFilterDataForSystemLogsGQL extends Apollo.Query<QueryGetFilterDataForSystemLogsQuery, QueryGetFilterDataForSystemLogsQueryVariables> {
    document = QueryGetFilterDataForSystemLogsGQLDocument;

  }
export const QueryGetListShoeSizeDocument = gql`
    query queryGetListShoeSize {
  getListShoeSize
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListShoeSizeGQL extends Apollo.Query<QueryGetListShoeSizeQuery, QueryGetListShoeSizeQueryVariables> {
    document = QueryGetListShoeSizeDocument;

  }
export const QueryGetListUserStatusDocument = gql`
    query queryGetListUserStatus {
  getListUserStatus
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserStatusGQL extends Apollo.Query<QueryGetListUserStatusQuery, QueryGetListUserStatusQueryVariables> {
    document = QueryGetListUserStatusDocument;

  }
export const QueryGetListEventTrainingForAdminDocument = gql`
    query queryGetListEventTrainingForAdmin($pagingRequest: DashboardPagingRequest, $filter: DashboardFilter) {
  getListEventTrainingForAdmin(pagingRequest: $pagingRequest, filter: $filter) {
    offset
    limit
    total
    data {
      id
      eventName
      type
      eventDateObj {
        year
        month
        date
        hour
        minute
        second
      }
      fromTimeObj {
        year
        month
        date
        hour
        minute
        second
      }
      toTimeObj {
        year
        month
        date
        hour
        minute
        second
      }
      venue
      imageUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetPendingListGQL extends Apollo.Query<QueryGetPendingListGQLAdminQuery, QueryGetPendingListGQLAdminQueryVariables> {
    document = QueryGetPendingListForAdminDocument;

  }
  export const QueryGetPendingListForAdminDocument = gql`
    query queryGetPendingActionList($pagingRequest: DashboardPagingRequest,$sportList:[UUID]) {
      getPendingActionList(pagingRequest: $pagingRequest,sportList:$sportList) {
        offset
        limit
        pageIndex
        totalPage
        total
        data {
          accountId
          description
          emailAddress
          fullName
          profileId
          recordsResultsId
          status
          redeemedId
          rewardId
          submittedDate {
            date
            year
            month
          }
}
}
}
    `;
  export const QueryGetUserRecordDocument = gql`
    query getUserRecordDetails($recordId:UUID) {
      getUserRecordDetails(recordId:$recordId){
        code
        message
        object {
          country
          emailAddress
          eventDate
          eventName
          fullName
          id
          status
          statusDescription
        }
        status
      }
      }
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetUserRecordDetailsGQL extends Apollo.Query<QueryGetUserRecordDetailsQuery, QueryGetUserRecordDetailsQueryVariables> {
    document = QueryGetUserRecordDocument;

  }

  export const QueryGetUserRewardDetailsDocument = gql`
  query getUserRewardRedeemedDetails($redeemedId:UUID) {
    getUserRewardRedeemedDetails(redeemedId:$redeemedId){
      code
      message
      object {
        emailAddress
        fullName
        redeemedDate {
          date
          hour
          month
          year
        }
        redeemedId
        redemptionOptionMethod
        remarks
        rewardName
        rewardStatus
      }
      status
    }
    }
  `;

@Injectable({
  providedIn: 'root'
})
export class QueryGetUserRewardDetailsGQL extends Apollo.Query<QueryGetUserRewardDetailsQuery, QueryGetUserRewardDetailsQueryVariables> {
  document = QueryGetUserRewardDetailsDocument;

}



  export const QueryGetUserResultDocument = gql`
  query getUserResultDetails($resultId:UUID) {
    getUserResultDetails(resultId:$resultId){
      code
    message
    object {
      assessment
      competitionCategory
      competitionDate
      competitionName
      competitionLevel
      competitionType
      countryCompeting
      emailAddress
      eventName
      fullName
      noOfParticipants
      positionNumber
      result
      resultId
      sportName
      status
      statusDescription
    }
    status
    }
    }
  `;

@Injectable({
  providedIn: 'root'
})
export class QueryGetUserResultDetails extends Apollo.Query<QueryGetUserResultDetailsQuery, QueryGetUserResultDetailsQueryVariables> {
  document = QueryGetUserResultDocument;

}
  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListEventTrainingForAdminGQL extends Apollo.Query<QueryGetListEventTrainingForAdminQuery, QueryGetListEventTrainingForAdminQueryVariables> {
    document = QueryGetListEventTrainingForAdminDocument;

  }
export const QueryGetTotalAthleteDocument = gql`
    query queryGetTotalAthlete($status: String) {
  getTotalAthlete(status: $status)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetTotalAthleteGQL extends Apollo.Query<QueryGetTotalAthleteQuery, QueryGetTotalAthleteQueryVariables> {
    document = QueryGetTotalAthleteDocument;

  }

  export const QueryGetTotalUsersDocument = gql`
    query queryGetTotalUsers($status: String) {
      getTotalUsers(status: $status)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetTotalUsersGQL extends Apollo.Query<QueryGetTotalUsersQuery, QueryGetTotalUsersQueryVariables> {
    document = QueryGetTotalUsersDocument;

  }
export const QueryGetTotalEventDocument = gql`
    query queryGetTotalEvent($filter: DashboardFilter) {
  getTotalEvent(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetTotalEventGQL extends Apollo.Query<QueryGetTotalEventQuery, QueryGetTotalEventQueryVariables> {
    document = QueryGetTotalEventDocument;

  }
export const QueryGetTotalTrainingDocument = gql`
    query queryGetTotalTraining($filter: DashboardFilter) {
  getTotalTraining(filter: $filter)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetTotalTrainingGQL extends Apollo.Query<QueryGetTotalTrainingQuery, QueryGetTotalTrainingQueryVariables> {
    document = QueryGetTotalTrainingDocument;

  }
export const QueryGetEventForAdminDocument = gql`
    query queryGetEventForAdmin($id: UUID) {
  getEventForAdmin(id: $id) {
    id
    eventName
    eventDateObj {
      year
      month
      date
      hour
      minute
      second
      timeZone
    }
    fromRegDate {
      date
      hour
      millisecond
      minute
      month
      second
      timeZone
      year
    }
    toRegDate{
      date
      hour
      millisecond
      minute
      month
      second
      timeZone
      year
    }
    eventImageUrl
    fromTimeObj {
      year
      month
      date
      hour
      minute
      second
      timeZone
    }
    eventRoles {
      id
      roleCode
      roleName
    }

    sports{

      id
    }
    toTimeObj {
      year
      month
      date
      hour
      minute
      second
      timeZone
    }

    eventType
    description
    cancelReason
    status
    emailNotification
    venue
    externalLink
    totalVacancy
    available
    registered
    organiser
    isPublished

    additionalInfoList {
      id
      question
      questionType
      optionValue
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetEventForAdminGQL extends Apollo.Query<QueryGetEventForAdminQuery, QueryGetEventForAdminQueryVariables> {
    document = QueryGetEventForAdminDocument;

  }
export const QueryGetListEventAttendeeForAdminDocument = gql`
    query queryGetListEventAttendeeForAdmin($eventId: UUID, $pagingRequest: EventAttendeePagingRequest) {
  getListEventAttendeeForAdmin(eventId: $eventId, pagingRequest: $pagingRequest) {
    offset
    limit
    total
    data {
      registrationRole
      fullName
      phoneNumber
      userProfileId
      email
      event {
        additionalInfoList {
          question
          id
        }
              }
      additionalInfoList {
        answer
        questionId
      }
      emergencyContactName
      emergencyContactNumber
      isFeedbackCompleted
    }
  }
}


    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListEventAttendeeForAdminGQL extends Apollo.Query<QueryGetListEventAttendeeForAdminQuery, QueryGetListEventAttendeeForAdminQueryVariables> {
    document = QueryGetListEventAttendeeForAdminDocument;

  }
export const QueryGetListEventForAdminDocument = gql`
    query queryGetListEventForAdmin($pagingRequest: EventPagingRequest, $filter: EventFilter,$sportList:[UUID]) {
  getListEventForAdmin(pagingRequest: $pagingRequest, filter: $filter,sportList: $sportList) {
    data {
      id
      eventName
      eventImageUrl
      eventType
      description
      emailNotification
      status
      venue
      externalLink
      totalVacancy
      available
      registered
      eventDateObj {
        year
        month
        date
        hour
        minute
        second
        timeZone
      }
      fromRegDate {
        date
        hour
        millisecond
        minute
        month
        second
        timeZone
        year
      }
      toRegDate {
        date
        hour
        millisecond
        minute
        month
        second
        timeZone
        year
      }
      fromTimeObj {
        year
        month
        date
        hour
        minute
        second
        timeZone
      }
      toTimeObj {
        year
        month
        date
        hour
        minute
        second
        timeZone
      }
    }
    total
    offset
    limit
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListEventForAdminGQL extends Apollo.Query<QueryGetListEventForAdminQuery, QueryGetListEventForAdminQueryVariables> {
    document = QueryGetListEventForAdminDocument;

  }
export const QueryGetListEventStatusDocument = gql`
    query queryGetListEventStatus {
  getListEventStatus
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListEventStatusGQL extends Apollo.Query<QueryGetListEventStatusQuery, QueryGetListEventStatusQueryVariables> {
    document = QueryGetListEventStatusDocument;

  }
export const QueryGetListEventTypeDocument = gql`
    query queryGetListEventType {
  getListEventType {
    groupName
    eventTypeName
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListEventTypeGQL extends Apollo.Query<QueryGetListEventTypeQuery, QueryGetListEventTypeQueryVariables> {
    document = QueryGetListEventTypeDocument;

  }
export const MutationCreateEventDocument = gql`
    mutation mutationCreateEvent($event: EventInput) {
  createEvent(event: $event) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationCreateEventGQL extends Apollo.Mutation<MutationCreateEventMutation, MutationCreateEventMutationVariables> {
    document = MutationCreateEventDocument;

  }
export const MutationDeleteEventDocument = gql`
    mutation mutationDeleteEvent($id: UUID) {
  deleteEvent(id: $id) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteEventGQL extends Apollo.Mutation<MutationDeleteEventMutation, MutationDeleteEventMutationVariables> {
    document = MutationDeleteEventDocument;

  }
export const MutationPublishEventDocument = gql`
    mutation mutationPublishEvent($id: UUID) {
  publishEvent(id: $id) {
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationPublishEventGQL extends Apollo.Mutation<MutationPublishEventMutation, MutationPublishEventMutationVariables> {
    document = MutationPublishEventDocument;

  }
export const MutationPushEventDocument = gql`
    mutation mutationPushEvent($id: UUID) {
  pushEvent(id: $id) {
    status
    message
    object {
      id
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationPushEventGQL extends Apollo.Mutation<MutationPushEventMutation, MutationPushEventMutationVariables> {
    document = MutationPushEventDocument;

  }
export const MutationUnPublishEventDocument = gql`
    mutation mutationUnPublishEvent($id: UUID) {
  unPublishEvent(id: $id) {
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUnPublishEventGQL extends Apollo.Mutation<MutationUnPublishEventMutation, MutationUnPublishEventMutationVariables> {
    document = MutationUnPublishEventDocument;

  }
export const MutationUpdateEventDocument = gql`
    mutation mutationUpdateEvent($event: EventInput) {
  updateEvent(event: $event) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateEventGQL extends Apollo.Mutation<MutationUpdateEventMutation, MutationUpdateEventMutationVariables> {
    document = MutationUpdateEventDocument;

  }




  export const MutationUpdateUserCertificatesDocument = gql`
    mutation mutationUpdateUserCertificates($userCertificates: [UserCertificatesInput]) {
  updateUserCertificates(userCertificates: $userCertificates) {
    code
    status
    message
    object {
      id
      certificateDateObject {
        date
        month
        year
      }
      certificateName
      issuingAuthority
      place
      userAccountId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserCertificatesGQL extends Apollo.Mutation<MutationUpdateUserCertificates, MutationUpdateUserCertificatesMutationVariables> {
    document =  MutationUpdateUserCertificatesDocument;
  }

  export const  QueryGetUserCertificatesDocument = gql`
    query queryGetUserCertificates($userAccountId: UUID) {
  getUserCertificates(userAccountId: $userAccountId) {
    certificateDateObject {
      date
      month
      year
    }
    certificateName
    issuingAuthority
    place
    userAccountId
    id

  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetUserCertificatesGQL extends Apollo.Query<QueryGetUserCertificatesQuery, QueryGetUserCertificatesQueryVariables> {
    document =  QueryGetUserCertificatesDocument;
  }

  export const MutationUpdateUserCoursesDocument = gql`
    mutation mutationUpdateUserCourses($userCourses: [UserCoursesInput]) {
  updateUserCourses(userCourses: $userCourses) {
    status
    code
    message
    object {
      id
      courseCompletedDateObject {
        date
        month
        year
      }
      courseName
      issuingAuthority
      place
      userAccountId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserCoursesGQL extends Apollo.Mutation<MutationUpdateUserCoursesMutation, MutationUpdateUserCoursesMutationVariables> {
    document =MutationUpdateUserCoursesDocument;

  }
  export const  QueryGetUserCoursesDocument = gql`
  query queryGetUserCourses($userAccountId: UUID) {
getUserCourses(userAccountId: $userAccountId) {
  courseCompletedDateObject {
    date
    month
    year
  }
    courseName
    id
    place
    issuingAuthority
    userAccountId

}
}
  `;

@Injectable({
  providedIn: 'root'
})
export class QueryGetUserCoursesGQL extends Apollo.Query<QueryGetUserCoursesQuery, QueryGetUserCoursesQueryVariables> {
  document =  QueryGetUserCoursesDocument;
}

export const MutationUpdateUserRecordsForAdminDocument = gql`
    mutation mutationUpdateUserRecordsForAdmin($userRecords: UserRecordsInput) {
      updateUserRecordsForAdmin(userRecords: $userRecords) {
        code
        status
        message
        object {
          approved
          countryObject {
            code
            displaySequence
            id
            name
          }
          userAccountId
          numberOfClassification
          eventDate
          eventName
          id
        }
        }
    }
    `;

@Injectable({
  providedIn: 'root'
})
export class MutationUpdateUserRecordsForAdminGQL extends Apollo.Mutation<MutationUpdateUserRecordsForAdminMutation, MutationUpdateUserRecordsForAdminMutationVariables> {
  document =MutationUpdateUserRecordsForAdminDocument;

}

export const  QueryGetUserRecordsOfUserForAdminDocument = gql`
query queryGetUserRecordsOfUserForAdmin($id: UUID,$pagingRequest: ReportsPagingRequest) {
  getUserRecordsOfUserForAdmin(id: $id, pagingRequest: $pagingRequest) {
    data {
      approved
      countryObject {
        code
        displaySequence
        id
        name
      }
      eventDate
      eventName
      status
      plainStatus
      statusDescription
      id
      numberOfClassification
      userAccountId
    }
    limit
    offset
    total
  }
}

`;
@Injectable({
  providedIn: 'root'
})
export class QueryGetUserRecordsOfUserForAdminGQL extends Apollo.Query<QueryGetUserRecordsOfUserForAdminQuery, QueryGetUserRecordsOfUserForAdminQueryVariables> {
  document =  QueryGetUserRecordsOfUserForAdminDocument;
}
export const  QueryGetUserRecordsDocument = gql`
query queryGetUserRecordsDocument($id: UUID) {
  getUserRecords(id: $id) {
    approved
    countryObject {
      code
      displaySequence
      id
      name
    }
    eventDate
    eventName
    status
    statusDescription
    id
    numberOfClassification
    userAccountId

  }
}

`;
@Injectable({
  providedIn: 'root'
})
export class QueryGetUserRecordsGQL extends Apollo.Query<QueryGetUserRecordsQuery, QueryGetUserRecordsQueryVariables> {
  document =  QueryGetUserRecordsDocument;
}

// export const  QueryGetEventFeedbackDocument = gql`
// query queryGetEventFeedbackDocument($eventId: UUID) {
//   getEventFeedback(eventId: $eventId) {
//     id
//     eventId
//     isFeedbackCompleted
//     points
//     userProfileId
//     answerFeedbackInfoList {
//       freeTextValue
//       numberValue
//       questionCode
//       feedbackOptionValueList {
//         comment
//         value
//         displaySequence
//         hasComment
//         isChecked
//       }
//     }
//   }
// }

// `;

// @Injectable({
//   providedIn: 'root'
// })
// export class QueryGetEventFeedbackGQL extends Apollo.Query<QueryGetEventFeedbackQuery, QueryGetEventFeedbackQueryVariables> {
//   document =  QueryGetEventFeedbackDocument;
// }

export const QueryGetEventFeedbackChartDataDocument = gql`
query queryGetEventFeedbackChartData($eventId: UUID) {
  getEventFeedbackChartData(eventId: $eventId) {
    questionCode
    questionText
    totalNumberOfResponse
    feedBackStatistics {
      isNumber
      numberValue
      textValue
      count
    }
  }
}

`;

@Injectable({
  providedIn: 'root'
})
export class QueryGetEventFeedbackChartDataGQL extends Apollo.Query<QueryGetEventFeedbackChartDataQuery, QueryGetEventFeedbackChartDataQueryVariables> {
  document =  QueryGetEventFeedbackChartDataDocument;
}

export const  QueryGetListEventFeedbackDocument = gql`
query queryGetListEventFeedback($eventId: UUID, $pagingRequest: EventFeedbackPagingRequest) {
  getListEventFeedback(eventId: $eventId, pagingRequest: $pagingRequest) {
    data {
      updatedDate {
        date
        hour
        millisecond
        minute
        month
        second
        timeZone
        year
      }
      answerFeedbackInfoList {
        freeTextValue
        numberValue
        questionCode
        feedbackOptionValueList {
          hasComment
          comment
          isChecked
          value
        }
      }
      eventId
      event {
        eventName
      }
      userProfile {
        roleCode
        fullName
        userType {
          name
        }
      }
      registeredRole
    }
    limit
    offset
    total

  }
}

`;
@Injectable({
    providedIn: 'root'
  })
  export class QueryGetListEventFeedbackGQL extends Apollo.Query<QueryGetListEventFeedbackQuery, QueryGetListEventFeedbackQueryVariables> {
    document =  QueryGetListEventFeedbackDocument;
  }
  export const QueryGetAssessmentBySportDocument = gql`
  query queryGetAssessmentBySport($sportId: UUID) {
    getAssessmentBySport(sportId: $sportId) {
      id
      name
    }
  }
  `;
@Injectable({
providedIn: 'root'
})
export class QueryGetAssessmentBySportGQL extends Apollo.Query<QueryGetAssessmentBySportQuery,QueryGetAssessmentBySportVariables> {
document = QueryGetAssessmentBySportDocument;
}

export const MutationChangeStatusArticleDocument = gql`
    mutation mutationChangeStatusArticle($id: UUID, $status: String) {
  changeStatusArticle(id: $id, status: $status) {
    status
    code
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationChangeStatusArticleGQL extends Apollo.Mutation<MutationChangeStatusArticleMutation, MutationChangeStatusArticleMutationVariables> {
    document = MutationChangeStatusArticleDocument;

  }
export const MutationCreateArticleDocument = gql`
    mutation mutationCreateArticle($article: ArticleInput) {
  createArticle(article: $article) {
    status
    code
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationCreateArticleGQL extends Apollo.Mutation<MutationCreateArticleMutation, MutationCreateArticleMutationVariables> {
    document = MutationCreateArticleDocument;

  }

  export const MutationUpdateUserAccessDocument = gql`
    mutation mutationUpdateUserAccess($userAccessData: userAccessInput) {
      updateUserAccess(userAccessData: $userAccessData) {
    status
    code
    message
    object
  }
}
    `;
  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserAccessGQL extends Apollo.Mutation<MutationUpdateUserAccessMutation, MutationUpdateUserAccessMutationVariables> {
    document = MutationUpdateUserAccessDocument;

  }
  export const MutationCreateUserAccessDocument = gql`
  mutation mutationCreateUserAccess($userAccessData: userAccessInput) {
    createUserAccess(userAccessData: $userAccessData) {
  status
  code
  message
  object
}
}
  `;
  @Injectable({
    providedIn: 'root'
  })
  export class MutationCreateUserAccessGQL extends Apollo.Mutation<MutationCreateUserAccessMutation, MutationCreateUserAccessMutationVariables> {
    document = MutationCreateUserAccessDocument;

  }
export const MutationDeleteArticleDocument = gql`
    mutation mutationDeleteArticle($id: UUID) {
    deleteArticle(id: $id) {
    status
    code
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteArticleGQL extends Apollo.Mutation<MutationDeleteArticleMutation, MutationDeleteArticleMutationVariables> {
    document = MutationDeleteArticleDocument;

  }
  export const MutationDeleteUserAccessDocument = gql`
    mutation mutationDeleteUserAccess($id: UUID) {
      deleteUserAccess(id: $id) {
    status
    code
    message
    object
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteUserAccessGQL extends Apollo.Mutation<MutationDeleteUserAccessMutation, MutationDeleteUserAccessMutationVariables> {
    document = MutationDeleteUserAccessDocument;

  }
export const QueryGetArticleDocument = gql`
    query queryGetArticle($id: UUID) {
  getArticle(id: $id) {
    id
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetArticleGQL extends Apollo.Query<QueryGetArticleQuery, QueryGetArticleQueryVariables> {
    document = QueryGetArticleDocument;

  }
export const QueryGetArticleForAdminDocument = gql`
    query queryGetArticleForAdmin($id: UUID) {
  getArticleForAdmin(id: $id) {
    id
    title
    coverPhotoUrl
    description
    duration
    source
    uploadedImageurl
    category
    shortDescription
    type
    linkToWebsite
    surveyPoints
    author {
      fullName
    }
    dateObj {
      year
      month
      date
      hour
      minute
      second
      timeZone
    }
    advStartDateObj {
      year
      month
      date
      hour
      minute
      second
      timeZone
    }
    advEndDateObj {
      year
      month
      date
      hour
      minute
      second
      timeZone
    }
    advPriority
    survey {
      id
      surveyAnswer
      surveyOptions
      surveyQuestion
    }
    views
    latestUpdatedObj {
      year
      month
      date
      hour
      minute
      second
    }
    status
    postTypes
  }
}
    `;

    @Injectable({
      providedIn: 'root'
    })
    export class QueryGetRewardsRedeemedListGQL extends Apollo.Query<QueryGetGetRewardsRedeemedListQuery, QueryGetRewardsRedeemedListQueryVariables> {
      document = QueryGetRewardsRedeemedListDocument;

    }
    export const QueryGetRewardsRedeemedListDocument = gql`
      query queryGetRewardsRedeemedList($rewardsId: UUID,$pagingRequest: RewardsPagingRequest) {
        getRewardsRedeemedList(rewardsId: $rewardsId,pagingRequest: $pagingRequest ) {
          data {
            emailAddress
            id
            mailingAddress
            postalCode
            redeemedDate {
              date
              month
              year
            }
            redeemedPoints
            redemptionOptionMethod
            remarks
            rewardsId
            rewardStatus
            userFullName
            userProfileId
          }
          limit
          offset
          pageIndex
          total
          totalPage
        }
      }

      `;

      @Injectable({
        providedIn: 'root'
      })
      export class QueryGetUsedUserPointListGQL extends Apollo.Query<QueryGetUsedUserPointListQuery, QueryGetUsedUserPointListQueryVariables> {
        document = QueryGetUsedUserPointListDocument;

      }
      export const QueryGetUsedUserPointListDocument = gql`
        query queryGetUsedUserPointList($userProfileId: UUID) {
          getUsedUserPointList(userProfileId: $userProfileId) {
            object {
              redeemedDate {
                year
                month
                date
                hour
                minute
                second
                timeZone
              }
              userPointsRedeemedList {
                emailAddress
                id
                mailingAddress
                postalCode
                redeemedDate {
                year
                month
                date
                hour
                minute
                second
                timeZone
                }
                redeemedPoints
                redemptionOption
                remarks
                rewardName
                rewardsId
                status
                userFullName
                userProfileId
              }
            }
            code
            message
            status
          }
        }
        `;

@Injectable({
  providedIn: 'root'
})
export class QueryGetRewardsListForAdminGQL extends Apollo.Query<QueryGetRewardsListForAdminQuery, QueryGetRewardsListForAdminQueryVariables> {
  document = QueryGetRewardsListForAdminDocument;

}
export const QueryGetRewardsListForAdminDocument = gql`
  query queryGetRewardsListForAdmin($pagingRequest: RewardsPagingRequest) {
  getRewardsListForAdmin(pagingRequest: $pagingRequest) {
    data {
      id
      imageUrl
      name
      points
      quantity
      endDateObj{
        year
        month
        date
        hour
        minute
        second
      }
      startDateObj{
        year
        month
        date
        hour
        minute
        second
      }

    }
    limit
    offset
    pageIndex
    total
    totalPage
  }
}

  `;


  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetRewardsListGQL extends Apollo.Query<QueryGetRewardsListQuery, QueryGetRewardsListQueryVariables> {
    document = QueryGetRewardsListDocument;

  }
  export const QueryGetRewardsListDocument = gql`
    query queryGetRewardsList($pagingRequest: RewardsPagingRequest) {
    getRewardsList(pagingRequest: $pagingRequest) {
      data {
        id
        imageUrl
        name
        points
      }
      limit
      offset
      pageIndex
      total
      totalPage
    }
  }

    `;

    @Injectable({
      providedIn: 'root'
    })
    export class QueryGetRewardsDetailsGQL extends Apollo.Query<QueryGetRewardsDetailsQuery, QueryGetRewardsDetailsQueryVariables> {
      document = QueryGetRewardsDetailsDocument;

    }
    export const QueryGetRewardsDetailsDocument = gql`
      query queryGetRewardsDetails($rewardsId: UUID) {
        getRewardsDetails(rewardsId: $rewardsId) {
          code
          message
          object {
            description
            id
            imageUrl
            name
            points
            quantity
            endDateObj{
              year
              month
              date
              hour
              minute
              second
            }
            startDateObj{
              year
              month
              date
              hour
              minute
              second
            }
          }
          status
        }
      }

      `;
@Injectable({
    providedIn: 'root'
  })
  export class QueryGetArticleForAdminGQL extends Apollo.Query<QueryGetArticleForAdminQuery, QueryGetArticleForAdminQueryVariables> {
    document = QueryGetArticleForAdminDocument;

  }
export const QueryGetListArticleForAdminDocument = gql`
    query queryGetListArticleForAdmin($pagingRequest: ArticlePagingRequest, $articleFilter: ArticleFilter) {
  getListArticleForAdmin(pagingRequest: $pagingRequest, articleFilter: $articleFilter) {
    offset
    limit
    total
    data {
      id
      title
      coverPhotoUrl
      description
      views
      type
      author {
        username
        fullName
      }
      status
      latestUpdatedObj {
        year
        month
        date
        hour
        minute
        second
      }
      createdDateObj {
        year
        month
        date
        hour
        minute
        second
        timeZone
      }
      publishDateObj {
        year
        month
        date
        hour
        minute
        second

      }
      postTypes
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListArticleForAdminGQL extends Apollo.Query<QueryGetListArticleForAdminQuery, QueryGetListArticleForAdminQueryVariables> {
    document = QueryGetListArticleForAdminDocument;

  }
  export const QueryGetListUserAccessForAdminDocument = gql`
    query queryGetListUserAccessForAdmin($pagingRequest: UserAccessPagingRequest) {
      getListUserAccessForAdmin(pagingRequest: $pagingRequest) {
        limit
        offset
        pageIndex
        total
        totalPage
    data {
      groupName
      id
      access
      modulesList {
        id
        name
      }
      sportList {
        id
        name
      }
      userProfileList {
        fullName
        id
        userAccountId
      }




    }
  }
}
    `;
   @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserAccessForAdminGQL extends Apollo.Query<QueryGetListUserAccessForAdminQuery, QueryGetListUserAccessForAdminQueryVariables> {
    document = QueryGetListUserAccessForAdminDocument;

  }

  export const QueryGetUserProfileListForAdminDocument = gql`
  query queryGetUserProfileListForAdmin($pagingRequest: UserPagingRequest) {
    getUserProfileListForAdmin(pagingRequest: $pagingRequest) {
      limit
      offset
      pageIndex
      total
      totalPage
  data {

    id
    fullName
    email




  }
}
}
  `;
  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetUserProfileListForAdminGQL extends Apollo.Query<QueryGetUserProfileListForAdminQuery, QueryGetUserProfileListForAdminQueryVariables> {
    document = QueryGetUserProfileListForAdminDocument;

  }


export const MutationPublishArticleDocument = gql`
    mutation mutationPublishArticle($id: UUID) {
  publishArticle(id: $id) {
    status
    code
    message
    object {
      id
    }
  }
}
`;


@Injectable({
  providedIn: 'root'
})
export class QueryGetUserProfileListForGroupAdminGQL extends Apollo.Query<QueryGetUserProfileListForGroupAdminQuery, QueryGetUserProfileListForGroupAdminQueryVariables> {
  document = QueryGetUserProfileListForGroupAdminDocument;

}

export const QueryGetUserProfileListForGroupAdminDocument = gql`
query queryGetUserProfileListForGroupAdmin($pagingRequest: UserPagingRequest,$groupId : UUID) {
  getUserProfileListForGroupAdminV2(pagingRequest: $pagingRequest,groupId : $groupId) {
    limit
    offset
    pageIndex
    total
    totalPage
data {
  checked
  email
  groupID
  name
  userID
}
}
}
`;


  @Injectable({
    providedIn: 'root'
  })
  export class MutationPublishArticleGQL extends Apollo.Mutation<MutationPublishArticleMutation, MutationPublishArticleMutationVariables> {
    document = MutationPublishArticleDocument;

  }
export const MutationUpdateArticleDocument = gql`
    mutation mutationUpdateArticle($article: ArticleInput) {
  updateArticle(article: $article) {
    status
    code
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateArticleGQL extends Apollo.Mutation<MutationUpdateArticleMutation, MutationUpdateArticleMutationVariables> {
    document = MutationUpdateArticleDocument;

  }
export const MutationChangeStatusSportDocument = gql`
    mutation mutationChangeStatusSport($id: UUID, $status: String) {
  changeStatusSport(id: $id, status: $status) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationChangeStatusSportGQL extends Apollo.Mutation<MutationChangeStatusSportMutation, MutationChangeStatusSportMutationVariables> {
    document = MutationChangeStatusSportDocument;

  }
export const MutationCreateSportArticleDocument = gql`
    mutation mutationCreateSportArticle($article: ArticleInput, $classificationIds: [UUID],$assessments: [String]) {
  createSportArticle(article: $article, classificationIds: $classificationIds, assessments: $assessments) {
    code
    status
    message
    object {
      id
      articleId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationCreateSportArticleGQL extends Apollo.Mutation<MutationCreateSportArticleMutation, MutationCreateSportArticleMutationVariables> {
    document = MutationCreateSportArticleDocument;

  }
export const MutationDeleteSportDocument = gql`
    mutation mutationDeleteSport($id: UUID) {
  deleteSport(id: $id) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteSportGQL extends Apollo.Mutation<MutationDeleteSportMutation, MutationDeleteSportMutationVariables> {
    document = MutationDeleteSportDocument;

  }
  export const MutationDeleteUserRecordsDocument = gql`
  mutation mutationDeleteUserRecords($id: UUID) {
deleteUserRecords(id: $id) {
  code
  status
  message
  object {
    approved
    countryObject {
      code
      displaySequence
      id
      name
    }
    eventDate
    eventName
    id
    numberOfClassification
    userAccountId
  }
}
}
`;
  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteUserRecordsGQL extends Apollo.Mutation<MutationDeleteUserRecordsMutation, MutationDeleteUserRecordsMutationVariables> {
    document = MutationDeleteUserRecordsDocument;
  }

  export const MutationApproveUserRecordsDocument = gql`
  mutation mutationApproveUserRecordsDocument($id: UUID, $status: String, $statusDescription: String) {
approveUserRecords(id: $id, status: $status, statusDescription: $statusDescription) {
  code
  status
  message
  object {
    approved
    countryObject {
      code
      displaySequence
      id
      name
    }
    eventDate
    eventName
    status
    statusDescription
    id
    numberOfClassification
    userAccountId
  }
}
}
`;
  @Injectable({
    providedIn: 'root'
  })
  export class MutationApproveUserRecordsGQL extends Apollo.Mutation<MutationApproveUserRecordsMutation, MutationApproveUserRecordsMutationVariables> {
    document = MutationApproveUserRecordsDocument;
  }

export const QueryGetListSportDocument = gql`
    query queryGetListSport {
  getListSport {
    id
    name
    status
    athleteCount
    coachCount
    article {
      id
    }
    assessments {
      code
      id
      name
    }
    imageUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListSportGQL extends Apollo.Query<QueryGetListSportQuery, QueryGetListSportQueryVariables> {
    document = QueryGetListSportDocument;

  }
  export const QueryGetListResultsEventDocument = gql`
  query queryGetListUserResultsEvent($statisticsInput: statisticsInput){
    getListUserResultsEvent(statisticsInput: $statisticsInput)

}
  `;

@Injectable({
  providedIn: 'root'
})
export class QueryGetListResultsEventGQL extends Apollo.Query<QueryGetListUserResultsEventQuery, QueryGetListUserResultsEventQueryVariables> {
  document = QueryGetListResultsEventDocument;

}

export const QueryGetListUserResultsAthletesAdminDocument = gql`
query queryGetListUserResultsAthletesAdmin($statisticsInput: statisticsInput){
  getListUserResultsAthletesAdmin(statisticsInput: $statisticsInput) {
    id
    fullName
  }

}
`;

@Injectable({
providedIn: 'root'
})
export class QueryGetListUserResultsAthletesAdminGQL extends Apollo.Query<QueryGetListUserResultsAthletesAdminQuery, QueryGetListUserResultsAthletesAdminQueryVariables> {
document = QueryGetListUserResultsAthletesAdminDocument;

}

export const QueryGetListUserResultsStatisticsDocument = gql`
query queryGetListUserResultsStatistics($statisticsInput: statisticsInput){
  getListUserResultsStatistics(statisticsInput: $statisticsInput) {
    userProfile {
      fullName
      id
    }
    userResultsStatistics {
      result
      competitionDate {
        date
        month
        year
      }
      event
    }
  }

}
`;

@Injectable({
providedIn: 'root'
})
export class QueryGetListUserResultsStatisticsGQL extends Apollo.Query<QueryGetListUserResultsStatisticsQuery, QueryGetListUserResultsStatisticsQueryVariables> {
document = QueryGetListUserResultsStatisticsDocument;

}
export const QueryGetListSportForAdminDocument = gql`
    query queryGetListSportForAdmin($classificationId: UUID, $filter: SportPagingRequest) {
  getListSportForAdmin(classificationId: $classificationId, filter: $filter) {
    id
    name
    status
    athleteCount
    coachCount
    article {
      id
      title
    }
    imageUrl
    classifications {
      id
    }
    impairments {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListSportForAdminGQL extends Apollo.Query<QueryGetListSportForAdminQuery, QueryGetListSportForAdminQueryVariables> {
    document = QueryGetListSportForAdminDocument;

  }
export const QueryGetListSportGroupDocument = gql`
    query queryGetListSportGroup {
  getListSportGroup
}
    `;
    export const QueryGetListCategoryForAdminDocument = gql`
    query queryGetListCategoryForAdmin ($pagingRequest: CategoryPagingRequest) {
    getListCategoryForAdmin (pagingRequest: $pagingRequest){
      limit
    offset
    pageIndex
    total
    totalPage
    data {
      category {
        description
        displaySequence
        id
        isPublish
        isShareApp
        name
      }
      categoryDetails {
        categoryDetails {
          description
          id
          imageId
          imageUrl
        }
        categoryLinks {
          id
          imageId
          imageUrl
          linkName
          linkUrl
        }
      }
    }
  }
}
    `;

    @Injectable({
      providedIn: 'root'
    })
    export class QueryGetListCategoryForAdminGQL extends Apollo.Query<QueryGetListCategoryForAdminQuery, QueryGetListCategoryForAdminQueryVariables> {
      document = QueryGetListCategoryForAdminDocument;

    }

      export const QueryGetCategorytDocument = gql`
    query queryGetCategory($id: UUID) {
  getCategory(id: $id) {

    category {
      description
      displaySequence
      id
      isPublish
      isShareApp
      name
    }
    categoryDetails {
      categoryDetails {
        description
        id
        imageId
        imageUrl
      }
      categoryLinks {
        id
        linkName
        linkUrl
      }
    }
  }
}
`;
@Injectable({
  providedIn: 'root'
})

export class QueryGetCategoryGQL extends Apollo.Query<QueryGetCategoryQuery, QueryGetCategoryQueryVariables> {
document = QueryGetCategorytDocument;

}
      export const MutationCreateCategoryDocument = gql`
      mutation mutationCreateCategory($category: categoryInput,$categoryDetails: [categoryDetailsInput]) {
        createCategory(category: $category,categoryDetails: $categoryDetails) {
          code
          status
          message
          object {
            id
            isPublish
            isShareApp
            name
              }
        }
      }
          `;
          @Injectable({
            providedIn: 'root'
          })

      export class MutationCreateCategoryGQL extends Apollo.Mutation<MutationCreateCategoryMutation, MutationCreateCategoryMutationVariables> {
        document = MutationCreateCategoryDocument;

      }




      export const MutationDeleteCategoryLinksDocument = gql`
      mutation mutationDeleteCategoryLinks($id: UUID) {
    deleteCategoryLinks(id: $id) {
      status
    code
    message
      }
}
      `;
    @Injectable({
      providedIn: 'root'
    })
      export class MutationDeleteCategoryLinksGQL extends Apollo.Mutation<MutationDeleteCategoryLinksMutation, MutationDeleteCategoryLinksMutationVariables> {
        document = MutationDeleteCategoryLinksDocument;
      }


      export const MutationDeleteCategoryDocument = gql`
      mutation mutationDeleteCategory($id: UUID) {
    deleteCategory(id: $id) {
      status
    code
    message
    object {
      id
    }
  }
}
      `;
    @Injectable({
      providedIn: 'root'
    })
      export class MutationDeleteCategoryGQL extends Apollo.Mutation<MutationDeleteCategoryMutation, MutationDeleteCategoryMutationVariables> {
        document = MutationDeleteCategoryDocument;
      }


      export const MutationUpdateCategoryDocument = gql`
    mutation mutationUpdateCategory($category: categoryInput, $categoryDetails:[categoryDetailsInput]) {
  updateCategory(category: $category,categoryDetails: $categoryDetails) {
    code
    status
    message
    object {
      description
      displaySequence
      id
      isPublish
      isShareApp
      name
    }
  }
}


    `;

      @Injectable({
        providedIn: 'root'
      })
      export class MutationUpdateCategoryGQL extends Apollo.Mutation<MutationUpdateCategoryMutation, MutationUpdateCategoryMutationVariables> {
        document = MutationUpdateCategoryDocument;
      }

      export const MutationUpdatePublishStatusDocument = gql`
      mutation mutationUpdatePublishStatus($id: UUID,$isPublish:Boolean) {
        updatePublishStatus(id: $id,isPublish:$isPublish) {
      status
      message


    }
  }
      `;


 @Injectable({
        providedIn: 'root'
      })
      export class MutationUpdatePublishStatusGQL extends Apollo.Mutation<MutationUpdatePublishStatusMutation, MutationUpdatePublishStatusMutationVariables> {
        document = MutationUpdatePublishStatusDocument;
      }

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListSportGroupGQL extends Apollo.Query<QueryGetListSportGroupQuery, QueryGetListSportGroupQueryVariables> {
    document = QueryGetListSportGroupDocument;

  }
export const QueryGetListSportSdscCardingDocument = gql`
    query queryGetListSportSdscCarding {
  getListSportSdscCarding
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListSportSdscCardingGQL extends Apollo.Query<QueryGetListSportSdscCardingQuery, QueryGetListSportSdscCardingQueryVariables> {
    document = QueryGetListSportSdscCardingDocument;

  }
export const QueryGetListSportSpexCardingDocument = gql`
    query queryGetListSportSpexCarding {
  getListSportSpexCarding
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListSportSpexCardingGQL extends Apollo.Query<QueryGetListSportSpexCardingQuery, QueryGetListSportSpexCardingQueryVariables> {
    document = QueryGetListSportSpexCardingDocument;

  }
export const QueryGetListUserSportLicenseLevelDocument = gql`
    query queryGetListUserSportLicenseLevel {
  getListUserSportLicenseLevel
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserSportLicenseLevelGQL extends Apollo.Query<QueryGetListUserSportLicenseLevelQuery, QueryGetListUserSportLicenseLevelQueryVariables> {
    document = QueryGetListUserSportLicenseLevelDocument;

  }
export const QueryGetSportDocument = gql`
    query queryGetSport($id: UUID) {
  getSport(id: $id) {
    id
    name
    status
    athleteCount
    coachCount
    article {
      id
      title
      coverPhotoUrl
      description
    }
    imageUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetSportGQL extends Apollo.Query<QueryGetSportQuery, QueryGetSportQueryVariables> {
    document = QueryGetSportDocument;

  }
export const QueryGetSportForAdminDocument = gql`
    query queryGetSportForAdmin($id: UUID) {
  getSportForAdmin(id: $id) {
    id
    name
    status
    athleteCount
    coachCount
    article {
      id
      title
      description
      coverPhotoUrl
      linkToWebsite

    }
    imageUrl
    classifications {
      id
    }
    impairments {
      id
    }
    assessments {
      code
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetSportForAdminGQL extends Apollo.Query<QueryGetSportForAdminQuery, QueryGetSportForAdminQueryVariables> {
    document = QueryGetSportForAdminDocument;

  }
export const MutationUpdateSportArticleDocument = gql`
    mutation mutationUpdateSportArticle($article: ArticleInput, $sportId: UUID, $classificationIds: [UUID],$assessments:[String]) {
  updateSportArticle(article: $article, sportId: $sportId, classificationIds: $classificationIds,assessments: $assessments) {
    code
    status
    message
    object {
      id
      articleId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateSportArticleGQL extends Apollo.Mutation<MutationUpdateSportArticleMutation, MutationUpdateSportArticleMutationVariables> {
    document = MutationUpdateSportArticleDocument;

  }
export const MutationCreateTrainingDocument = gql`
    mutation mutationCreateTraining($training: TrainingInput) {
  createTraining(training: $training) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationCreateTrainingGQL extends Apollo.Mutation<MutationCreateTrainingMutation, MutationCreateTrainingMutationVariables> {
    document = MutationCreateTrainingDocument;

  }
export const MutationCreateTrainingSessionDocument = gql`
    mutation mutationCreateTrainingSession($trainingSession: TrainingSessionInput) {
  createTrainingSession(trainingSession: $trainingSession) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationCreateTrainingSessionGQL extends Apollo.Mutation<MutationCreateTrainingSessionMutation, MutationCreateTrainingSessionMutationVariables> {
    document = MutationCreateTrainingSessionDocument;

  }
export const MutationDeleteTrainingDocument = gql`
    mutation mutationDeleteTraining($id: UUID) {
  deleteTraining(id: $id) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteTrainingGQL extends Apollo.Mutation<MutationDeleteTrainingMutation, MutationDeleteTrainingMutationVariables> {
    document = MutationDeleteTrainingDocument;

  }
export const MutationDeleteTrainingSessionDocument = gql`
    mutation mutationDeleteTrainingSession($id: UUID) {
  deleteTrainingSession(id: $id) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteTrainingSessionGQL extends Apollo.Mutation<MutationDeleteTrainingSessionMutation, MutationDeleteTrainingSessionMutationVariables> {
    document = MutationDeleteTrainingSessionDocument;

  }
export const MutationUpdateTrainingDocument = gql`
    mutation mutationUpdateTraining($training: TrainingInput) {
  updateTraining(training: $training) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateTrainingGQL extends Apollo.Mutation<MutationUpdateTrainingMutation, MutationUpdateTrainingMutationVariables> {
    document = MutationUpdateTrainingDocument;

  }
export const MutationUpdateTrainingSessionDocument = gql`
    mutation mutationUpdateTrainingSession($trainingSession: TrainingSessionInput) {
  updateTrainingSession(trainingSession: $trainingSession) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateTrainingSessionGQL extends Apollo.Mutation<MutationUpdateTrainingSessionMutation, MutationUpdateTrainingSessionMutationVariables> {
    document = MutationUpdateTrainingSessionDocument;

  }
export const QueryGetListTrainingForAdminDocument = gql`
    query queryGetListTrainingForAdmin($pagingRequest: TrainingPagingRequest, $filter: DashboardFilter,$sportList:[UUID]) {
  getListTrainingForAdmin(pagingRequest: $pagingRequest, filter: $filter,sportList:$sportList) {
    offset
    limit
    total
    data {
      id
      trainingName
      venue
      toDateObj {
        year
        month
        date
        hour
        minute
        second
      }
      fromDateObj {
        year
        month
        date
        hour
        minute
        second
      }
      numberOfSession
      frequency
      sportImageUrl
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListTrainingForAdminGQL extends Apollo.Query<QueryGetListTrainingForAdminQuery, QueryGetListTrainingForAdminQueryVariables> {
    document = QueryGetListTrainingForAdminDocument;

  }
export const QueryGetListTrainingSessionForAdminDocument = gql`
    query queryGetListTrainingSessionForAdmin($trainingSessionPagingRequest: TrainingSessionPagingRequest, $trainingId: UUID) {
  getListTrainingSessionForAdmin(pagingRequest: $trainingSessionPagingRequest, trainingId: $trainingId) {
    offset
    limit
    total
    data {
      id
      training {
        id
      }
      trainingId
      sessionNumber
      dateTrainingObj {
        year
        month
        date
        hour
        minute
        second
      }
      vacancy
      registered
      available
      coaches {
        id
        fullName
      }
      coachIds
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListTrainingSessionForAdminGQL extends Apollo.Query<QueryGetListTrainingSessionForAdminQuery, QueryGetListTrainingSessionForAdminQueryVariables> {
    document = QueryGetListTrainingSessionForAdminDocument;

  }

  export const QueryGetListAthleteAthendaceForAdminDocument = gql`
    query queryGetListAthleteAthendaceForAdmin($filter: TrainingSessionFilter,$pagingRequest: TrainingPagingRequest,$userProfileId: UUID) {
      getListAthleteAthendaceForAdmin(filter: $filter,pagingRequest: $pagingRequest,userProfileId: $userProfileId) {
    total
    offset
    limit
    data {
      trainingName
      trainingId
      attendancePercentage
      attendance {
        otherReason
        reasonType
        sessionId
        sessionNumber
        symbol
        checkInStatus
        trainingFromDateObj {
          date
          hour
          minute
          month
          second
          year
        }
        trainingToDateObj {
          date
          hour
          month
          second
          year
          minute
        }
      }
    }
  }
}
    `;

    @Injectable({
      providedIn: 'root'
    })
    export class QueryGetListAthleteAttendanceForAdminGQL extends Apollo.Query<QueryGetListAthleteAttendanceForAdminQuery, QueryGetListAthleteAttendanceForAdminQueryVariables> {
      document =QueryGetListAthleteAthendaceForAdminDocument;

    }
export const QueryGetTotalSessionsDocument = gql`
    query queryGetTotalSessions($id: UUID) {
  getTotalSessions(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetTotalSessionsGQL extends Apollo.Query<QueryGetTotalSessionsQuery, QueryGetTotalSessionsQueryVariables> {
    document = QueryGetTotalSessionsDocument;

  }
export const QueryGetTotalSessionsRealTimeDocument = gql`
    query queryGetTotalSessionsRealTime($training: TrainingInput) {
  getTotalSessionsRealTime(training: $training)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetTotalSessionsRealTimeGQL extends Apollo.Query<QueryGetTotalSessionsRealTimeQuery, QueryGetTotalSessionsRealTimeQueryVariables> {
    document = QueryGetTotalSessionsRealTimeDocument;

  }
export const QueryGetTrainingForAdminDocument = gql`
    query queryGetTrainingForAdmin($id: UUID) {
  getTrainingForAdmin(id: $id) {
    id
    trainingName
    venue
    toDateObj {
      year
      month
      date
      hour
      minute
      second
    }
    fromDateObj {
      year
      month
      date
      hour
      minute
      second
    }
    numberOfSession
    frequency
    status
    sport {
      id
    }
    sportId
    trainingDateTimes {
      weekday
      fromTimeObj {
        year
        month
        date
        hour
        minute
        second
      }
      toTimeObj {
        year
        month
        date
        hour
        minute
        second
      }
    }
    athleteManagers {
      id
    }
    athleteManagerIds
    coaches {
      id
    }
    coachIds
    athletes {
      id
    }
    athleteIds
    longitude
    latitude
    sportImageUrl
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetTrainingForAdminGQL extends Apollo.Query<QueryGetTrainingForAdminQuery, QueryGetTrainingForAdminQueryVariables> {
    document = QueryGetTrainingForAdminDocument;
  }


  export const QueryGetUserResultAdminDocument = gql`
  query queryGetUserResultAdmin($id: UUID, $pagingRequest:ResultsPagingRequest) {
    getUserResultAdmin(id: $id, pagingRequest: $pagingRequest ) {
      limit
    offset
    pageIndex
    total
    totalPage
    data {
      CompetitionCategory
      assessment {
        id
        name
      }
      competitionDate {
        date
        month
        year
      }
      competitionDateValue
      competitionLevel
      competitionName
      competitionType
      country {
        code
        displaySequence
        id
        name
      }
      event
      id
      noOfParticipants
      positionNumber
      result
      status
      plainStatus
      statusDescription
      userProfileId
      sport {
        id
        name
        selected
      }
    }
  }
}
  `;

@Injectable({
  providedIn: 'root'
})
export class QueryGetUserResultAdminGQL extends Apollo.Query<QueryGetUserResultAdminQuery, QueryGetUserResultAdminQueryVariables> {
  document = QueryGetUserResultAdminDocument;

}

export const QueryGetTrainingSessionForAdminDocument = gql`
    query queryGetTrainingSessionForAdmin($id: UUID) {
  getTrainingSessionForAdmin(id: $id) {
    id
    training {
      id
    }
    trainingId
    sessionNumber
    dateTrainingObj {
      year
      month
      date
      hour
      minute
      second
    }
    fromTimeObj {
      year
      month
      date
      hour
      minute
      second
    }
    toTimeObj {
      year
      month
      date
      hour
      minute
      second
    }
    vacancy
    registered
    available
    coaches {
      id
      fullName
    }
    coachIds
    athleteIds
    venue
    latitude
    longitude
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetTrainingSessionForAdminGQL extends Apollo.Query<QueryGetTrainingSessionForAdminQuery, QueryGetTrainingSessionForAdminQueryVariables> {
    document = QueryGetTrainingSessionForAdminDocument;

  }

  export const QueryGetListTrainingSessionAttendeeForAdminDocument = gql`
    query queryGetListTrainingSessionAttendeeForAdmin($filter: TrainingSessionFilter,$pagingRequest: TrainingPagingRequest,$trainingId: UUID) {
      getListTrainingSessionAttendeeForAdmin(filter: $filter,pagingRequest: $pagingRequest,trainingId: $trainingId) {
        limit
    offset
    total
    data {
      attendancePercentage
      userProfile {
        fullName
        id
      }
      attendance {
        checkInStatus
        otherReason
        sessionId
        reasonType
        sessionNumber
        symbol
        trainingFromDateObj {
          date
          hour
          month
          second
          year
          minute

        }
        }
    }
  }
}

    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListTrainingSessionAttendeeForAdminGQL extends Apollo.Query<QueryGetListTrainingSessionAttendeeForAdminQuery, QueryGetListTrainingSessionAttendeeForAdminQueryVariables> {
    document = QueryGetListTrainingSessionAttendeeForAdminDocument;

  }

export const MutationCreateUserAccountDocument = gql`
    mutation mutationCreateUserAccount($userAccount: userAccountInput) {
  createUserAccount(userAccount: $userAccount) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationCreateUserAccountGQL extends Apollo.Mutation<MutationCreateUserAccountMutation, MutationCreateUserAccountMutationVariables> {
    document = MutationCreateUserAccountDocument;

  }
export const MutationDeleteUserAccountDocument = gql`
    mutation mutationDeleteUserAccount($id: UUID) {
  deleteUserAccount(id: $id) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteUserAccountGQL extends Apollo.Mutation<MutationDeleteUserAccountMutation, MutationDeleteUserAccountMutationVariables> {
    document = MutationDeleteUserAccountDocument;

  }
export const QueryGetPermissionDocument = gql`
    query queryGetPermission {
  getPermission
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetPermissionGQL extends Apollo.Query<QueryGetPermissionQuery, QueryGetPermissionQueryVariables> {
    document = QueryGetPermissionDocument;

  }
export const MutationCreateUserDocumentDocument = gql`
    mutation mutationCreateUserDocument($userDocument: UserDocumentInput) {
  createUserDocument(userDocument: $userDocument) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationCreateUserDocumentGQL extends Apollo.Mutation<MutationCreateUserDocumentMutation, MutationCreateUserDocumentMutationVariables> {
    document = MutationCreateUserDocumentDocument;

  }
export const MutationDeleteUserDailyAidDocument = gql`
    mutation mutationDeleteUserDailyAid($id: UUID) {
  deleteUserDailyAid(id: $id) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteUserDailyAidGQL extends Apollo.Mutation<MutationDeleteUserDailyAidMutation, MutationDeleteUserDailyAidMutationVariables> {
    document = MutationDeleteUserDailyAidDocument;

  }
export const MutationDeleteUserDocumentFileDocument = gql`
    mutation mutationDeleteUserDocumentFile($id: UUID) {
  deleteUserDocumentFile(id: $id) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteUserDocumentFileGQL extends Apollo.Mutation<MutationDeleteUserDocumentFileMutation, MutationDeleteUserDocumentFileMutationVariables> {
    document = MutationDeleteUserDocumentFileDocument;

  }
export const MutationDeleteUserKinDocument = gql`
    mutation mutationDeleteUserKin($id: UUID) {
  deleteUserKin(id: $id) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteUserKinGQL extends Apollo.Mutation<MutationDeleteUserKinMutation, MutationDeleteUserKinMutationVariables> {
    document = MutationDeleteUserKinDocument;

  }
export const MutationDeleteUserMedicalConditionDocument = gql`
    mutation mutationDeleteUserMedicalCondition($id: UUID) {
  deleteUserMedicalCondition(id: $id) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationDeleteUserMedicalConditionGQL extends Apollo.Mutation<MutationDeleteUserMedicalConditionMutation, MutationDeleteUserMedicalConditionMutationVariables> {
    document = MutationDeleteUserMedicalConditionDocument;

  }
export const MutationExportUsersDocument = gql`
    mutation mutationExportUsers {
  exportUsers {
    status
    code
    message
    object
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationExportUsersGQL extends Apollo.Mutation<MutationExportUsersMutation, MutationExportUsersMutationVariables> {
    document = MutationExportUsersDocument;

  }
export const MutationUpdateListUserDailyAidDocument = gql`
    mutation mutationUpdateListUserDailyAid($userDailyAidList: [UserDailyAidInput]) {
  updateListUserDailyAid(userDailyAidList: $userDailyAidList) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateListUserDailyAidGQL extends Apollo.Mutation<MutationUpdateListUserDailyAidMutation, MutationUpdateListUserDailyAidMutationVariables> {
    document = MutationUpdateListUserDailyAidDocument;

  }
export const MutationUpdateListUserKinDocument = gql`
    mutation mutationUpdateListUserKin($userKins: [UserKinInput]) {
  updateListUserKin(userKins: $userKins) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateListUserKinGQL extends Apollo.Mutation<MutationUpdateListUserKinMutation, MutationUpdateListUserKinMutationVariables> {
    document = MutationUpdateListUserKinDocument;

  }
export const MutationUpdateListUserMedicalConditionDocument = gql`
    mutation mutationUpdateListUserMedicalCondition($userMedicalConditionList: [UserMedicalConditionInput]) {
  updateListUserMedicalCondition(userMedicalConditionList: $userMedicalConditionList) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateListUserMedicalConditionGQL extends Apollo.Mutation<MutationUpdateListUserMedicalConditionMutation, MutationUpdateListUserMedicalConditionMutationVariables> {
    document = MutationUpdateListUserMedicalConditionDocument;

  }
export const MutationUpdateListUserSportLicenseByAdminDocument = gql`
    mutation MutationUpdateListUserSportLicenseByAdmin($userAccountId: UUID, $userSportLicensesInput: UserSportLicensesInput) {
  updateListUserSportLicenseByAdmin(userAccountId: $userAccountId, userSportLicensesInput: $userSportLicensesInput) {
    code
    message
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateListUserSportLicenseByAdminGQL extends Apollo.Mutation<MutationUpdateListUserSportLicenseByAdminMutation, MutationUpdateListUserSportLicenseByAdminMutationVariables> {
    document = MutationUpdateListUserSportLicenseByAdminDocument;

  }
export const MutationUpdateUserContactDocument = gql`
    mutation mutationUpdateUserContact($userContact: UserContactInput) {
  updateUserContact(userContact: $userContact) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserContactGQL extends Apollo.Mutation<MutationUpdateUserContactMutation, MutationUpdateUserContactMutationVariables> {
    document = MutationUpdateUserContactDocument;

  }
export const MutationUpdateUserDocumentDocument = gql`
    mutation mutationUpdateUserDocument($userDocument: UserDocumentInput) {
  updateUserDocument(userDocument: $userDocument) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserDocumentGQL extends Apollo.Mutation<MutationUpdateUserDocumentMutation, MutationUpdateUserDocumentMutationVariables> {
    document = MutationUpdateUserDocumentDocument;

  }
export const MutationUpdateUserMedicalDocument = gql`
    mutation mutationUpdateUserMedical($userMedical: UserMedicalInput) {
  updateUserMedical(userMedical: $userMedical) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserMedicalGQL extends Apollo.Mutation<MutationUpdateUserMedicalMutation, MutationUpdateUserMedicalMutationVariables> {
    document = MutationUpdateUserMedicalDocument;

  }
export const MutationUpdateUserOrganisationDocument = gql`
    mutation mutationUpdateUserOrganisation($userOrganisation: UserOrganisationInput) {
  updateUserOrganisation(userOrganisation: $userOrganisation) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserOrganisationGQL extends Apollo.Mutation<MutationUpdateUserOrganisationMutation, MutationUpdateUserOrganisationMutationVariables> {
    document = MutationUpdateUserOrganisationDocument;

  }

  export const MutationUpdateUserOtherDocument= gql`
    mutation mutationUpdateUserOther($userOther: UserOtherInput) {
  updateUserOther(userOther: $userOther) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutateUpdateDashboardStatusGQL extends Apollo.Mutation<MutationUpdateDashboardStatusMutation, MutationUpdateDashboardStatusVariables> {
    document = MutateUpdateDashboardStatusDocument;

  }
export const MutateUpdateDashboardStatusDocument = gql`
    mutation mutationUpdateDashboardStatus($accountId: UUID,$dashBoardId: UUID,$description:String,$filter: String,$status: String) {
      updateDashboardStatus(accountId: $accountId,dashBoardId: $dashBoardId,description: $description,filter: $filter,status: $status) {
    code
    status
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserOtherGQL extends Apollo.Mutation<MutationUpdateUserOtherMutation, MutationUpdateUserOtherMutationVariables> {
    document = MutationUpdateUserOtherDocument;

  }
export const MutationUpdateUserProfileDocument = gql`
    mutation mutationUpdateUserProfile($userProfile: UserProfileInput) {
  updateUserProfile(userProfile: $userProfile) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserProfileGQL extends Apollo.Mutation<MutationUpdateUserProfileMutation, MutationUpdateUserProfileMutationVariables> {
    document = MutationUpdateUserProfileDocument;

  }
export const MutationUpdateUserRecommendedSportsByAdminDocument = gql`
    mutation MutationUpdateUserRecommendedSportsByAdmin($recommendedSports: UserRecommendedSportsInput, $userAccountId: UUID) {
  updateUserRecommendedSportsByAdmin(recommendedSports: $recommendedSports, userAccountId: $userAccountId) {
    code
    status
    message
    object {
      ... on UserRecommendedSport {
        imageUrl
        sportId
        sportName
        isRecommended
        isInterested
        isCompetitive
        sportSdscCarding
        sportGroup
        sportSpexCarding
        sportYear
        sportClassification
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationUpdateUserRecommendedSportsByAdminGQL extends Apollo.Mutation<MutationUpdateUserRecommendedSportsByAdminMutation, MutationUpdateUserRecommendedSportsByAdminMutationVariables> {
    document = MutationUpdateUserRecommendedSportsByAdminDocument;

  }
export const QueryDownloadUserDocumentFileDocument = gql`
    query queryDownloadUserDocumentFile {
  downloadUserDocumentFile
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryDownloadUserDocumentFileGQL extends Apollo.Query<QueryDownloadUserDocumentFileQuery, QueryDownloadUserDocumentFileQueryVariables> {
    document = QueryDownloadUserDocumentFileDocument;

  }
export const QueryGetListRecommendedSportByAdminDocument = gql`
    query QueryGetListRecommendedSportByAdmin($userAccountId: UUID, $pagingRequest: RecommendedSportPagingRequest) {
  getListRecommendedSportByAdmin(userAccountId: $userAccountId, pagingRequest: $pagingRequest) {
    offset
    limit
    pageIndex
    totalPage
    total
    data {
      imageUrl
      sportId
      sportName
      isInterested
      isCompetitive
      sportSdscCarding
      sportGroup
      sportSpexCarding
      sportYear
      sportClassification
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListRecommendedSportByAdminGQL extends Apollo.Query<QueryGetListRecommendedSportByAdminQuery, QueryGetListRecommendedSportByAdminQueryVariables> {
    document = QueryGetListRecommendedSportByAdminDocument;

  }
export const QueryGetListRoleDocument = gql`
    query queryGetListRole {
  getListRole {
    name
    code
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListRoleGQL extends Apollo.Query<QueryGetListRoleQuery, QueryGetListRoleQueryVariables> {
    document = QueryGetListRoleDocument;

  }
export const QueryGetListUserDailyAidDocument = gql`
    query queryGetListUserDailyAid($userAccountId: UUID) {
  getListUserDailyAid(userAccountId: $userAccountId) {
    id
    userAccountId
    aidType
    otherContent
    length
    width
    height
    weight
    diameter
    batteryType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserDailyAidGQL extends Apollo.Query<QueryGetListUserDailyAidQuery, QueryGetListUserDailyAidQueryVariables> {
    document = QueryGetListUserDailyAidDocument;

  }
export const QueryGetListUserDocumentDocument = gql`
    query queryGetListUserDocument($userAccountId: UUID) {
  getListUserDocument(userAccountId: $userAccountId) {
    id
    documentName
    userAccountId
    userDocumentFiles {
      id
      fileName
      fileUrl
      uploadedDateObj {
        year
        month
        date
        hour
        minute
        second
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserDocumentGQL extends Apollo.Query<QueryGetListUserDocumentQuery, QueryGetListUserDocumentQueryVariables> {
    document = QueryGetListUserDocumentDocument;

  }
export const QueryGetListUserKinDocument = gql`
    query queryGetListUserKin($userAccountId: UUID) {
  getListUserKin(userAccountId: $userAccountId) {
    id
    userAccountId
    relationship
    relationshipOther
    fullName
    isSameAddressContact
    address
    postalCode
    homeNumber
    mobileNumber
    emailAddress
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserKinGQL extends Apollo.Query<QueryGetListUserKinQuery, QueryGetListUserKinQueryVariables> {
    document = QueryGetListUserKinDocument;

  }
export const QueryGetListUserMedicalConditionDocument = gql`
    query queryGetListUserMedicalCondition($userAccountId: UUID) {
  getListUserMedicalCondition(userAccountId: $userAccountId) {
    id
    userAccountId
    classificationId
    medicalCondition
    medicalMemo
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserMedicalConditionGQL extends Apollo.Query<QueryGetListUserMedicalConditionQuery, QueryGetListUserMedicalConditionQueryVariables> {
    document = QueryGetListUserMedicalConditionDocument;

  }
export const QueryGetListUserProfileDocument = gql`
    query queryGetListUserProfile($users: String,$registeredUser: String,$volunteer: String,$roleCode: String, $pagingRequest: UserPagingRequest, $status: String,,$sportList:[UUID]) {
  getListUserProfile(roleCode: $roleCode,users: $users,volunteer: $volunteer, registeredUser: $registeredUser,pagingRequest: $pagingRequest, status: $status,,sportList:$sportList) {
    offset
    limit
    total
    data {
      classifier
      level
      accountCreatedDate {
        date
          hour
          month
          second
          year
          minute
      }
      userType {
        code
        name
      }
      role {
        code
        name
      }
      id
      sportIds
      userAccountId
      fullName
      contactNumber
      email
      userSportLicenseLevel
      photoProfileUrl
      nric
      isNrocMembership
      legalGuardian
      sports {
        id
        name

      }
      userRecommendedSports{
        sportId
        sportName
        isCompetitive
        isRecommended
        isInterested
      }
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserProfileGQL extends Apollo.Query<QueryGetListUserProfileQuery, QueryGetListUserProfileQueryVariables> {
    document = QueryGetListUserProfileDocument;
  }

  export const QueryGetListUserProfileForTrainingDocument = gql`
  query queryGetListUserProfileForTraining($roleCode: String, $pagingRequest: UserPagingRequest, $status: String,,$sportList:[UUID]) {
    getListUserProfileForTraining(roleCode: $roleCode, pagingRequest: $pagingRequest, status: $status,,sportList:$sportList) {
  offset
  limit
  total

  data {
    classifier
    level
       userType {
      code
      name
    }
    accountCreatedDate {
      date
      month
      year
    }
    id
    sportIds
    userAccountId
    fullName
    contactNumber
    email
    userSportLicenseLevel
    photoProfileUrl
    nric
    isNrocMembership
    legalGuardian
    sports {
      id
      name

    }
    userRecommendedSports{
      sportId
      sportName
      isCompetitive
      isRecommended
      isInterested
    }
    status
  }
}
}
  `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserProfileForTrainingGQL extends Apollo.Query<QueryGetListUserProfileForTrainingQuery, QueryGetListUserProfileForTrainingQueryVariables> {
    document = QueryGetListUserProfileForTrainingDocument;

  }
export const QueryGetListUserSportLicenseByAdminDocument = gql`
    query queryGetListUserSportLicenseByAdmin($userAccountId: UUID, $pageRequest: PagingRequest) {
  getListUserSportLicenseByAdmin(userAccountId: $userAccountId, pageRequest: $pageRequest) {
    offset
    limit
    pageIndex
    totalPage
    total
    data {
      id
      imageUrl
      sportId
      sport {
        name
        imageUrl
      }
      sportName
      level
      expiryDateObj {
        year
        month
        date
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetListUserSportLicenseByAdminGQL extends Apollo.Query<QueryGetListUserSportLicenseByAdminQuery, QueryGetListUserSportLicenseByAdminQueryVariables> {
    document = QueryGetListUserSportLicenseByAdminDocument;

  }
export const QueryGetUserContactDocument = gql`
    query queryGetUserContact($userAccountId: UUID) {
  getUserContact(userAccountId: $userAccountId) {
    id
    userAccountId
    address
    postalCode
    homeNumber
    mobileNumber
    emailAddress
    emergencyContactName
    emergencyContactNumber
    athleteProfileIdList
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetUserContactGQL extends Apollo.Query<QueryGetUserContactQuery, QueryGetUserContactQueryVariables> {
    document = QueryGetUserContactDocument;

  }

  export const QuerygetImportUserTemplate = gql`
    query getImportUserTemplate {
  getImportUserTemplate {
    code
    message
    object
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QuerygetImportUserTemplateGQL extends Apollo.Query<QueryGetImportUserQuery, QueryGetImportUserVariables> {
    document = QuerygetImportUserTemplate;

  }
export const QueryGetUserDocumentDocument = gql`
    query queryGetUserDocument($id: UUID) {
  getUserDocument(id: $id) {
    id
    documentName
    userAccountId
    userDocumentFiles {
      id
      fileName
      fileUrl
      uploadedDateObj {
        year
        month
        date
        hour
        minute
        second
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetUserDocumentGQL extends Apollo.Query<QueryGetUserDocumentQuery, QueryGetUserDocumentQueryVariables> {
    document = QueryGetUserDocumentDocument;

  }
export const QueryGetUserMedicalDocument = gql`
    query queryGetUserMedical($userAccountId: UUID) {
  getUserMedical(userAccountId: $userAccountId) {
    id
    userAccountId
    bloodType
    height
    weight
    dietaryPreference
    foodAllergy
    medicalMemo
    medicalCondition
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetUserMedicalGQL extends Apollo.Query<QueryGetUserMedicalQuery, QueryGetUserMedicalQueryVariables> {
    document = QueryGetUserMedicalDocument;

  }
export const QueryGetUserOrganisationDocument = gql`
    query queryGetUserOrganisation($userAccountId: UUID) {
  getUserOrganisation(userAccountId: $userAccountId) {
    id
    userAccountId
    occupation
    organisationName
    organisationAddress
    personInCharge
    designationPersonInCharge
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetUserOrganisationGQL extends Apollo.Query<QueryGetUserOrganisationQuery, QueryGetUserOrganisationQueryVariables> {
    document = QueryGetUserOrganisationDocument;

  }
export const QueryGetUserOtherDocument = gql`
    query queryGetUserOther($userAccountId: UUID) {
  getUserOther(userAccountId: $userAccountId) {
    id
    userAccountId
    topSize
    bottomSize
    shoeSize
  }
}
    `;

    export const QueryGetUserAccessGroupDetailsDocument = gql`
    query queryGetUserAccessGroupDetails($id: UUID) {
   getUserAccessGroupDetails(id: $id) {
    access
    groupName
    id
    modulesList {
      id
      name
    }
    sportList {
      id
      name
    }
    userProfileList {
      fullName
      id
      userAccountId
    }
  }
}
    `;
    @Injectable({
      providedIn: 'root'
    })
    export class QueryGetUserAccessGroupDetailsGQL extends Apollo.Query<QueryGetUserAccessGroupDetailsQuery, QueryGetUserAccessGroupDetailsQueryVariables> {
      document = QueryGetUserAccessGroupDetailsDocument;

    }
  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetUserOtherGQL extends Apollo.Query<QueryGetUserOtherQuery, QueryGetUserOtherQueryVariables> {
    document = QueryGetUserOtherDocument;

  }
export const QueryGetUserProfileDocument = gql`
    query queryGetUserProfile($userAccountId: UUID) {
  getUserProfile(userAccountId: $userAccountId) {
    id
    fullName
    chineseName
    classifier
    level
    legalGuardian
    isMale
    nric
    roleCode
    sports {
      id
    }
    sportIds
    dateOfBirthObj {
      year
      month
      date
    }
    placeOfBirth {
      id
    }
    placeOfBirthId
    passport
    dateOfIssueObj {
      year
      month
      date
    }
    dateOfExpiryObj {
      year
      month
      date
    }
    nationalityId
    nationality {
      id
      name
    }
    raceId
    race {
      id
    }
    religionId
    religion {
      id
    }
    maritalStatus
    isNationalService
    isNrocMembership
    dateNsEnlistmentObj {
      year
      month
      date
    }
    dateNsCompletionObj {
      year
      month
      date
    }
    photoProfileUrl
    status
    lastModifiedDateObj {
      year
      date
      month
      hour
      minute
      second
      timeZone
    }
    lastModifiedBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QueryGetUserProfileGQL extends Apollo.Query<QueryGetUserProfileQuery, QueryGetUserProfileQueryVariables> {
    document = QueryGetUserProfileDocument;

  }
export const MutationRegisterDocument = gql`
    mutation mutationRegister($userAccount: userAccountInput) {
  register(userAccount: $userAccount) {
    code
    status
    message
    object {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MutationRegisterGQL extends Apollo.Mutation<MutationRegisterMutation, MutationRegisterMutationVariables> {
    document = MutationRegisterDocument;

  }
  export const MutationRemoveEventAttendanceByAdminDocument = gql`
  mutation mutationRemoveEventAttendanceByAdmin($actionType: AttendanceEventType,$eventAttendeeId:UUID,$eventId:UUID) {
  removeEventAttendanceByAdmin(actionType: $actionType,eventAttendeeId: $eventAttendeeId,eventId:$eventId) {
  status
    code
    message
}
}
  `;
  @Injectable({
    providedIn: 'root'
  })
  export class MutationRemoveEventAttendanceByAdminGQL extends Apollo.Mutation<MutationRemoveEventAttendanceByAdminMutation, MutationRemoveEventAttendanceByAdminMutationVariables> {
    document = MutationRemoveEventAttendanceByAdminDocument;
  }


  export const QueryDownloadEventFeedbackFileDocument = gql`
  query queryDownloadEventFeedbackFile($eventId: UUID ,$timeOffset: String) {
    downloadEventFeedbackFile(eventId: $eventId, timeOffset: $timeOffset)
}
  `;

@Injectable({
  providedIn: 'root'
})
export class QueryDownloadEventFeedbackFileeGQL extends Apollo.Query<QueryDownloadEventFeedbackFileQuery, QueryDownloadEventFeedbackFileVariables> {
  document = QueryDownloadEventFeedbackFileDocument;

}

export const QueryDownloadEventAttendanceFileDocument = gql`
query queryDownloadEventAttendanceFile($eventId: UUID ) {
  downloadEventAttendanceFile(eventId: $eventId)
}
`;

@Injectable({
  providedIn: 'root'
})
export class QueryDownloadEventAttendanceFileGQL extends Apollo.Query<QueryDownloadEventAttendanceFileQuery, QueryDownloadEventAttendanceFileVariables> {
  document = QueryDownloadEventAttendanceFileDocument;

}


export const QueryDownloadAllSessionAttendanceUrlDocument = gql`
query queryDownloadAllSessionAttendanceUrl($filter: TrainingSessionFilter,$trainingId: UUID, $trainingName: String) {
  downloadAllSessionAttendanceUrl(filter: $filter, trainingId: $trainingId,trainingName:$trainingName){
    code
    message
    object
    status
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class QueryDownloadAllSessionAttendanceUrlGQL extends Apollo.Query<QueryDownloadAllSessionAttendanceUrleQuery, QueryDownloadAllSessionAttendanceUrlVariables> {
  document = QueryDownloadAllSessionAttendanceUrlDocument;

}

export const QueryDownloadUserRecordCsvDocument = gql`
query queryDownloadUserRecordCsv($userId: UUID ) {
  downloadUserRecordCsv(userId: $userId) {
  code
  message
  object
  status
}
}
`;
@Injectable({
  providedIn: 'root'
})
export class QueryDownloadUserRecordCsvGQL extends Apollo.Query<QueryDownloadUserRecordCsvQuery, QueryDownloadUserRecordCsvVariables> {
  document = QueryDownloadUserRecordCsvDocument;

}

export const QueryDownloadRedemptionListDocument = gql`
query queryDownloadRedemptionList($rewardName: String,$rewardsId: UUID ) {
  downloadRedemptionList(rewardName: $rewardName,rewardsId: $rewardsId) {
  code
  message
  object
  status
}
}
`;
@Injectable({
  providedIn: 'root'
})
export class QueryDownloadRedemptionListGQL extends Apollo.Query<QueryDownloadRedemptionListQuery, QueryDownloadRedemptionListVariables> {
  document = QueryDownloadRedemptionListDocument;

}

export const QueryDownloadUserResultsCsvDocument = gql`
query queryDownloadUserResultsCsv($userProfileId: UUID ) {
  downloadUserResultsCsv(userProfileId: $userProfileId) {
  code
  message
  object
  status
}
}
`;
@Injectable({
  providedIn: 'root'
})
export class QueryDownloadUserResultsCsvGQL extends Apollo.Query<QueryDownloadUserResultsCsvQuery, QueryDownloadUserResultsCsvVariables> {
  document = QueryDownloadUserResultsCsvDocument;

}

export const MutationUpdateListUserMedicalConditionAdminDocument = gql`
  mutation mutationUpdateListUserMedicalConditionAdmin($userMedicalConditionList: [UserMedicalConditionInputAdmin]) {
   updateListUserMedicalConditionAdmin(userMedicalConditionList: $userMedicalConditionList) {
    code
    status
    message
    object {
      classificationId
      id
      medicalMemo
      medicalCondition
      userAccountId
    }
  }
}
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationUpdateListUserMedicalConditionAdminGQL extends Apollo.Mutation<MutationUpdateListUserMedicalConditionAdminMutation, MutationUpdateListUserMedicalConditionAdminMutationVariables> {
  document = MutationUpdateListUserMedicalConditionAdminDocument;
}

export const QueryGetListUserPointDocument = gql`
    query queryGetListUserPointAdmin($userProfileId: UUID) {
    getListUserPointAdmin(userProfileId: $userProfileId) {
      object {
        id
        points
        type
        receivedDateObj {
          year
          month
          date
          hour
          minute
          second
          timeZone

        }
      }

    }
  }
    `;
@Injectable({
  providedIn: 'root'
})
export class QueryGetListUserPointGQL extends Apollo.Query<QueryGetListUserPointQuery,QueryGetListUserPointVariables> {
  document = QueryGetListUserPointDocument;

}
export const QueryGetListEventPointsDocument = gql`
    query queryGetListEventPoints {
      getListEventPoints {
        eventType
        roleTypeList {
          eventType
          points
          roleType
        }
      }
  }
    `;
@Injectable({
  providedIn: 'root'
})
export class QueryGetListEventPointsGQL extends Apollo.Query<QueryGetListEventPointsQuery,QueryGetListEventPointsVariables> {
  document = QueryGetListEventPointsDocument;

}
export const MutationCreateUserPointDocument = gql`
  mutation mutationCreateUserPoint($userPoint: UserPointInput) {
  createUserPoint(userPoint: $userPoint) {
    status
    code
    message
     }
}
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationCreateUserPointGQL extends Apollo.Mutation<MutationCreateUserPointMutation, MutationCreateUserPointMutationVariables> {
  document = MutationCreateUserPointDocument;
}
export const MutationUpdateUserPointDocument = gql`
  mutation mutationUpdateUserPoint($userPoint: UserPointInput) {
    updateUserPoint(userPoint: $userPoint) {
    status
    code
    message
     }
}
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationUpdateUserPointGQL extends Apollo.Mutation<MutationUpdateUserPointMutation, MutationUpdateUserPointVariables> {
  document = MutationUpdateUserPointDocument;
}
export const MutationDeleteUserPointDocument = gql`
  mutation mutationDeleteUserPoint($userPointId: UUID!) {
    deleteUserPoint(userPointId: $userPointId) {
    status
    code
    message
     }
}
  `;


@Injectable({
  providedIn: 'root'
})
export class MutationDeleteUserPointGQL extends Apollo.Mutation<MutationDeleteUserPointMutation, MutationDeleteUserPointVariables> {
  document =MutationDeleteUserPointDocument;
}

export const MutationSaveUserRecordsForAdminDocument = gql`
  mutation mutationSaveUserRecordsForAdmin($userRecords: UserRecordsInput) {
    saveUserRecordsForAdmin(userRecords: $userRecords) {
      code
      status
      message
      object {
        id
        userAccountId
        eventName
        eventDate
        countryObject {
          id
          name
          code
          displaySequence
        }
        numberOfClassification
        approved
        status
        statusDescription
      }
    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationSaveUserRecordsForAdminGQL extends Apollo.Mutation< MutationSaveUserRecordsForAdminMutation,  MutationSaveUserRecordsForAdminVariables> {
  document = MutationSaveUserRecordsForAdminDocument;
}



export const MutationUpdateTrainingAttendanceDocument = gql`
  mutation mutationUpdateTrainingAttendance($trainingSessionAttendee: TrainingSessionAttendeeInputAdmin) {
    updateTrainingAttendance(trainingSessionAttendee: $trainingSessionAttendee) {
      code
      message
      object {
        checkInStatus
        fullName
        reasonOther
        reasonType
        trainingSession {
          trainingName
          id
          trainingId
          sessionNumber
          dateTrainingObj {
            date
            hour
            minute
            month
            year
            timeZone
          }
        }
        trainingSessionId
        userProfileId
        userProfile {
          id
          fullName
        }
      }
    }
  }

  `;
@Injectable({
  providedIn: 'root'
})
export class MutationUpdateTrainingAttendanceGQL extends Apollo.Mutation<MutationUpdateTrainingAttendanceMutation,  MutationUpdateTrainingAttendanceVariables> {
  document = MutationUpdateTrainingAttendanceDocument;
}


export const MutationUpdateUserRoleDocument = gql`
  mutation mutationUpdateUserRole($roleCode: String,$userAccountIdList: [UUID]) {
    updateUserRole(roleCode: $roleCode,userAccountIdList: $userAccountIdList) {
      code
      status
      message
       }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationUpdateUserRoleGQL extends Apollo.Mutation< MutationUpdateUserRoleMutation,  MutationUpdateUserRoleVariables> {
  document = MutationUpdateUserRoleDocument;
}

export const MutationSaveUserResultsForAdminDocument = gql`
  mutation mutationSaveUserResultsForAdmin($userResults: userResultsInput) {
    saveUserResultsForAdmin(userResults: $userResults) {
      code
      message
      status
    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationSaveUserResultsForAdminGQL extends Apollo.Mutation< MutationSaveUserResultsForAdminMutation,  MutationSaveUserResultsForAdminVariables> {
  document = MutationSaveUserResultsForAdminDocument;
}
export const MutationUpdateStatusUserResultsAdminDocument = gql`
  mutation mutationUpdateStatusUserResultsAdmin($resultStatus: StatusUpdateInput) {
    updateStatusUserResultsAdmin(resultStatus: $resultStatus) {
      code
    message
    status

    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationUpdateStatusUserResultsAdminGQL extends Apollo.Mutation< MutationUpdateStatusUserResultsAdminMutation,  MutationUpdateStatusUserResultsAdminVariables> {
  document = MutationUpdateStatusUserResultsAdminDocument;
}
export const MutationUpdateUserResultsAdminDocument = gql`
  mutation mutationUpdateUserResultsAdmin($userResults: userResultsUpdateInput) {
    updateUserResultsAdmin(userResults: $userResults) {
    code
    message
    status

    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationUpdateUserResultsAdminGQL extends Apollo.Mutation< MutationUpdateUserResultsAdminMutation,  MutationUpdateUserResultsAdminVariables> {
  document = MutationUpdateUserResultsAdminDocument;
}


export const MutationGenerateUserResultsCsvDocument = gql`
  mutation mutationGenerateUserResultsCsv($statisticsInput: statisticsInput,$imageFile:String) {
    generateUserResultsCsv(statisticsInput: $statisticsInput,imageFile:$imageFile)

  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationGenerateUserResultsCsvGQL extends Apollo.Mutation< MutationGenerateUserResultsCsvMutation,  MutationGenerateUserResultsCsvVariables> {
  document = MutationGenerateUserResultsCsvDocument;
}
export const MutationDeleteUserResultsAdminDocument = gql`
  mutation mutationDeleteUserResultsAdmin($id: UUID) {
    deleteUserResultsAdmin(id: $id) {
    code
    message
    status

    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationDeleteUserResultsAdminGQL extends Apollo.Mutation< MutationDeleteUserResultsAdminMutation,  MutationDeleteUserResultsAdminVariables> {
  document = MutationDeleteUserResultsAdminDocument;
}

export const MutationUpdateCategorySequenceDocument = gql`
  mutation mutationUpdateCategorySequence($category: categoryInput, $direction: String) {
    updateCategorySequence(category: $category,direction: $direction) {
      code
      message
      status
      object {
        description
        displaySequence
        id
        isPublish
        name
      }
    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationUpdateCategorySequenceGQL extends Apollo.Mutation<MutationUpdateCategorySequenceMutation,  MutationUpdateCategorySequenceVariables> {
  document = MutationUpdateCategorySequenceDocument;
}

export const MutationUpdateRewardsDocument = gql`
  mutation mutationUpdateRewards($rewards: RewardsInput) {
    updateRewards(rewards: $rewards) {
      code
      message
      status
      object {
        id
        imageUrl
        name
        points
        description
        quantity
        endDateObj {
          date
          month
          year
          hour
          minute
          second
        }
        startDateObj{
          date
          month
          year
          hour
          minute
          second
        }
      }
    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationUpdateRewardsGQL extends Apollo.Mutation<MutationUpdateRewardsMutation,  MutationUpdateRewardsVariables> {
  document = MutationUpdateRewardsDocument;
}


export const MutationCreateRewardsDocument = gql`
  mutation mutationCreateRewards($rewards: RewardsInput) {
    createRewards(rewards: $rewards) {
      code
      message
      status
      object {
        id
        imageUrl
        name
        points
        description
        quantity
        startDateObj {
          date
          month
          year
          hour
          minute
          second
        }
        endDateObj {
          date
          month
          year
          hour
          minute
          second
        }
      }
    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationCreateRewardsGQL extends Apollo.Mutation<MutationCreateRewardsMutation, MutationCreateRewardsMutationVariables> {
  document = MutationCreateRewardsDocument;
}

export const MutationDeleteRewardForAdminDocument = gql`
  mutation mutationDeleteRewardForAdmin($rewardId: UUID) {
    deleteRewardForAdmin(rewardId: $rewardId) {
      code
      message
      object {
        description
        id
        imageUrl
        name
        points
      }
      status
    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationDeleteRewardForAdminGQL extends Apollo.Mutation<MutationDeleteRewardForAdminMutation, MutationDeleteRewardForAdminMutationVariables> {
  document = MutationDeleteRewardForAdminDocument;
}

export const MutationUpdateRewardRedeemedStatusDocument = gql`
  mutation mutationUpdateRewardRedeemedStatus($redeemId: UUID,$remarks: String, $status: String) {
    updateRewardRedeemedStatus(redeemId: $redeemId,remarks:$remarks,status:$status) {
      code
    message
    object {
      emailAddress
      id
      mailingAddress
      postalCode
      redeemedDate {
        date
        month
        year
      }
      redeemedPoints
      redemptionOption
      remarks
      rewardsId
      status
      userFullName
      userProfileId
    }
    status
  }
}
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationUpdateRewardRedeemedStatusGQL extends Apollo.Mutation<MutationUpdateRewardRedeemedStatusMutation, MutationUpdateRewardRedeemedStatusMutationVariables> {
  document = MutationUpdateRewardRedeemedStatusDocument;
}

export const MutationCreateEventPointsDocument = gql`
  mutation mutationCreateEventPoints($eventPoints: EventPointsInput) {
    createEventPoints(eventPoints: $eventPoints) {
      code
      message
      status
    }
  }
  `;
@Injectable({
  providedIn: 'root'
})
export class MutationCreateEventPointsGQL extends Apollo.Mutation<MutationCreateEventPointsMutation, MutationCreateEventPointsMutationVariables> {
  document = MutationCreateEventPointsDocument;
}
