import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BreadCrumb} from '../../../modules/sites/sites.component';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService
{
  private breadcrumbs = new BehaviorSubject([]);
  currentBreadcrumbs = this.breadcrumbs.asObservable();
  selectedLabel: String;
  selectedValue: String;
   usedLabel: String;
  constructor()
  {
  }

  changeBreadcrumbs(router: Router, activatedRoute: ActivatedRoute)
  {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map(event => this.buildBreadCrumb(activatedRoute.root))
    ).subscribe(data =>
    {
      this.breadcrumbs.next(data);
    });
  }

  private buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadCrumb> = []): Array<BreadCrumb>
  {
    const category = route.snapshot.params['category'];


    let label = route.routeConfig ? route.routeConfig.data['breadcrumb'] : null;
    const pathTemp = route.routeConfig ? route.routeConfig.path : '';

    if (label === null && category) {
        label = this.labelByCode(category);
    }

    let path = pathTemp;
    if (route.snapshot.params) {
        for (var param in route.snapshot.params) {
          const keyParam = ':' + param;
          const valueParam = route.snapshot.params[param];
          path = path.replace(keyParam, valueParam);
        }
    }

    const nextUrl = `${url}${path}/`;

     this.selectedLabel = localStorage.getItem('selectedUserRole')
     this.usedLabel = localStorage.getItem('selectedUserRole')

//     if(localStorage.getItem('selectedUserRole') == 'technical_official')
//     {
// this.selectedLabel = 'Technical Official'
//     }
    switch (this.selectedLabel) {

    case 'TECHNICAL_OFFICIAL':
this.selectedValue='Technical Official'
        break;
          case 'ATHLETE':
this.selectedValue='Athletes'
        break;
          case 'VOLUNTEER':
this.selectedValue='Volunteer'
        break;
          case 'CAREGIVER':
this.selectedValue='caregiver'
        break;
          case 'ADMIN':
this.selectedValue='Administrator'
        break;
          case 'REGISTERED_USER':
this.selectedValue='Registered User'
        break;
          case 'COACH':
        this.selectedValue = 'Coaches'
          case 'CLASSIFIER':
        this.selectedValue = 'classifier'
          case 'ATHLETE_SUPPORT':
        this.selectedValue = 'Athletes Support Teaml'

break;
  case 'technical_official':
this.selectedValue='Technical Official'
break;
case 'admin':
  this.selectedValue= 'Administrator'
  break;
  case 'registered_user':
  this.selectedValue= 'Registered User'
  break;
  case 'athlete':
  this.selectedValue= 'Athletes'
  break;
  case 'caregiver':
  this.selectedValue= 'Caregiver'
  break;
  case 'coach':
  this.selectedValue= 'Coaches'
  break;
  case 'classifier':
  this.selectedValue= 'Classifier'
  break;
  case 'volunteer':
  this.selectedValue= 'Volunteer'
  break;
  case 'athlete_support':
  this.selectedValue= 'Athletes Support Team'
  break;
  case 'new':
    this.selectedValue = 'Registered Users'
    break;
}
    //   const breadcrumb = {
    //   label: label === 'athletes'
    //   ? this.selectedValue
    //   : label === 'users' && this.selectedValue === 'Registered Users' ? 'New Users' : label,
    //    url: nextUrl,

    // };
    const breadcrumb = {      label: label === 'athletes' ? this.selectedValue : label,       url: nextUrl,
    };

    const newBreadcrumbs = [...breadcrumbs, breadcrumb];
    if (route.firstChild)
    {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  private labelByCode(code: string) {
      switch (code) {
        case 'registered_user':
          return 'Registered Users';
        case 'athlete':
          return 'Athletes';
        case 'caregiver':
          return 'Caregivers';
        case 'coach':
          return 'Coaches';
        case 'sport_official':
          return 'Sports Officials';
        case 'athlete_support':
          return 'Athletes Support Team';
        case 'admin':
          return 'Administrator';
        case 'classifier':
          return 'Classifier';
        case 'technical_official':
           return 'Technical Official';
        case 'new':
           return 'New Users'
        case 'volunteer':
            return 'Volunteer';
        default:
          return null;
      }
  }

}
