import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingScreenService} from './loading-screen.service';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-loading-screen',
  template: `
    <div class="loading-screen-wrapper" *ngIf="loading">
      <div class="blockUI blockMsg blockPage"
           style="">
        <div class="m-blockui">
          <span>Please wait...</span>
          <span class="m-loader  m-loader--success m-loader--lg"></span>
        </div>
      </div>
    </div>
  `,
  styles:
    [
        `
        .loading-screen-wrapper {
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 1000;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.6);
          width: 100%;
          height: 100%;
          display: block;
          cursor: progress;
        }

        .blockUI {
          z-index: 1011;
          position: fixed;
          padding: 0;
          margin: 0;
          top: 50%;
          left: 50%;
          text-align: center;
          color: rgb(0, 0, 0);
          border: 0;
          cursor: wait;
          transform: translate(-50%, -50%);
        }

        .m-blockui {
          background: #fff;
          -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .1);
          -moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .1);
          box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .1);
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          -ms-border-radius: 4px;
          -o-border-radius: 4px;
          border-radius: 4px;
          display: table;
          table-layout: fixed;
        }


        .m-blockui > span {
          color: #6f727d;
          display: table-cell;
          vertical-align: middle;
          padding: 8px 20px 8px 15px;
          font-size: 1rem;
          font-weight: 400;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .m-loader:before {
          border-top-color: #dfe2ea;
          width: 1.4rem;
          height: 1.4rem;
          margin-top: -.7rem;
          margin-left: -1rem;
          border-top-width: 2px;
          border-right-width: 2px;
          content: '';
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          position: absolute;
          border-top: 2px solid #34bfa3;
          border-right: 2px solid transparent;
          border-radius: 50%;
          -webkit-animation: m-loader-rotate 0.6s linear infinite;
          animation: m-loader-rotate 0.6s linear infinite;
        }

        @keyframes m-loader-rotate {
          to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
          }
        }
      `
    ]
})
export class LoadingScreenComponent implements OnInit, OnDestroy
{
  loading: boolean = false;
  loadingSubscription: Subscription;

  constructor(private loadingScreenService: LoadingScreenService)
  {
  }

  ngOnInit()
  {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(
      debounceTime(200)
    ).subscribe((value: any) => this.loading = value);
  }

  ngOnDestroy()
  {
    this.loadingSubscription.unsubscribe();
  }

}
