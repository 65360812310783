import {Injectable} from '@angular/core';
import {
  AuthenticationResponse,
  MutationLoginAdminGQL,
  QueryGetCurrentUserProfileGQL,
  UserAccountInput,
  UserProfile,
  MutationRequestChangPasswordGQL,
  MutationChangePasswordGQL,
  MutationCheckTokenGQL,
  MutationRegisterGQL, QueryGetPermissionGQL, QueryGetAccessDetailsByLoggedInUserGQL,
} from '../../../core/modules/graphql/generated/graphql';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import * as _ from 'lodash';

export interface Type {
  name : string,
  value:string
}
@Injectable({
  providedIn: 'root'
})


export class AuthService
{
moduleList : any []=[];
  constructor(
    private queryGetAccessDetailsByLoggedInUserGQL:QueryGetAccessDetailsByLoggedInUserGQL,
    private mutationLoginAdminGQL: MutationLoginAdminGQL,
    private queryGetCurrentUserProfileGQL: QueryGetCurrentUserProfileGQL,
    private mutationRequestChangPasswordGQL: MutationRequestChangPasswordGQL,
    private mutationChangePasswordGQL: MutationChangePasswordGQL,
    private mutationCheckTokenGQL: MutationCheckTokenGQL,
    private mutationRegisterGQL: MutationRegisterGQL,
    private queryGetPermissionGQL: QueryGetPermissionGQL,
  )
  {
  }


  loginAdmin(userAccount: UserAccountInput): Observable<AuthenticationResponse>
  {
    return this.mutationLoginAdminGQL.mutate({
        userAccount: userAccount
    }).pipe(map(({data}) => data.loginAdmin));
  }

  // getCurrentUserProfile(): Observable<UserProfile>
  // {
  //   return this.queryGetCurrentUserProfileGQL.watch().valueChanges.pipe(map(({data}) => data.getCurrentUserProfile));
  // }
  getCurrentUserProfile()
  {

  }

  getUserDetails(): Observable<any> {
    return this.queryGetAccessDetailsByLoggedInUserGQL
      .watch({

      }).valueChanges
      .pipe(map(({data}) => {
        this.moduleList = data.getAccessDetailsByLoggedInUser.accessDetails[0].modulesList;
      return data.getAccessDetailsByLoggedInUser;
      }));
  }

  getTypefromDashboard(types):Type[]{
    if(_.find(this.moduleList, {name: 'Rewards'}) && (!_.find(types, {name: 'Rewards'}))) {
      types.push({name:'Rewards',value:'REWARDS'});
    }
    return types
  }
  getPendingTypeList():Type []{
    const types = [
      {name:'Results' ,value:'RESULTS'},
      {name:'Records' ,value:'RECORDS'}
    ]
   if( localStorage.getItem('role') === 'ADMIN' && (!_.find(types,{name:'Rewards'}))){
    types.push({name:'Rewards',value:'REWARDS'})
   }
    return this.getTypefromDashboard(types);
  }
  logout(router : Router)
  {
    window.localStorage.removeItem('token');
    router.navigate(['auth', 'login']);

  }
  requestChangePassword(emailAddress: string) {
    return this.mutationRequestChangPasswordGQL.mutate({
      emailAddress: emailAddress
    }).pipe(map(({data}) => data.requestChangePassword))
  }
  changePassword(newPassword: string, token: string) {
    return this.mutationChangePasswordGQL.mutate({
      newPassword: newPassword,
      token: token
    }).pipe(map(({data}) => data.changePassword))
  }
  checkToken(token: string) {
    return this.mutationCheckTokenGQL.mutate({
      token: token
    }).pipe(map(({data}) => data.checkToken))
  }
  register(userAccount: UserAccountInput) {
    return this.mutationRegisterGQL.mutate({
      userAccount: userAccount
    }).pipe(map(({data}) => data.register))
  }

  getPermission(): Observable<string[]>
  {
    return this.queryGetPermissionGQL.watch().valueChanges.pipe(map(({data}) => data.getPermission));
  }
}
