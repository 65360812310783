import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BreadcrumbsService} from './core/services/breadcrumbs/breadcrumbs.service';
import { UseraccessService } from './core/services/useraccess.service';
import { AuthService } from './modules/auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
{
  title = 'trampolene-life-admin';


  constructor(private breadcrumbsService: BreadcrumbsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private useraccessService:UseraccessService)
  {
 

    this.breadcrumbsService.changeBreadcrumbs(this.router, this.activatedRoute);

  }


}
