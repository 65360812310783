import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, AbstractControlDirective, ValidationErrors, ValidatorFn} from '@angular/forms';

@Component({
  selector: 'form-error-message',
  template: `
    <div class="form-control-feedback">
      {{errorMessage}}
    </div>
  `,
  styles: [`
    .form-control-feedback {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      height: 18px;
      color: #cc4b37;
    }
  `]
})
export class FormErrorMessageComponent implements OnInit
{

  @Input('FormControls') formControls: AbstractControlDirective | AbstractControl | any;
  @Input('FormFieldLabel') fieldLabel;

  constructor()
  {
  }

  ngOnInit()
  {
  }

  get errorMessage()
  {
    if (this.formControls.controls) {
      const childControlNames = Object.keys(this.formControls.controls);
      const errArr = [];
      childControlNames.forEach(childControlName => {
        const control = this.formControls.controls[childControlName];
        for (let propertyName in control.errors)
        {
          if (control.errors.hasOwnProperty(propertyName) && control.touched)
          {
            errArr.push({controlName: childControlName, propertyName: propertyName, error: control.errors[propertyName]});
          }
        }
      });
      const errSet = [];
      errArr.forEach((errItem, index) => {
        if (index === errArr.findIndex(findErr => findErr.propertyName === errItem.propertyName)) {
          errSet.push(errItem);
        }
      });
      if (errSet.length > 0) {
        return this.getValidatorErrorMessage(errSet[0].propertyName, errSet[0].error, this.fieldLabel);
      }
    }
    else {
      for (let propertyName in this.formControls.errors)
      {
        if (this.formControls.errors.hasOwnProperty(propertyName) && this.formControls.touched)
        {
          return this.getValidatorErrorMessage(propertyName, this.formControls.errors[propertyName], this.fieldLabel);
        }
      }
      return '';
    }
  }

  private getValidatorErrorMessage(validatorName: string, validatorValue?: any, fieldName?: string)
  {
    let config = {
      'required': `Please input ${fieldName} `,
      'email': `Please input valid email address`,
       'min': `${fieldName} must be larger than 0`,
       'max': `${fieldName} must not be larger than 6 digits`,
      'minlength': `This `+fieldName+` must be at least ${validatorValue.requiredLength}`,
      "compare": "Confirm password and Password do not match",
      'maxlength': `This `+fieldName+` maximum is ${validatorValue.requiredLength} `,
      'pattern' : 'Enter the result in valid format',
      // 'invalidEmailAddress': 'Please input email',
    };
    return config[validatorName];
  }

}

