import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {distinctUntilChanged, filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UseraccessService
{
  private breadcrumbs = new BehaviorSubject([]);
  currentBreadcrumbs = this.breadcrumbs.asObservable();

  private subject = new BehaviorSubject<any>({});
  userDetails: Observable<any> = this.subject.asObservable();
  private routerInfo: BehaviorSubject<any>;
  private guardInfo: BehaviorSubject<any>;
  constructor()
  {
    this.routerInfo = new BehaviorSubject<any>([]);
    this.guardInfo = new BehaviorSubject<any>([]);
  }



  getValue(): Observable<any> {
    return this.routerInfo.asObservable();
  }

  setValue(newValue): void {
    this.routerInfo.next(newValue);
  }
  setValueGuard(newValue): void {
    this.guardInfo.next(newValue);
  }
  getValueGuard(): Observable<any> {
    return this.guardInfo.asObservable();
  }
}
