import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform
{

  transform(value: any, args?: any): any
  {
    if(value) {
      const {year, month, date, hour, minute, second} = value;
      return new Date(year, month, date, hour, minute, second);
    } else {
      return null;
    }
  }

}
