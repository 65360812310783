// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // mode: 'dev',
  //apiURL:'https://life-services-dev.the-hash.com/life',
  // apiURL:'https://api-trampolene.perfomatix.online',
  apiURL:'https://life-services.sdsc.org.sg/life',
  //   // apiURL:'http://localhost:8082/life',
  //apiURL:'http://13.215.63.223:8080/life',
  // googleAPI: 'AIzaSyDQJgWagbYKLugNBJUlCy9tOpBQNbWjyaY'
  production: true,
  mode: 'production',
  //apiURL:'http://lifebackenddevelopment-env.eba-2vehmanf.ap-southeast-1.elasticbeanstalk.com/life',
  //apiURL:'http://localhost:5000/life',
  googleAPI: 'AIzaSyDbH38-yTFEH4huXhG-YZKgbdgj5GyGD7E'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
