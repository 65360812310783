import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';

import {AppComponent} from './app.component';


import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './modules/page-not-found/page-not-found.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreComponentsModule} from './core/components/core-components.module';
import {LoadingScreenInterceptor} from './core/components/loading-screen/loading.interceptor';
import {GraphQLModule} from './core/modules/graphql/graphql.module';
import {AuthGuard} from './modules/auth/guard/auth.guard';
import {ToastrModule} from 'ngx-toastr';
import {CoreService} from './modules/sites/services/core.service';

import {AgmCoreModule} from '@agm/core';
import {environment} from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogModule, MatDialogRef, MatTooltipModule, MAT_DIALOG_DATA } from '@angular/material';
import { SharePipesModule } from './modules/sites/modules/shared/pipes/share-pipes.module';
import { GlobalErrorHandler } from './core/components/global-error-handler';
import { SatDatepickerModule } from 'saturn-datepicker';
import { IConfig, NgxMaskModule } from 'ngx-mask';











 export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

const appRoute: Routes = [
  {path: 'auth', loadChildren: './modules/auth/auth.module#AuthModule', data: {breadcrumb: null}},
  {path: '', loadChildren: './modules/sites/sites.module#SitesModule', data: {breadcrumb: null}, canActivate: [AuthGuard]},
  {path: '**', component: PageNotFoundComponent, data: {breadcrumb: null}}
];

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,

           ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreComponentsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatTooltipModule,



    // common module
    GraphQLModule,
    MatDialogModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(appRoute, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
    NgxMaskModule.forRoot(options),
    AgmCoreModule.forRoot({
      apiKey: environment.googleAPI,
      libraries: ['places']
    })
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,

    },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler},
     { provide: MatDialogRef, useValue: {} }, { provide: MAT_DIALOG_DATA, useValue: [] },
    CoreService
  ],
  bootstrap: [AppComponent]
})
export class AppModule
{
}
