import {Injectable} from '@angular/core';
import {
  Country,
  Nationality,
  QueryGetListCountryGQL,
  QueryGetListMaritalStatusGQL,
  QueryGetListNationalityGQL, QueryGetListPostTypeGQL,
  QueryGetListRaceGQL,
  Race
} from '../../../core/modules/graphql/generated/graphql';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ETypeUpload} from '../../../models/enum/type-upload.enum';

@Injectable()
export class CoreService
{

  constructor(private http: HttpClient,
              private queryGetListCountryGQL: QueryGetListCountryGQL,
              private queryGetListNationalityGQL: QueryGetListNationalityGQL,
              private queryGetListRaceGQL: QueryGetListRaceGQL,
              private queryGetListMaritalStatusGQL: QueryGetListMaritalStatusGQL,
              private queryGetListPostTypeGQL: QueryGetListPostTypeGQL)
  {
  }

  getListCountry(): Observable<Country[]>
  {
    return this.queryGetListCountryGQL.watch().valueChanges.pipe(map(({data}) => data.getListCountry));

  }

  getListMaritalStatus():Observable<string[]>
  {
    return this.queryGetListMaritalStatusGQL.watch().valueChanges.pipe(map(({data}) => data.getListMaritalStatus));
  }

  getListNationality(): Observable<Nationality[]>
  {
    return this.queryGetListNationalityGQL.watch().valueChanges.pipe(map(({data}) => data.getListNationality));
  }

  getListRace(): Observable<Race[]>
  {
    return this.queryGetListRaceGQL.watch().valueChanges.pipe(map(({data}) => data.getListRace));
  }

  getListPostType(): Observable<string[]>
  {
    return this.queryGetListPostTypeGQL.watch().valueChanges.pipe(map(({data}) => data.getListPostType));
  }


  uploadImage(file: any, typeUpload: ETypeUpload, id: string)
  {

    const form = new FormData();
    form.append('file', file);
    form.append('type', typeUpload);
    form.append('id', id);

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post(environment.apiURL + '/upload/image', form, httpOptions);
  }
  uploadImageReward(file: any, type, id: string)
  {

    const form = new FormData();
    form.append('file', file);
    form.append('type', type);
    form.append('id', id);

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post(environment.apiURL + '/upload/image', form, httpOptions);
  }
  uploadImagePost(file: any,type)
  {
    const form = new FormData();
    form.append('file', file);
    form.append('type', type);


    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post(environment.apiURL + '/upload/image', form, httpOptions);
  }
  uploadFile(file : any )
  {
    const form = new FormData();
    form.append('file', file);

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post(environment.apiURL + '/upload/file', form, httpOptions);
  }

  downloadExportUsers(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.get(environment.apiURL + '/download/export/users', httpOptions);
  }

  uploadDocument(file: any, typeUpload: ETypeUpload, id: string)
  {
    const form = new FormData();
    form.append('file', file);
    form.append('type', typeUpload);
    form.append('id', id);

    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      })
    };
    return this.http.post(environment.apiURL + '/upload/document', form, httpOptions);
  }

}
