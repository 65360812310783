import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'input[intOnly]'
})
export class IntegerOnlyDirective {
  private regex = new RegExp(/^[1-9]{1,}[0-9]*$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Enter', 'ArrowRight', 'ArrowLeft'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.specialKeys.indexOf(event.key) !== -1)
    {
      return;
    }
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex))
    {
      event.preventDefault();
    }
  }
}
